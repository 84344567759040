<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{title}}</h2>
        </div>
        <div class="card" [formGroup]="basicDetailsForm">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Details
                </h4>

                <div class="row  mr-3 ml-3 mt-3">
                <div class="col-12 row"  >
                    <div class=" col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Branch
                        </div>
                        <div class="" >
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                              <mat-select [placeholder]="'All Branches'"  [(ngModel)]="selected_branch_id" (ngModelChange)="getUsers()" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let branch of branchesList" [value]="branch.id">{{branch.name | titlecase  }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                           
                          </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                           Role
                        </div>
                        <div class="" >
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                              <mat-select [placeholder]="'All Roles'"  [(ngModel)]="selected_role_id" (ngModelChange)="getUsers()" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let role of roleList" [value]="role.id">{{role.name | titlecase }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            User
                            <span class="text-danger">*</span>
                        </div>
                        <div class="" >
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" >
                              <mat-select [placeholder]="'Select User'" formControlName="selectedCustomerId">
                                <mat-option *ngFor="let user of userList" [value]="user.id">{{user.full_name | titlecase }}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="basicDetailsForm.get('selectedCustomerId').hasError('required')">
                                    User is required
                                </mat-error>
                            </mat-form-field>
                          </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="fw-bold fieldName">
                        Select Tag
                        <span class="text-danger">*</span>
                    </div>
                    <div class="">
                        <div class="">

                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="tag" style="width: 90%;">
                                <mat-select [placeholder]="'Select Tag'" formControlName="selectedTagId" (ngModelChange)="getTags()">
                                    <mat-option *ngFor="let tag of tagsList"
                                        [value]="tag.id">{{tag.name}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="basicDetailsForm.get('selectedTagId').hasError('required')">
                                    Tag is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="fw-bold fieldName">
                        Subject
                        <span class="text-danger">*</span>
                        <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(100))</span>
                    </div>
                    <div class="">
                        <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="subject" style="width: 90%;">
                            <input id="Subject" style="background: #FFFFFF;" matInput placeholder="Enter Subject"
                            formControlName="subject" required 
                            pattern="^[a-zA-Z][\w\s]*$" maxlength="100"
                            (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('subject').value)"
                            >
                            <mat-error *ngIf="basicDetailsForm.get('subject').hasError('required')">
                                Subject is required
                            </mat-error>
                            <mat-error *ngIf="basicDetailsForm.get('subject').hasError('pattern')">
                                Subject has invalid characters
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-6">
            <div class="card " style="border: None;">
                <div class="row  m-3 justify-content-center">
                    <div class="col-10">
                        <div class="fw-bold fieldName" style="background: #000; color: #fff; padding: 10px; border-top-left-radius: 13px; border-top-right-radius: 13px;">
                            Description
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <textarea  rows=5 id="Description" style="background: #FFFFFF;" matInput
                                formControlName="description"
                                (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('description').value)"
                                >
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-file-upload-manager #fileUploadComponent></app-file-upload-manager>

    <div class='btnposition' id="rest-button">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn" mat-flat-button (click)="resetForm()"> 
                <span class="ml-2 mr-1 reset">Reset</span>
            </button>
            <button class="ml-4 mr-6 addBtn"  mat-flat-button [color]="'primary'"
            (click)="createTicket()"  [disabled]="!basicDetailsForm.valid" [ngClass]="{'btn-clr': basicDetailsForm.valid}">
               <span class="ml-2 mr-1 txt" style="color: white">Create </span>
             </button>
        </div>
    </div>
</div>