<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{title}}</h2>
        </div>

        <div class="card"  style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Details
                </h4>

                <div class="row  mr-3 ml-3 mt-3">

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Enter Name
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(100))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input style="background: #FFFFFF;" matInput placeholder="Enter Name"
                                [(ngModel)]="planName" id="planNameId" name="planNameId" required #planNameId="ngModel" 
                                pattern="^[a-zA-Z][\w\s]*$"
                                (keypress)="handleStartingSpaceTextInput($event, planName)" maxlength="100"
                                >
                                <mat-error *ngIf="planNameId.invalid && planNameId.touched && planNameId.errors.required">Name is required</mat-error>
                                <mat-error *ngIf="planNameId.invalid && planNameId.touched && planNameId.errors.pattern">Please enter valid name</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Enter Hierarchy
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(100))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input style="background: #FFFFFF;" matInput placeholder="Enter Hierarchy"
                                [(ngModel)]="planHierarchy" oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value='';" 
                                id="planHierarchyId" name="planHierarchyId"  maxlength="100"   required #planHierarchyId="ngModel" pattern="^[0-9]*$"
                                >
                                <mat-error *ngIf="planHierarchyId.invalid && planHierarchyId.touched && planHierarchyId.errors.required">Hierarchy is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Plan Type
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Plan Type'" [(ngModel)]="selectedPlanTypeId" (ngModelChange)="getPlanSubType()"
                                    id="planTypeId" name="planTypeId" required #planTypeId="ngModel"
                                    >
                                        <mat-option *ngFor="let plan of PlanTypeList"
                                            [value]="plan.id">{{plan.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="planTypeId.invalid && planTypeId.touched && planTypeId.errors.required">Plan Type is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Plan Sub Type
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Plan Sub Type'" [(ngModel)]="selectedPlanSubTypeId" (ngModelChange)="getBenefitPointsOfSubType()"
                                    id="planSubTypeId" name="planSubTypeId" required #planSubTypeId="ngModel"
                                    >
                                        <mat-option *ngFor="let plan of PlanSubTypeList"
                                            [value]="plan.id">{{plan.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="(planSubTypeId.invalid || planSubTypeId==null) && planSubTypeId.touched && planSubTypeId.errors.required">Plan Sub Type is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Plan SAC Code
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Plan SAC Code'" [(ngModel)]="selectedPlanSACCode"
                                    id="planSACCodeId" name="planSACCodeId" required #planSACCodeId="ngModel"
                                    >
                                        <mat-option *ngFor="let sacCode of PlanSACCodes"
                                            [value]="sacCode">{{sacCode}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="(planSACCodeId.invalid || planSACCodeId==null) && planSACCodeId.touched && planSACCodeId.errors.required">Plan SAC Code is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="mt-6">
            <div class="card " style="border: none">
                <div class="card-header"  style="border: none ;">
                    <h4>
                        Plan Duration and Price
                    </h4>
                    <mat-hint class="hintStyle">**Hint: Atleast one duration should be added</mat-hint>
                </div>
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Enter Duration
                            <span class="text-danger">*</span>
                           
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="Plan Price" style="background: #FFFFFF;" matInput placeholder="Enter Duration"
                                [(ngModel)]="planDuration"
                                oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value='';">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Enter Plan Price
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="Plan Price" style="background: #FFFFFF;" matInput placeholder="Enter Plan Price"
                                [(ngModel)]="planPrice" (ngModelChange)="calculatePlanPrices()"
                                oninput="if(!this.value.match('^[1-9]*\.[0-9]*$')) this.value='';"
                                >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            SGST
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="SGSTPrice" style="background: #FFFFFF;" matInput placeholder="SGST Tax Price"
                                    [(ngModel)]="sgstTaxPrice" [readonly]="true">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            CGST
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="CGSTPrice" style="background: #FFFFFF;" matInput placeholder="CGST Tax Price"
                                    [(ngModel)]="cgstTaxPrice" [readonly]="true">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            IGST
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="IGSTPrice" style="background: #FFFFFF;" matInput placeholder="IGST Tax Price"
                                    [(ngModel)]="igstTaxPrice" [readonly]="true">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mt-6">
                            <div class=" d-flex justify-content-end">

                                <button class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="addPlanDuration()"
                                [ngClass]="{'btn-clr': !(!planDuration || planPrice==null)}"
                                [disabled]="(!planDuration || planPrice==null)"
                                >
                                    <span class="ml-2 mr-1 txt" style="color: white">Add</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 mt-6 d-flex flex-column  m-auto overflow-x-scroll"
                    style="max-height:450px; overflow-y:scroll;">
                    <table class="table" style="width: 90%">
                        <thead class="thead-dark"
                            style="background: #000; color: #fff;  position: sticky; top: 0;">
                            <tr>
                                <th scope="col">Duration</th>
                                <th scope="col">Price</th>
                                <th scope="col">SGST</th>
                                <th scope="col">CGST</th>
                                <th scope="col">IGST</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let planDuration of planDurations">
                                <th scope="row">{{planDuration.planDuration}}</th>
                                <th>{{planDuration.planPrice}}</th>
                                <th>{{planDuration.sgstTaxPrice}}</th>
                                <th>{{planDuration.cgstTaxPrice}}</th>
                                <th>{{planDuration.igstTaxPrice}}</th>
                                <td>
                                    <button mat-icon-button (click)="deleteplanDuration(planDuration)">
                                        <mat-icon style="color: red" svgIcon="mat_outline:delete"></mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="mt-6">
            <div class="card  " style="border: none">
                <div class="card-header d-flex justify-content-between" style="border: none ;">
                    <h4>
                        Plan Benefits
                    </h4>
                    <div class="col-md-4">
                        <mat-checkbox  [(ngModel)]="canBookSlots"  (change)="CanBookSlotCheckEvent($event)">
                            <span class="fw-bold fieldName">Can Book Slots</span>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row mr-3 ml-3 mt-3">
                    

                    <div class="col-md-4" *ngIf="canBookSlots">
                        <div class="fw-bold fieldName">
                            Trainer Roles
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Trainer roles'" [(ngModel)]="selectedTrainerRoleIds" multiple
                                    id="TrainerRolesId" name="TrainerRolesId" required #TrainerRolesId="ngModel"
                                    >
                                        <mat-option *ngFor="let role of roleList"
                                            [value]="role.id">{{role.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="(TrainerRolesId.invalid || TrainerRolesId.length<=0) && TrainerRolesId.touched && TrainerRolesId.errors.required">Trainer Roles are required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                      <div class="col-md-4" *ngIf="canBookSlots">
                        <div class="fw-bold fieldName">
                            Slot Booking Days
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%">
                                    <mat-select [placeholder]="'ALL'"
                                        (selectionChange)="selectedDays($event.value)" [(ngModel)]="selectedSlotBookingDays"
                                        multiple id="slotBookingDaysId" name="slotBookingDaysId" required #slotBookingDaysId="ngModel">
                                        <mat-checkbox class="" [checked]="isAllSlotBookingDaysSelected"
                                            [indeterminate]="someComplete()" (change)="setAll($event)">
                                            Select All
                                        </mat-checkbox>

                                        <mat-option *ngFor="let day of allDaysList" [value]="day.value">
                                            {{ day.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="canBookSlots && slotBookingDaysId.touched && selectedSlotBookingDays.length<=0">Slot booking days are required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Suitable For Customers
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="planSuitableForCustomers" style="background: #FFFFFF;" matInput placeholder="Suitable For Customers"
                                [(ngModel)]="planSuitableForCustomers"
                                (keypress)="handleStartingSpaceTextInput($event, planSuitableForCustomers)"
                                >
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Benefit Points
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%">
                                <mat-select [placeholder]="'ALL'"
                                    [(ngModel)]="selectedBenefitPoints"
                                    multiple>

                                    <mat-option *ngFor="let benefitPoint of allBenefitPoints" [value]="benefitPoint">
                                        {{ benefitPoint }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-5" *ngIf="canBookSlots">
                        <div class="fw-bold fieldName">
                            No.of Free Reschedules Per Month
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input style="background: #FFFFFF;" matInput placeholder="No of Free Reschedules per Month"
                                [(ngModel)]="noOfReschedulesPerMonth" oninput="if(!this.value.match('^[0-9][0-9]*$')) this.value='';"
                                id="noOfReschedulesPerMonthId" name="noOfReschedulesPerMonthId" required #noOfReschedulesPerMonthId="ngModel"
                                >
                                <mat-error *ngIf="canBookSlots && noOfReschedulesPerMonthId.touched && noOfReschedulesPerMonth==null">Reschedule count is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <div class="row  mr-3 ml-3 mt-3">

                    
                </div>
            </div>
        </div>
    </div>

    <div class="btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 mt-4 addBtn" mat-flat-button (click)="reset()" *ngIf="!isUpdate">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
            <button class="ml-4 mt-4 addBtn" mat-flat-button (click)="back()" *ngIf="isUpdate">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <!-- <span matTooltip="userTimings should be added for all the seven days"
                [matTooltipDisabled]="!(basicDetailsForm.invalid || (should_add_branch_at_user_creation && branch_list.length<=0) || (should_add_address && userAddressForm.invalid) || (should_add_availability_timings && !isValidTimings))"
                style="width: fit-content;">
            </span> -->
            <!-- Why tghis span is added here -->
            <button class="ml-4 mr-6 mt-4 addBtn" mat-flat-button [color]="'primary'" (click)="createPlan()" *ngIf="!isUpdate"
            [disabled]="(!planName || planNameId.invalid || !planHierarchy || !selectedPlanTypeId || !selectedPlanSubTypeId || !selectedPlanSACCode || planSACCodeId.invalid || planDurations.length<=0  || (canBookSlots == true && (selectedSlotBookingDays.length<=0 || noOfReschedulesPerMonth==null || selectedTrainerRoleIds.length<=0)))"
            [ngClass]="{'btn-clr': !(!planName || planNameId.invalid || !planHierarchy || !selectedPlanTypeId || !selectedPlanSubTypeId || !selectedPlanSACCode || planSACCodeId.invalid || planDurations.length<=0  || (canBookSlots == true && (selectedSlotBookingDays.length<=0 || noOfReschedulesPerMonth==null || selectedTrainerRoleIds.length<=0)))}"
            >
                <span class="ml-2 mr-1 txt" style="color: white">Create</span>
            </button>
            <button class="ml-4 mr-6 mt-4 addBtn" mat-flat-button [color]="'primary'" (click)="updatePlan()" *ngIf="isUpdate"
            [disabled]="(!planName || planNameId.invalid || !planHierarchy || !selectedPlanTypeId || !selectedPlanSubTypeId || !selectedPlanSACCode || planSACCodeId.invalid || planDurations.length<=0  || (canBookSlots == true && (selectedSlotBookingDays.length<=0 || noOfReschedulesPerMonth==null || selectedTrainerRoleIds.length<=0)))"
            [ngClass]="{'btn-clr': !(!planName || planNameId.invalid || !planHierarchy || !selectedPlanTypeId || !selectedPlanSubTypeId || !selectedPlanSACCode || planSACCodeId.invalid || planDurations.length<=0  || (canBookSlots == true && (selectedSlotBookingDays.length<=0 || noOfReschedulesPerMonth==null || selectedTrainerRoleIds.length<=0)))}"
            >
                <span class="ml-2 mr-1 txt" style="color: white">Update </span>
            </button>
        </div>
    </div>
    <!-- <div class="loader" *ngIf="showloading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->
</div>