import { Component, Inject } from '@angular/core';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';
import { trainerInterface } from 'app/pages/commonComponents/customInterfaces/customInterfaces.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MetaService } from 'app/meta.service';
import { JsonPipe } from '@angular/common';


@Component({
  selector: 'app-change-plan-trainer',
  templateUrl: './change-plan-trainer.component.html',
  styleUrls: ['./change-plan-trainer.component.scss']
})
export class ChangePlanTrainerComponent {

  title: string = "Update Plan Trainer"

  selectedTrainer: trainerInterface | null = null
  allowedTrainerObjs: trainerInterface[] = []
  filteredTrainerObjs: trainerInterface[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePlanTrainerComponent>,
    public meta: MetaService,
    public apiService: ApiService, 
    public toastr: ToastrService, 
  ){
    this.allowedTrainerObjs = this.data.allowedTrainerObjs;
    this.filteredTrainerObjs = this.data.allowedTrainerObjs;
  }

  ngOnInit(){
    this.meta.dialogClosed = false
  }

  applyFilterOnTrainers(searchValue){
    this.filteredTrainerObjs = []
    if(this.selectedTrainer){
      this.filteredTrainerObjs = [this.selectedTrainer]
    }
    this.filteredTrainerObjs = this.filteredTrainerObjs.concat(
      this.allowedTrainerObjs.filter(optionObj => 
        optionObj.full_name.toLowerCase().includes(searchValue.toLowerCase()) && this.selectedTrainer != optionObj
    ))
  }

  updatePlanTrainer(){

    let userOrderPlanId: string = this.data.userOrderPlanId
    let trainerId: string = this.selectedTrainer.id

    this.apiService.updatePlanTrainerAPI(userOrderPlanId=userOrderPlanId, trainerId=trainerId).subscribe((response) => {
      if (response.status == "success") {
        this.toastr.success(response.message)
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );

    this.meta.dialogClosed = true;
    this.dialogRef.close()
  }

}
