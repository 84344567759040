import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import { viewUser } from 'app/meta.service';
import moment from 'moment';

@Component({
  selector: 'app-view-plans',
  templateUrl: './view-plans.component.html',
  styleUrls: ['./view-plans.component.scss']
})


export class ViewPlansComponent {
  plans = [];
  planDuration=[];
  PlanBenefits = [];
  suitableForCustomers = null;
 
  reportToUserNames = [];
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService, private datePipe: DatePipe
  ) { }
  ngOnInit() {
    this.getUserById();
  }
  getUserById() {
    this.plans = this.data.data;
    this.plans.forEach(element => {
      this.planDuration = element.plan_durations;
      this.PlanBenefits = element.benefits;
      this.suitableForCustomers = element.suitable_for_customers;
      element.trainerRoleNames = element.schedule_roles.map(scheduleRole => {
        return scheduleRole.name
      })

    });
  }
}

