import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { IUserActions, MetaService, PlanTypeActions, PlansActions, featureConstants } from 'app/meta.service';
import { user } from 'app/mock-api/common/user/data';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ViewUserComponent } from 'app/pages/Users/view-user/view-user.component';
import { ViewPlansComponent } from '../view-plans/view-plans.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
export interface Element {
  Name: string;
  RoleType: string;
  IsRemoved: boolean;
  CreatedBy: string;
  gender: string;
}
export interface Iuser {
  id: String;
  is_removed: boolean;
  name: string;
  email: string;
  mobile: boolean;
  // AddBranch: boolean;
}
/** Constants used to fill up our data base. */
@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['planName', 'planHierarchy', 'planType', 'planSubType',  'planPricePerMonth', 'Actions'];
  dataSource = new MatTableDataSource<Iuser>();
  userDetails = [];
  userList: Iuser[];
  PlansDetails=[];
  planTypesList = [];
  planSubTypesList = [];
  filterValueToDisplay="";
  roleType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]

  IDPlanSubType: PlansActions = {
    plansActive: "",
    planTypeId: "",
    plansId: '',
    planSubTypeId: ''
  }
  
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  Plan_Type_id = "";
  search = "";
  filterValue=null;
  branches_list:[];
  userBranchNames=[];
  scrHeight: number;
  scrWidth: number;
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewPlans = this.userAllowedFeatures.indexOf(featureConstants.VIEW_PLANS) > -1
  isUserAllowedToCreatePlans = this.userAllowedFeatures.indexOf(featureConstants.CREATE_PLANS) > -1
  isUserAllowedToEditPlans = this.userAllowedFeatures.indexOf(featureConstants.EDIT_PLANS) > -1
  isUserAllowedToDeactivatePlans = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_PLANS) > -1
  isUserAllowedToReactivatePlans = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_PLANS) > -1
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, public dialog: MatDialog, public apiService: ApiService, private _liveAnnouncer: LiveAnnouncer, public meta: MetaService, private toastr: ToastrService, private route: ActivatedRoute,) {

    // Assign the data to the data source for the table to render
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.Plan_Type_id = params['Plan_Type_id'];
    });
    const paginationDataString = sessionStorage.getItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');

    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.IDPlanSubType.planTypeId=paginationData.filterByPlanTypeId;
      this.IDPlanSubType.plansActive=paginationData.filterByPlanActiveStatus;
      this.IDPlanSubType.planSubTypeId=paginationData.filterByPlanSubTypeId;
      this.filterValue=paginationData.filterValue;
      this.filterValueToDisplay=paginationData.filterValue;
    }
    if (this.Plan_Type_id) {
      this.getPlansByFilter();
      this.getPlanTypesList();
      
    } else {
      if (this.isUserAllowedToViewPlans) {
        this.getPlanTypesList();
      }
      this.getPlansByFilter();
    }
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getPlanTypesList() {
    this.IDPlanSubType.planSubTypeId = ''
    this.planSubTypesList = []
    let data = { "filter_by_plan_type_active_status": true }
    this.apiService.getPlanTypeByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.planTypesList = resp.data.plan_types;
        // console.log("this is plan list", resp.data)
        // this.dataSource.data = resp.data
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getPlanSubTypesList() {
    this.IDPlanSubType.planSubTypeId = ''
    this.planSubTypesList = []
    
    if (this.IDPlanSubType.planTypeId) {
      let data = {
        "filter_by_plan_sub_type_active_status": true,
        "filter_by_plan_type_id": this.IDPlanSubType.planTypeId,
      }
      this.apiService.getPlanSubTypeByFilter(data).subscribe((resp) => {
        if (resp.status == "success") {
          this.planSubTypesList = resp.data.plan_sub_types;
          // console.log("this is plan list", resp.data)
          // this.dataSource.data = resp.data
        }
      },
        (err) => {
          this.toastr.error(err.error.message)
        })
    }
  }
 
  getBranches() {
    let data = {filter_by_branch_active_status: true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branches_list = resp.data.branches
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  roleData = [];
  getPlan(){
    this.apiService.getPlanDetails().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.plans;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    
    // }

this.onGetPlansByFilterForSearch() 
 }



 announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  filterPlanType(value) {
    this.getPlanSubTypesList()
    this.onGetPlansByFilter()
  }

  filterPlanSubType(value) {
    this.onGetPlansByFilter()
  }

  getPlansByFilter() {
    let data = {
      filter_by_plan_type_id: this.IDPlanSubType.planTypeId,
      filter_by_plan_active_status: this.IDPlanSubType.plansActive,
      filter_by_plan_sub_type_id:this.IDPlanSubType.planSubTypeId,
      offset: this.bOffset,
      limit: this.bLimit,
      search_term:this.filterValue
    }
    this.apiService.getPlansByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.plans;
        // this.dataSource.data.forEach((element: any) => {
        //   var branchNames = [];
        //   this.userBranchNames = []
        //   element.branches.forEach(ele => {
        //     branchNames.push(ele.name)
        //     this.userBranchNames = branchNames
        //   });
        //   element.branchNames = branchNames;
        // });
     this.totalCount=resp.data.total_count;
     
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  selectRoleType(value) {
  }
  createUser() {
    sessionStorage.removeItem('editPlanId')
    this.router.navigateByUrl('Admin/create-plan');
  }
  editPlans(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByPlanTypeId: this.IDPlanSubType.planTypeId,
      filterByPlanActiveStatus: this.IDPlanSubType.plansActive,
      filterByPlanSubTypeId:this.IDPlanSubType.planSubTypeId,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForPlan', JSON.stringify(paginationData)); 
    sessionStorage.setItem('editPlanId', row.id)
    this.router.navigateByUrl('Admin/edit-plan');
   
    // this.router.navigate(['Admin/create-plan', { plan_id:row.id }]);

  }

  getUserById(row) {
    this.userDetails = [];
    this.apiService.getUserById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.userDetails.push(resp.data.user);
        this.viewUser(this.userDetails)
      }

    },
      (err) => {
        this.toastr.error(err.error.message)
      })

  }
  viewWidth:any
  viewUser(data) {
    if(this.scrWidth < 1450){
      this.viewWidth='100%'
    }else{
      this.viewWidth='60%'
    }
    let dialogRef = this.dialog.open(ViewPlansComponent, {
      data: {
        data: data,
        
      },
      
      // height: auto,
      width: this.viewWidth,
      
    });
  }

  reactivatePlans(plan_id: String) {
    this.apiService.reactivatePlans(plan_id = plan_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          plan_type => {
            if (plan_type.id == plan_id){
              plan_type.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  verifyDeactivate(plan_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "This Plan won't be visible on Mobile App."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivatePlans(plan_id=plan_id)
      }
    });
  }


  deactivatePlans(plan_id: String) {
    this.apiService.deactivatePlans(plan_id = plan_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          plan_type => {
            if (plan_type.id == plan_id){
              plan_type.is_removed = true
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  getPlansById(row) {
    this.PlansDetails = [];
    this.apiService.getPlansById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.PlansDetails.push(resp.data.plan);
        this.viewUser(this.PlansDetails)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getPlansByFilter(); 
  }
  onGetPlansByFilterForSearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByPlanTypeId: this.IDPlanSubType.planTypeId,
      filterByPlanActiveStatus: this.IDPlanSubType.plansActive,
      filterByPlanSubTypeId:this.IDPlanSubType.planSubTypeId,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForPlan', JSON.stringify(paginationData)); 
    this.getPlansByFilter()
  }

  onGetPlansByFilter(){
    this.pageIndex=0;
    this.bOffset=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByPlanTypeId: this.IDPlanSubType.planTypeId,
      filterByPlanActiveStatus: this.IDPlanSubType.plansActive,
      filterByPlanSubTypeId:this.IDPlanSubType.planSubTypeId,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForPlan', JSON.stringify(paginationData)); 
    this.getPlansByFilter()
  }
}
