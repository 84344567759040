import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-referral-percentages',
  templateUrl: './create-referral-percentages.component.html',
  styleUrls: ['./create-referral-percentages.component.scss']
})
export class CreateReferralPercentagesComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  public basicDetailsForm: FormGroup;
  referralPercentagesId: string;

  isUpdate: boolean = false;
  pageTitle: string = "Create Referral Percentages"
  disableAdd: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    public apiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ){
    this.buildForm();

    this.referralPercentagesId = sessionStorage.getItem('editReferralPercentagesId')
  }

  ngOnInit(){
    if(this.referralPercentagesId){
      this.getReferralPercentagesById();
      this.pageTitle = "Edit Referral Percentages";
      this.isUpdate = true
    }
  }

  back(){
    this.router.navigateByUrl('Admin/referral-percentages-management');
  }

  keyPress(event: any) {
    //for name validation
    const pattern = /[a-zA-z0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public buildForm() {
    this.basicDetailsForm = this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      referrerBonusPercentage: new FormControl(null, [Validators.required]),
      refereeDiscountPercentage: new FormControl(null, [Validators.required]),
    });
  }

  getReferralPercentagesById(){
    this.apiService.getReferralPercentagesById(this.referralPercentagesId).subscribe((resp) => {
      if (resp.status == "success") {
        let referralPercentagesData = resp.data.referral_percentages;
        this.basicDetailsForm.controls.name.setValue(referralPercentagesData.name);
        this.basicDetailsForm.controls.description.setValue(referralPercentagesData.description);
        this.basicDetailsForm.controls.referrerBonusPercentage.setValue(referralPercentagesData.referrer_bonus_percentage);
        this.basicDetailsForm.controls.refereeDiscountPercentage.setValue(referralPercentagesData.referee_discount_percentage);
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    })
  }

  createReferralPercentages(){
    if(this.basicDetailsForm.valid){
      let payload = {
        name: this.basicDetailsForm.controls.name.value,
        description:this.basicDetailsForm.controls.description.value,
        referrer_bonus_percentage: this.basicDetailsForm.controls.referrerBonusPercentage.value,
        referee_discount_percentage:this.basicDetailsForm.controls.refereeDiscountPercentage.value
      }
      this.apiService.createReferralPercentages(payload).subscribe((resp)=>{
        if(resp.status =="success"){
          this.router.navigateByUrl('Admin/referral-percentages-management');
        }
      },
      (err) => {
        this.toastr.error(err.error.message)
      })
    }
  }

  updateReferralPercentages(){
    let payload = {
      name: this.basicDetailsForm.controls.name.value,
      description: this.basicDetailsForm.controls.description.value,
      referrer_bonus_percentage: this.basicDetailsForm.controls.referrerBonusPercentage.value,
      referee_discount_percentage:this.basicDetailsForm.controls.refereeDiscountPercentage.value
    }
    this.apiService.updateReferralPercentages(this.referralPercentagesId, payload).subscribe((resp)=>{
      if(resp.status == "success"){
        this.router.navigateByUrl('Admin/referral-percentages-management');
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })

  }

  resetForm(){
    this.buildForm()
  }

}
