import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { saveAs } from 'file-saver';
import { ExcelDownloadService } from 'app/excel-download.service';
const sampleData = [
  { Name: 'John Doe', Age: 30, Email: 'john.doe@example.com' },
  { Name: 'Jane Smith', Age: 25, Email: 'jane.smith@example.com' },
  { Name: 'Robert Johnson', Age: 35, Email: 'robert.johnson@example.com' },
];
@Component({
  selector: 'app-create-logs',
  templateUrl: './create-logs.component.html',
  styleUrls: ['./create-logs.component.scss']
})
export class CreateLogsComponent {
  startTime: string;
  endTime: string;
  user_id:string;
  log_date:string;
  check_type: string;
  branches_list: string;
  branch: string;
  devices_list: string;
  attendance_device_id: string;
  disableUpdate: boolean = true;
  convertedTime:string
  constructor( private router: Router,private route: ActivatedRoute,public apiService: ApiService,private toastr: ToastrService,private excelDownloadService: ExcelDownloadService){
    this.route.params.subscribe((params) => {
      this.user_id = params['user_id'];
      this.log_date = params['log_date'];
    });
  }
  ngOnInit(){
    this.branch = ''
    this.attendance_device_id = ''
    this.getUserLogsById()
    this.getUserBranches()
    this.filterUserDevices();
    this.downloadExcel()
  }
  updatedLogs = []

  filterUserDevices() {
    let queryParams = {}
    if (this.branch) {
      queryParams['filter_by_branch_id'] = this.branch
    }
    this.apiService.getUsersDevices(this.user_id, queryParams).subscribe((resp)=>{
      this.devices_list = resp.data.attendance_devices;
    })
    
  } 
  getUserBranches(){
    this.apiService.getUsersBranches(this.user_id).subscribe((resp)=>{
      this.branches_list = resp.data.branches;
    })
  }
 
  downloadExcel(): void {
    // this.excelDownloadService.downloadExcelFile(sampleData);
  }
  AddUersLogs(){

    var stTime = moment(new Date(this.startTime)).format("HH:mm:ss").toString();
    let addedLogs = {
      log_type:this.check_type,
      log_date_time:this.log_date +" "+ stTime,
      is_removable: true,
      attendance_device_id: this.attendance_device_id
    }
    // if(this.attendance_logs.length >0){
    //   this.attendance_logs.forEach(element => {
    //     if(element.log_date_time != addedLogs.log_date_time && element.log_type != addedLogs.log_type){
         
    //     }
    //   });
    //   if(this.check_type && this.startTime ){
    //     this.attendance_logs.push(addedLogs);
    //     this.attendance_logs = this.sortData();
        
    //   }
    // }else{
    //   if(this.check_type && this.startTime ){
    //     this.attendance_logs.push(addedLogs);
    //     this.attendance_logs = this.sortData()
    //   }
    // }
    if(this.check_type && this.startTime && this.attendance_device_id ){
      this.attendance_logs.push(addedLogs);
      this.disableUpdate = false;
      this.attendance_logs = this.sortData()
    }
        
    // this.attendance_logs=this.attendance_logs.filter((each,index,array )=>{
    //   let value =true;
    //   array.forEach(element => {
    //     if(element.log_date_time == each.log_date_time && element.log_type == each.log_type){
    //       value =false
    //     }
    //   });
    //   return value;
    //  })
  //  this.attendance_logs=this.attendance_logs.sort((a, b) => new Date(b.log_date_time).getTime() - new Date(a.log_date_time).getTime())
  //  this.attendance_logs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  //  this.attendance_logs.reverse();
    this.check_type="";
    this.startTime = "";
    this.attendance_device_id = "";
    this.branch = "";
  }
  convertUTCToTime(date:any) {
    const utcDate = new Date(date);
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();

    const convertedHours = hours < 10 ? '0' + hours : hours;
    const convertedMinutes = minutes < 10 ? '0' + minutes : minutes;

    this.convertedTime = `${convertedHours}:${convertedMinutes}`;
    return this.convertedTime;
  }
  back(){
    // this.router.navigateByUrl('Admin/view-user-attendance');
    this.router.navigate(['Admin/view-user-attendance', { user_id: this.user_id }]);
  }
  deleteRow(i){
    this.attendance_logs.splice(i, 1);
    this.updatedLogs.splice(i,1)
  }
  attendance_logs=[];
  dateTime=[]
  getUserLogsById() {
    // When this feature come again, we have to get the branch_id as well
    this.apiService.getUserLogsById(this.log_date, this.user_id, null).subscribe((resp) => {
      if (resp.status == "success") {
        this.attendance_logs = resp.data.user_attendance_log.attendance_logs;
      
        this.attendance_logs.forEach(element => {
          element.is_removable = false;
        })
      }
    }, (err) => {
        this.toastr.error(err.error.message);
        this.router.navigate(['Admin/view-user-attendance', { user_id: this.user_id }]);
      }
    )
    this.attendance_logs = this.sortData()
    // this.attendance_logs.sort((a, b) => new Date(b.log_date_time).getTime() - new Date(a.log_date_time).getTime())
    // this.attendance_logs.sort((a, b) => new Date(b.log_date_time).getTime() - new Date(a.log_date_time).getTime())
   
    // this.attendance_logs
    //   .pipe(
    //     map((arr:any) => {
    //       return arr.sort((a, b) => (b.date as any) - (a.date as any));
    //     })
    //   )
    //   .subscribe(arr => {
    //     this.attendance_logs = arr;
    //   });
    // this.attendance_logs.reverse();

    }
  sortData() {
    return this.attendance_logs.sort((a, b) => {
      return <any>new Date(a.log_date_time).getTime() - <any>new Date(b.log_date_time).getTime();
    });
  }
  updateLogs(){
    let data=[];
    
    // this.attendance_logs.forEach(element => {
    //   if(element.is_removable){
    //     var stTime = moment(new Date(element.log_date_time)).format("HH:mm").toString();
    //     element.log_date_time = this.log_date +" "+ stTime +":"+ "00";
    //   }
      
    // });
   
    
   let updatedData= this.attendance_logs.filter(each=>each.is_removable == true ).map(ele => {
    var stTime = moment(new Date(ele.log_date_time)).format("HH:mm").toString();

    let valueDate = this.log_date +" "+ stTime +":"+ "00";
    let UTC = new Date(valueDate).toUTCString()
    let newDate = new Date(valueDate).getUTCHours() + ':' + new Date(valueDate).getUTCMinutes() + ':' + new Date(valueDate).getUTCSeconds()
    // let latest_Value = moment(newDate).format("HH:mm:ss").toString();
    // let updatedate =  moment(new Date(UTC)).format("HH:mm").toString();
    return({
      log_date_time:this.log_date +" "+ stTime +":"+ "00",
      log_type: ele.log_type,
      attendance_device_id: ele.attendance_device_id
    })
   })
   
    let payload = {
      attendance_logs :updatedData
    }
    this.apiService.updateLogsById(payload,this.user_id).subscribe((resp)=>{
      if(resp.status == "success"){
        // this.toastr.success("Users Loaded successfully");
    this.router.navigate(['Admin/view-user-attendance', { user_id: this.user_id }]);

      }
    })
  }
}
