<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
  <div class="row">

    <div class="row mt-8 justify-content-between">
      <div class="col-sm-12 col-md-4 col-lg-2 d-flex flex-column flex-sm-row">
        <h1 class="h3 ml-4 mr-6  heading">
          ROLES
        </h1>
      </div>
      <div class="col-sm-12 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToRoleCreate">
        <button class="addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'" (click)="createRole()">
          <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1 txt" style="color: white">Create Role</span>
        </button>
      </div>
    </div>  

 <div class="row"> 
    <div class="col-sm-12 col-md-4 col-lg-2">
      <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 100%;">
        <mat-select  [(ngModel)]="Irole.roleType" (selectionChange)="onFilterRole()">
          <mat-option *ngFor="let role of roleTypes" [value]="role.value">{{role.viewValue }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div class="col-sm-12 col-md-4 col-lg-2">
      <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 100%;">
        <mat-select  [(ngModel)]="Irole.roleStatus"  [placeholder]="'Status'"(selectionChange)="onFilterRole()">
          <mat-option *ngFor="let role of roleStatuses" [value]="role.value">{{role.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-12 col-md-4 col-lg-2">
      <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 100%;">
        <mat-select  [(ngModel)]="filterByFeature"  [placeholder]="'All Features'" (selectionChange)="onFilterRole()">
          <mat-option  value="">Select Feature</mat-option>
          <mat-option m-3 *ngFor="let feature of userAllFeatures" [value]="feature.alias">{{feature.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
      <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;" [subscriptSizing]="'dynamic'">
        <mat-icon class="icon-size-5"  matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
        <input matInput (keyup)="applyFilter($event)"  [(ngModel)]="filterValueToDisplay" [autocomplete]="'off'" [placeholder]="'Search Role Name'">
      </mat-form-field>
    </div>

  </div>

  </div>


  <div class="mat-elevation-z8 mt-2 overflow-auto">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef >Role Name</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}"  *matCellDef="let row"> {{row.name | titlecase}} </td>
      </ng-container>
      <!-- AvailabilityTimings Column -->
      <ng-container matColumnDef="roleType">
        <th mat-header-cell *matHeaderCellDef >Role Type</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> 
          <span *ngIf="row.role_type == 'CUSTOM_ROLES'">Custom Role </span>
          <span *ngIf="row.role_type == 'DEFAULT_ROLES'">Default Role</span>
          </td>
      </ng-container>

      <!-- AddBranch Column -->
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef >Created By</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
          <ng-container *ngIf="row.created_by; else defaultValue">
            {{row.created_by?.full_name}}<span *ngIf="row.created_by?.role">({{row.created_by.role.name}})</span>
          </ng-container>
          <ng-template #defaultValue>
          </ng-template>
        </td>
      </ng-container>
      <!-- AddAddress Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef >Created Date</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_at  | dateFormat}} </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <!-- <mat-cell style="height: 52px;" *matCellDef="let row;"> -->
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row ">
          <!-- <button mat-icon-button (click)="GetRoleDetailsById(row)">
            <mat-icon style="color:#0038FF" svgIcon="heroicons_outline:eye"></mat-icon>
          </button>
          <button mat-icon-button *ngIf="!row.is_removed" (click)="editRole(row.id)">
            <mat-icon style="color:#0FAE1F; height: 18px; width: 18px ;" svgIcon="heroicons_outline:pencil"></mat-icon>
          </button>
          <button mat-icon-button *ngIf="!row.is_removed" (click)="deactivateRole(row.id)">
            <mat-icon style="color:#FF0000" svgIcon="heroicons_outline:trash"></mat-icon>
          </button>
          <button mat-icon-button *ngIf="row.is_removed" (click)="reactivateRole(row.id)">
            <mat-icon style="color:#0000FF" svgIcon="heroicons_outline:refresh"></mat-icon>
          </button> -->
          <button mat-button [matMenuTriggerFor]="menu" ><mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="GetRoleDetailsById(row)">View</button>
            <!-- <button mat-menu-item [disabled]="!row.is_removed && isUserAllowedToRoleEdit" (click)="editRole(row.id)">Edit</button>
            <button mat-menu-item *ngIf="!row.is_removed " [disabled]=" isUserAllowedToRoleDeactivate" (click)="deactivateRole(row.id)">Deactivate</button>
            <button mat-menu-item *ngIf="row.is_removed "[disabled]=" isUserAllowedToRoleReactivate" (click)="reactivateRole(row.id)">Reactivate</button> -->
            <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToRoleEdit"  (click)="editRole(row.id)">Edit</button>
            <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToRoleDeactivate" (click)="verifyDeactivate(row.id)">Deactivate</button>
            <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToRoleReactivate" (click)="reactivateRole(row.id)">Reactivate</button>
          </mat-menu>
        
        </td>
        

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <div>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">
          <h3 style="display: flex;justify-content: center; margin:10%;">
            No Records Found
          </h3>
          </td>
        </tr>
      </div>
      
    </table>
  </div>
  
<mat-paginator style="display:flex;justify-content: end;" 
  (page)="onPaginateChange($event)" [length]="totalCount"
  [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
  aria-label="Select page of users" showFirstLastButtons class="paginator"></mat-paginator>

</div>