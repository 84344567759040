import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import {   MetaService, PlanTypeActions, PlansActions, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { CreatePlanTypesComponent } from '../createPlanTypes/create-plan-types.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';



export interface Element {
  is_removed: boolean;
  id: String;
}


@Component({
  selector: 'app-plan-types',
  templateUrl: './plan-types.component.html',
  styleUrls: ['./plan-types.component.scss']
})
export class PlanTypesComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['PlanType', 'CreatedBy', 'CreatedDate' ,'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list = [];
  PlanStatusType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDPlanType: PlanTypeActions = {
    planTypeActive: "",
    planTypeId: "",
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue=null;
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewPlanTypes = this.userAllowedFeatures.indexOf(featureConstants.VIEW_PLANS) > -1
  isUserAllowedToCreatePlanType = this.userAllowedFeatures.indexOf(featureConstants.CREATE_PLANS) > -1
  isUserAllowedToEditPlanTypes = this.userAllowedFeatures.indexOf(featureConstants.EDIT_PLANS) > -1
  isUserAllowedToDeactivatePlanType = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_PLANS) > -1
  isUserAllowedToReactivatePlanType = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_PLANS) > -1

  constructor(private router: Router, public dialog: MatDialog,public meta: MetaService, public apiService: ApiService, private toastr: ToastrService) {
    this.getScreenSize();
  }
  ngOnInit() {
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    this.filterPlanType()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getPlanType(){
    this.apiService.getPlanTypeDetails().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.plan_types;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
   
    // }
    this.onFilterPlanType()
  }
  
  filterPlanType() {
    let data = {}
    // if (this.IBranch.branchId) {
    data = {
      filter_by_plan_type_active_status: this.IDPlanType.planTypeActive,
      offset:this.bOffset,
      limit:this.bLimit,
      filter_by_search_term:this.filterValue || null

    }

    this.apiService.getPlanTypeByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.plan_types;
        this.totalCount=resp.data.total_count;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  
  scrHeight: number;
  scrWidth: number;
  viewWidth:any
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }
  createPlanType() {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreatePlanTypesComponent, {
      // data: {
      //   data: data,
      // },
      // // height: auto,
      width: this.viewWidth,
      
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPlanType()
    });
  }
  editPlanType(row) {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreatePlanTypesComponent, {
      data: {
        data: row,
      },
      // // height: auto,
      width: this.viewWidth,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPlanType()
    });
  }

  verifyDeactivate(plan_type_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "This PlanType won't be visible to anyone on mobile (As a result plans under this PlanType won't be visible to anyone on Mobile)."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivatePlanType(plan_type_id=plan_type_id)
      }
    });
  }

  deactivatePlanType(plan_type_id: String) {
    this.apiService.deactivatePlanType(plan_type_id = plan_type_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          plan_type => {
            if (plan_type.id == plan_type_id)
            plan_type.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivatePlanType(plan_type_id: String) {
    this.apiService.reactivatePlanType(plan_type_id = plan_type_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          plan_type => {
            if (plan_type.id == plan_type_id){
              plan_type.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterPlanType(); 
  }

  onFilterPlanType(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterPlanType()
  }

}
