import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import {   PlanTypeActions, PlansActions, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { CreatePlanTypesComponent } from '../../plan-types/createPlanTypes/create-plan-types.component';
import { ViewAddOnPlanComponent } from '../view-add-on-plan/view-add-on-plan.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';

export interface Element {
  is_removed: boolean;
  id: String;
}

@Component({
  selector: 'app-get-add-on-plan',
  templateUrl: './get-add-on-plan.component.html',
  styleUrls: ['./get-add-on-plan.component.scss']
})


export class GetAddOnPlanComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['AddOnName', 'AddOnHierarchy', 'AddOnType', 'AddOnPrice' ,'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list = [];
  AddonTypeDetails=[];
  addOnPlanTypes=[];
  PlanStatusType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDPlanType: PlanTypeActions = {
    planTypeActive: "",
    planTypeId: "",
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewAddonPlanTypes = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ADDON_PLANS) > -1
  isUserAllowedToCreateAddonPlanType = this.userAllowedFeatures.indexOf(featureConstants.CREATE_ADDON_PLANS) > -1
  isUserAllowedToEditAddonPlanTypes = this.userAllowedFeatures.indexOf(featureConstants.EDIT_ADDON_PLANS) > -1
  isUserAllowedToDeactivateAddonPlanType = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_ADDON_PLANS) > -1
  isUserAllowedToReactivateAddonPlanType = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_ADDON_PLANS) > -1

  constructor(private router: Router, public dialog: MatDialog, public apiService: ApiService, private toastr: ToastrService,private _liveAnnouncer: LiveAnnouncer) {
    this.getScreenSize();
  }
  ngOnInit() {
    this.getAddonPlanTypes()
    this.getAddOnPlans()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getAddOnPlans(){
    this.apiService.getAddOnPlanDetails().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.plans;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  

  getAddOnPlanById(row) {
    this.AddonTypeDetails = [];
    this.apiService.getAddOnPlanById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.AddonTypeDetails.push(resp.data.plan);
        this.viewUser(this.AddonTypeDetails)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  viewWidth:any
  viewUser(data) {
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewAddOnPlanComponent, {
      data: {
        data: data,
      },
      // height: auto,
      width: this.viewWidth,
    });
  }
  
  filterPlanType(value) {
    let data = {}
    data = {
      filter_by_plan_active_status: this.IDPlanType.planTypeActive,
      filter_by_plan_type_id: this.IDPlanType.planTypeId
    }

    this.apiService.getAddOnPlanByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.plans;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  
  scrHeight: number;
  scrWidth: number;
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }
  getAddonPlanTypes() {
    let data = {
      filter_by_plan_type_active_status: true
    }
    this.apiService.getAddOnTypeByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.addOnPlanTypes =  resp.data.add_on_plan_types;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  createPlanType() {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreatePlanTypesComponent, {
      // data: {
      //   data: data,
      // },
      // // height: auto,
      width: this.viewWidth,
      
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getPlanType()
    });
  }
  editPlanType(row) {
    if(this.scrWidth < 1450){
      this.viewWidth='90%'
    }else{
      this.viewWidth='40%'
    }
    let dialogRef = this.dialog.open(CreatePlanTypesComponent, {
      data: {
        data: row,
      },
      // // height: auto,
      width: this.viewWidth,
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getPlanType()
    });
  }

  createAddOn() {
    sessionStorage.removeItem('editAddonPlanId')
    this.router.navigateByUrl('Admin/create-addon-plan');
  }

  editAddOn(row) {
    sessionStorage.setItem('editAddonPlanId', row.id)
    this.router.navigateByUrl('Admin/edit-addon-plan');
   
    // this.router.navigate(['Admin/create-add-on-plan', { addon_plan_id:row.id }]);
   
  }
  deactivatePlanType(addon_plan_id: String) {
    this.apiService.deactivateAddOnPlanType(addon_plan_id = addon_plan_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          AddOnplan_type => {
            if (AddOnplan_type.id == addon_plan_id)
            AddOnplan_type.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivatePlanType(addon_plan_id : String) {
    this.apiService.reactivateAddOnPlanType(addon_plan_id  = addon_plan_id ).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          AddOnplan_type => {
            if (AddOnplan_type.id == addon_plan_id ){
              AddOnplan_type.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}

