import { Component,ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViewUserComponent } from 'app/pages/Users/view-user/view-user.component';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { ApiService } from 'app/api-services/api.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { IReport, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { UsageReportComponent } from '../usage-report/usage-report.component';
import { tick } from '@angular/core/testing';
import { ExcelDownloadService } from 'app/excel-download.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
export interface Element {
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved:boolean;
  CreatedBy:string;
}

@Component({
  selector: 'app-reports',
  templateUrl: './view-usage-reports.component.html',
  styleUrls: ['./view-usage-reports.component.scss']
})
export class ReportsComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['userName','defaultRole','attendanceDate', 'dutyTime','overTime', 'totalActiveTime', 'activeStatus'];
  dataSource = new MatTableDataSource<Element>();
  sortedData=[];
  branches_list:[]
  UserStatusType = [
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10;
  download_index:boolean;
  download_index2:boolean
  bOffset:number= 0;
  bLimit :number=10;
  IDReport: IReport = {
    branchId: "",
    roleId:"",
    userId:""
  }
  branchIdNameList = []
  selectedBranchIds = []
  roleIdNameList = []
  selectedroleIds = []
  userIdNameList = []
  selectedUserIds = []
  attendanceData;
  selectedGender = null;
  userActive = null;

  

  fromDate = null;
  toDate = null;
  maxDate = new Date();;
  roleList=[]
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToViewRoles = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1
  isUserAllowedToViewUsageReport = this.userAllowedFeatures.indexOf(featureConstants.ALLOWED_TO_USAGE_REPORT) > -1

  constructor( private router: Router,public dialog: MatDialog, public apiService: ApiService,private _liveAnnouncer: LiveAnnouncer, private toastr: ToastrService, private excelDownloadService: ExcelDownloadService) {
   
  }
  ngOnInit() {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    this.fromDate = moment(new Date(firstDay)).format("YYYY-MM-DD").toString();
    this.maxDate = new Date();
    this.toDate = moment(new Date(this.maxDate)).format("YYYY-MM-DD").toString();
    this.maxDate = this.toDate
    this.getRoles();
    this.getBranches();
    this.getUsers();
    this.getUsageReport1()
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
    this.dataSource.sort = this.sort
  }
  getRoles() {
    let data = {
      "filter_by_role_active_status": true
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleIdNameList = resp.data.roles.map(role => {
          return {
            "id": role.id, "name": role.name
          }
        });
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getBranches() {
    let data = {
      "filter_by_branch_active_status": true
    }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchIdNameList = resp.data.branches.map(branch => {
          return {
            "id": branch.id, "name": branch.name
          }
        })
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getUsers() {
    this.resetCheckedUsers()
    let data = {
      "filter_by_branch_ids": this.selectedBranchIds,
      "filter_by_role_ids": this.selectedroleIds
    }
    this.apiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userIdNameList = resp.data.users.map(user => {
          return {
            "id": user.id, "full_name": user.full_name
          }
        })
      }
    })
  }
  
  // SelecteAll checkbox for Users
  allBranchesChecked = false
  someBranchesChecked() {
    if (this.selectedBranchIds.length == this.branchIdNameList.length) {
      this.allBranchesChecked = true
    } else {
      this.allBranchesChecked = false
    }
    return this.selectedBranchIds.length > 0 && !this.allBranchesChecked;
  }
  setAllBranchesChecked(change) {
    if (change.checked) {
      this.allBranchesChecked = true;
      this.selectedBranchIds = this.branchIdNameList.map(e => e.id);
    } else {
      this.allBranchesChecked = false;
      this.selectedBranchIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Roles
  allRolesChecked = false
  someRolesChecked() {
    if (this.selectedroleIds.length == this.roleIdNameList.length) {
      this.allRolesChecked = true
    } else {
      this.allRolesChecked = false
    }
    return this.selectedroleIds.length > 0 && !this.allRolesChecked;
  }
  setAllRolesChecked(change) {
    if (change.checked) {
      this.allRolesChecked = true;
      this.selectedroleIds = this.roleIdNameList.map(e => e.id);
    } else {
      this.allRolesChecked = false;
      this.selectedroleIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Users
  allUsersChecked = false
  someUsersChecked() {
    if (this.selectedUserIds.length == this.userIdNameList.length) {
      this.allUsersChecked = true
    } else {
      this.allUsersChecked = false
    }
    return this.selectedUserIds.length > 0 && !this.allUsersChecked;
  }
  setAllUsersChecked(change) {
    if (change.checked) {
      this.allUsersChecked = true;
      this.selectedUserIds = this.userIdNameList.map(e => e.id);
    } else {
      this.allUsersChecked = false;
      this.selectedUserIds = [];
    }
  }

  getUsageReport1() {
    let fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString()
    let toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString()
    let requestBody = {
      from_date: fromDate,
      to_date: toDate,
      filter_by_branch_ids: this.selectedBranchIds,
      filter_by_role_ids: this.selectedroleIds,
      filter_by_user_ids: this.selectedUserIds,
      filter_by_gender: this.selectedGender,
      filter_by_active_status: this.userActive,
    }

    let queryParams={}
    
    if(this.download_index2){
      this.download_index2=false

    queryParams = {  
    }
  }
  else{
    queryParams = {
      offset:this.bOffset,
      limit:this.bLimit
    }
  }
  
   
    


    // let queryParams: { offset: number | null; limit: number | null } 
    
    // if (this.download_index) {
    //   this.download_index = false;
    //   queryParams.offset = null;
    //   queryParams.limit = null;
    // } else {
    //   queryParams.offset = this.bOffset;
    //   queryParams.limit = this.bLimit;
    // }
    

    this.apiService.getUsageReport(requestBody, queryParams).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.attendance_reports;
        this.dataSource.data.forEach((usageReportRow: any) => {
          if (usageReportRow.attendance_date) {
            usageReportRow.attendance_date = moment(new Date(usageReportRow.attendance_date)).format("DD-MM-YYYY").toString();
          }
        })
        this.totalCount=response.data.total_count;
        if(this.download_index){
          this.download_index=false;
          this.openUsageReportPopUp()
          this.getUsageReport1()
        }
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  // Resetting details
  resetCheckedUsers() {
    this.allUsersChecked = false;
    this.selectedUserIds = []
  }

  resetAll() {
    this.fromDate = null;
    this.toDate = null;
    this.selectedBranchIds=[];
    this.selectedroleIds=[];
    this.selectedUserIds=[];
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  openUsageReportPopUp() {
    this.attendanceData = this.dataSource.data
    let fileName = "AttendanceUsageReports_" + this.fromDate + "/" + this.toDate + '.' + 'xlsx'
    let headerNames = ["User", 'User Active Status', 'Branch', 'Default Role', 'Role', "Gender", "Duty Time in Minutes", "Over Time in Minutes", "Attendance Date", "Total Active Time in Minutes"]
    this.excelDownloadService.downloadExcelFile(this.attendanceData, headerNames, fileName);
    // this.meta.dialogClosed = false;
    // let dialogRef = this.dialog.open(UsageReportComponent, {
    //   data: {
    //     // Data: row,
    //   },
    //   width: '50%',
    // });
  }



  



  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getUsageReport1(); 
  }

  onGetUsageReport(){
    this.bOffset=0;
    this.pageIndex=0;
    this.getUsageReport1();
  }

  onClickDownload(): void{
    this.download_index2=true;
    this.download_index=true;
    this.getUsageReport1()
    
  }

}


