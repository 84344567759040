import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService, featureConstants } from 'app/meta.service';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';

export interface IReferralPercentages {
  id: String;
  is_removed: boolean;
}

@Component({
  selector: 'app-referral-percentages-management',
  templateUrl: './referral-percentages-management.component.html',
  styleUrls: ['./referral-percentages-management.component.scss']
})
export class ReferralPercentagesManagementComponent {

  dataSource = new MatTableDataSource<IReferralPercentages>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['name', 'description', 'referrerBonus', 'refereeDiscount', 'createdBy', 'createdDate', 'Actions'];

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToReferralPercentageCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_REFERRAL_PERCENTAGES) > -1
  isUserAllowedToReferralPercentageEdit = this.userAllowedFeatures.indexOf(featureConstants.EDIT_REFERRAL_PERCENTAGES) > -1
  isUserAllowedToReferralPercentageDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_REFERRAL_PERCENTAGES) > -1
  isUserAllowedToReferralPercentageReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_REFERRAL_PERCENTAGES) > -1

  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;
  bOffset: number = 0;
  bLimit: number = 10;

  filterOnSearchTerm = ''

  filterOnActiveStatus = ''
  activeStatusTypes = [
    { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }
  ]

  constructor(
    private router: Router, 
    public dialog: MatDialog,
    public meta: MetaService, 
    public apiService: ApiService, 
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterOnActiveStatus = paginationData.filterOnActiveStatus
    }
    
    this.getReferralPercentages();
  }

  getReferralPercentages(){

    let queryParamsData = {
      offset: this.bOffset,
      limit: this.bLimit,
      filter_by_referral_percentages_active_status: this.filterOnActiveStatus,
      filter_by_search_term: this.filterOnSearchTerm
    }

    this.apiService.getReferralPercentages(queryParamsData).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.referral_percentages;
        this.totalCount = resp.data.total_referral_percentages_count
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });

  }

  addReferralPercentages(){
    sessionStorage.removeItem('editReferralPercentagesId')
    this.router.navigateByUrl('Admin/create-referral-percentages');
  }

  editReferralPercentages(row){
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterOnActiveStatus: this.filterOnActiveStatus,
      filterOnSearchTerm: this.filterOnSearchTerm
    };
    sessionStorage.setItem('paginationDataForReferalPercentages', JSON.stringify(paginationData)); 
    sessionStorage.setItem('editReferralPercentagesId', row.id);
    this.router.navigateByUrl('Admin/edit-referral-percentages');
  }

  verifyDeactivateReferralPercentages(referralPercentagesId){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "This set of Referral Percentages will be deactivated. And incase of no active Referral Percentages here, the default percentages will be applied."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.meta.dialogClosed) {
        this.deactivateReferralPercentages(referralPercentagesId)
      }
    });
  }

  deactivateReferralPercentages(referralPercentagesId){
    this.apiService.deactivateReferralPercentages(referralPercentagesId).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          referralPercentage => {
            if (referralPercentage.id == referralPercentagesId)
              referralPercentage.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivateReferralPercentages(referralPercentagesId){
    this.apiService.reactivateReferralPercentages(referralPercentagesId).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          referralPercentage => {
            if (referralPercentage.id == referralPercentagesId)
              referralPercentage.is_removed = false
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    this.getReferralPercentages();
  }

}
