import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plan-expiry-renewal-tracks-management',
  templateUrl: './plan-expiry-renewal-tracks-management.component.html',
  styleUrls: ['./plan-expiry-renewal-tracks-management.component.scss']
})
export class PlanExpiryRenewalTracksManagementComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  scrHeight: number;
  scrWidth: number;

  title = "Plan Expiry-Renewal Tracks";

  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;

  filterBySearchTerm = ''
  filterByBranchId = '';
  filterByFromDate = '';
  filterByToDate = '';
  filterByExpiryTrackStatus = '';
  filterByRenewalTrackStatus = '';
  
  branchesList = [];

  expiryTrackStatuses = [
    { value: '', viewValue: 'All Expiry Statuses' },
    { value: 'EXPIRING', viewValue: 'Expiring' },
    { value: 'EXPIRED', viewValue: 'Expired' }
  ]

  renewalTrackStatuses = [
    { value: '', viewValue: 'All Renew Statuses' },
    { value: 'RENEWED', viewValue: 'Renewed' },
    { value: 'NOT_RENEWED', viewValue: 'Not Renewed' }
  ]

  displayedColumns: string[] = ["userName", "expiryPlanName", "expiryPlanDates", "expiryPlanStatus", "expiryPlanTrackStatus", "divider", "renewalPlanTrackStatus", "renewalPlanName", "renewalPlanDates", "renewalPlanStatus"];

  dataSource = new MatTableDataSource<any>();

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1

  constructor(
    public apiService: ApiService, 
    public toastr: ToastrService, 
    public router: Router
  ){
    this.getScreenSize();
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForPlanExpiryRenewalTracks');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterBySearchTerm = paginationData.filterValue;
      this.filterByBranchId = paginationData.filterByBranchId;
      this.filterByFromDate = paginationData.filterByFromDate;
      this.filterByToDate = paginationData.filterByToDate;
      this.filterByExpiryTrackStatus = paginationData.filterByExpiryStatus;
      this.filterByRenewalTrackStatus = paginationData.filterByRenewalStatus;
    }

    this.getBranches()

    this.getPlanExpiryRenewalTracks()
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  getBranches() {
    let data = {filter_by_branch_active_status: true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchesList = resp.data.branches
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  applyFilter(event: Event) {
    this.filterBySearchTerm = (event.target as HTMLInputElement).value;
    this.onGetPlanExpiryRenewalTracks()
  }

  onGetPlanExpiryRenewalTracks(){
    this.pageIndex = 0;
    this.bOffset = 0;

    this.getPlanExpiryRenewalTracks()
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    this.getPlanExpiryRenewalTracks(); 
  }

  getPlanExpiryRenewalTracks(){


    let filterByfromDateBE = this.filterByFromDate ? moment(new Date(this.filterByFromDate)).format("YYYY-MM-DD").toString(): null;
    let filterByToDateBE = this.filterByToDate ? moment(new Date(this.filterByToDate)).format("YYYY-MM-DD").toString(): null;
    let data: any = {
      offset: this.bOffset,
      limit: this.bLimit,
      filter_by_search_term: this.filterBySearchTerm || null,
      filter_by_branch_id: this.filterByBranchId || null,
      filter_by_from_date: filterByfromDateBE,
      filter_by_to_date: filterByToDateBE,
      filter_by_expiry_track_status: this.filterByExpiryTrackStatus || null,
      filter_by_renewal_track_status: this.filterByRenewalTrackStatus || null,
    };
    
    this.apiService.getExpiryRenewalTracksAPI(data).subscribe((response) => {
      if (response.status == "success") {
        this.totalCount = response.data.total_count;
        let expiryRenewalTracks = []
        response.data.expiry_renewal_tracks.forEach(expiryRenewalTrackDataObj => {
          let expiryRenewalTrackObj = {
            "user": { 
              name: expiryRenewalTrackDataObj.user.full_name, 
              mobileNumber: expiryRenewalTrackDataObj.user.mobile_number
            },
            "expiryPlanTrackStatus": expiryRenewalTrackDataObj.expiry_track.track_status,
            "renewalPlanTrackStatus": expiryRenewalTrackDataObj.renewal_track.track_status,
            "expiryPlan": {
              planId: expiryRenewalTrackDataObj.expiry_track.track.plan.id,
              planName: expiryRenewalTrackDataObj.expiry_track.track.plan.name,
              planDuration: expiryRenewalTrackDataObj.expiry_track.track.plan_duration.validity_in_months,
              PlanStartDate: expiryRenewalTrackDataObj.expiry_track.track.user_order_plan.start_date,
              PlanEndDate: expiryRenewalTrackDataObj.expiry_track.track.user_order_plan.end_date,
              createdDateTime: expiryRenewalTrackDataObj.expiry_track.track.user_order_plan.created_at,
              planStatus: expiryRenewalTrackDataObj.expiry_track.track.user_order_plan.plan_status,
              planBranch: expiryRenewalTrackDataObj.expiry_track.track.branch.name,
            },
            "renewalPlan": {
              planId: '',
              planName: '',
              planDuration: '',
              PlanStartDate: '',
              PlanEndDate: '',
              createdDateTime: '',
              planStatus: '',
              planBranch: '',
            }
          }

          if(expiryRenewalTrackDataObj.renewal_track.track){
            expiryRenewalTrackObj["renewalPlan"] = {
              planId: expiryRenewalTrackDataObj.renewal_track.track.plan.id,
              planName: expiryRenewalTrackDataObj.renewal_track.track.plan.name,
              planDuration: expiryRenewalTrackDataObj.renewal_track.track.plan_duration.validity_in_months,
              PlanStartDate: expiryRenewalTrackDataObj.renewal_track.track.user_order_plan.start_date,
              PlanEndDate: expiryRenewalTrackDataObj.renewal_track.track.user_order_plan.end_date,
              createdDateTime: expiryRenewalTrackDataObj.renewal_track.track.user_order_plan.created_at,
              planStatus: expiryRenewalTrackDataObj.renewal_track.track.user_order_plan.plan_status,
              planBranch: expiryRenewalTrackDataObj.renewal_track.track.branch.name,
            }
          }

          expiryRenewalTracks.push(expiryRenewalTrackObj)


        });
        this.dataSource.data = expiryRenewalTracks;
      
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
      }
    );

  }

  










}
