import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { IDeviceActions, MetaService, PlanTypeActions, PlansActions, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { ViewPlanSubTypeComponent } from '../view-plan-sub-type/view-plan-sub-type.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';



export interface Element {
  is_removed: boolean;
  id: String;
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved: boolean;
  CreatedBy: string;
}


@Component({
  selector: 'app-plan-sub-types',
  templateUrl: './plan-sub-types.component.html',
  styleUrls: ['./plan-sub-types.component.scss']
})
export class PlanSubTypesComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['PlanSubType', 'PlanType', 'CreatedBy', 'CreatedDate' ,'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list = [];
  PlanStatusSubType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IDPlanSubType: PlanTypeActions = {
    planTypeActive: "",
    planTypeId: "",
  }
  PlanSubTypeDetails=[];
  scrHeight: number;
  scrWidth: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValueToDisplay="";
  filterValue=null;
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewPlanSubTypes = this.userAllowedFeatures.indexOf(featureConstants.VIEW_PLANS) > -1
  isUserAllowedToCreatePlanSubType = this.userAllowedFeatures.indexOf(featureConstants.CREATE_PLANS) > -1
  isUserAllowedToEditPlanSubTypes = this.userAllowedFeatures.indexOf(featureConstants.EDIT_PLANS) > -1
  isUserAllowedToDeactivatePlanSubType = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_PLANS) > -1
  isUserAllowedToReactivatePlanSubType = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_PLANS) > -1

  constructor(private router: Router, public dialog: MatDialog,public meta: MetaService, public apiService: ApiService, private toastr: ToastrService) {
    this.getScreenSize();
  }
  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.IDPlanSubType.planTypeActive=paginationData.filterByPlanSubTypeActiveStatus;
      this.filterValue=paginationData.filterValue;
      this.filterValueToDisplay=paginationData.filterValue;
    }
    this.filterPlanType()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
   
    // }
    this.onFilterPlanTypeForsearch()
  }
  getPlanSubType(){
    this.apiService.getPlanSubTypeDetails().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.plan_sub_types;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  getBranches() {
    let data = { "filter_by_branch_active_status": true }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branches_list = resp.data.branches
        // this.toastr.success("Branches Loaded successfully");
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  filterPlanType() {
    let data = {}
    // if (this.IBranch.branchId) {
    data = {
      filter_by_plan_sub_type_active_status: this.IDPlanSubType.planTypeActive,
      offset:this.bOffset,
      limit:this.bLimit,
      filter_by_search_term:this.filterValue || null

    }

    this.apiService.getPlanSubTypeByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data =  resp.data.plan_sub_types;
        this.totalCount=resp.data.total_count;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  addPlanSubType() {
    sessionStorage.removeItem('editPlanSubTypeId')
    this.router.navigateByUrl('Admin/create-plan-sub-types');
  }
  editPlanSubType(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByPlanSubTypeActiveStatus: this.IDPlanSubType.planTypeActive,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForPlanSubType', JSON.stringify(paginationData));
    sessionStorage.setItem('editPlanSubTypeId', row.id)
    // this.router.navigate(['Admin/create-plan-sub-types', { plan_subtype_id: row.id }]);
    this.router.navigateByUrl('Admin/edit-plan-sub-types');
   
  }
  viewWidth:any
  viewUser(data) {
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewPlanSubTypeComponent, {
      data: {
        data: data,
      },
      // height: auto,
      width: this.viewWidth,
    });
  }
  getPlanSubTypeById(row) {
    this.PlanSubTypeDetails = [];
    this.apiService.getPlanSubTypeById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.PlanSubTypeDetails.push(resp.data.plan_sub_type);
        this.viewUser(this.PlanSubTypeDetails)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  verifyDeactivate(plan_subtype_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "This PlanSubType won't be visible on mobile (But right now we are not showing this on Mobile App)."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivatePlanSubType(plan_subtype_id=plan_subtype_id)
      }
    });
  }

  deactivatePlanSubType(plan_subtype_id: String) {
    this.apiService.deactivatePlanSubType(plan_subtype_id = plan_subtype_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          plan_type => {
            if (plan_type.id == plan_subtype_id)
            plan_type.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivatePlanSubType(plan_subtype_id: String) {
    this.apiService.reactivatePlanSubType(plan_subtype_id = plan_subtype_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          plan_type => {
            if (plan_type.id == plan_subtype_id){
              plan_type.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterPlanType(); 
  }
  onFilterPlanTypeForsearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByPlanSubTypeActiveStatus: this.IDPlanSubType.planTypeActive,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForPlanSubType', JSON.stringify(paginationData));
    this.filterPlanType()
  }

  onFilterPlanType(){
    this.pageIndex=0;
    this.bOffset=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByPlanSubTypeActiveStatus: this.IDPlanSubType.planTypeActive,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForPlanSubType', JSON.stringify(paginationData));
    this.filterPlanType()
  }
    

}
