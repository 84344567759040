import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-active-schedules-list',
  templateUrl: './active-schedules-list.component.html',
  styleUrls: ['./active-schedules-list.component.scss'],
  providers: [DatePipe]
})
export class ActiveSchedulesListComponent {

  scrHeight: number;
  scrWidth: number;

  searchTerm = "";

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToViewSchedules = this.userAllowedFeatures.indexOf(featureConstants.VIEW_SCHEDULES) > -1
  isUserAllowedToCreateSchedules = this.userAllowedFeatures.indexOf(featureConstants.CREATE_SCHEDULES) > -1


  branchesList = [];
  selectedFilterbranchId = "";

  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue=null;
  filterValueToDisplay="";

  searchInputControl: UntypedFormControl = new UntypedFormControl();
  
  displayedColumns = ["Customer", "Plan", "PlanType", "PlanSubType", "PlanCategory", "PlanDuration", "PlanStatus", "Branch", "Actions"]; 
  dataSource = new MatTableDataSource<Element>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public apiService: ApiService, public toastr: ToastrService, private router: Router, private datePipe: DatePipe) {
    this.getScreenSize();
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForDevice')
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.selectedFilterbranchId=paginationData.filterByBranchId;
      this.filterValue=paginationData.filterValue
      this.filterValueToDisplay=paginationData. filterValue;
    }
    
    this.getAllSchedules()
    this.getBranchesListToFilter();
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getBranchesListToFilter() {
    this.apiService.getBranches().subscribe((resp) => {
      if (resp.status == "success") {
        this.branchesList = resp.data.branches.map(branch => {
          return {id: branch.id, name: branch.name}
        })
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  getAllSchedules() {

    let filterData = {
      filter_by_branch_id: this.selectedFilterbranchId,
      offset:this.bOffset,
      limit:this.bLimit,
      filter_by_search_term:this.filterValue || null
    }

    this.apiService.getActiveSchedulesByFilter(filterData).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.schedule_list.map(schedule_data => { 
          return {
            "userId": schedule_data.user.id,
            "userOrderPlanId": schedule_data.user_order_plan_id,
            "customer": schedule_data.user.full_name,
            "plan": schedule_data.plan.name,
            "planType": schedule_data.plan_type.name,
            "planSubType": schedule_data.plan_type.plan_subtype?.name,
            "planTypeAlias": schedule_data.plan_type_alias,
            "planCategory": schedule_data.plan_type_alias.replaceAll('_', ' '),
            "planDuration": this.datePipe.transform(
              schedule_data.plan_duration.start_date, 'dd MMMM YYYY'
            ) + ' to ' + this.datePipe.transform(
              schedule_data.plan_duration.end_date, 'dd MMMM YYYY'
              ),
            "planStatus": schedule_data.user_order_plan_status,
            "branch": schedule_data.branch.name
          }
        });
        this.totalCount=response.data.total_count
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    
    // }
    this.onGetAllSchedulesForSearch()
  }

  getPlanSchedules(row) {

    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByBranchId: this.selectedFilterbranchId,
      filterValue:this.filterValue
    };

    sessionStorage.setItem('paginationDataForScheduleList', JSON.stringify(paginationData));
    sessionStorage.setItem('userSchedulesUserId', row.userId);
    sessionStorage.setItem('userSchedulesUserOrderPlanId', row.userOrderPlanId);
    sessionStorage.setItem('userSchedulesPlanTypeAlias', row.planTypeAlias);
    this.router.navigate(['Admin/user-schedules'])
  }

  getAddonPlanSchedules(row) {
    sessionStorage.setItem('userSchedulesUserId', row.userId);
    sessionStorage.setItem('userSchedulesUserOrderPlanId', row.userOrderPlanId);
    sessionStorage.setItem('userSchedulesPlanTypeAlias', row.planTypeAlias);
    this.router.navigate(['Admin/user-schedules'])
  }

  createAddonPlanSchedule(row) {
    sessionStorage.removeItem('slotBookingIdToUpdateSchedule')
    sessionStorage.setItem('userOrderPlanIdToCreateSchedule', row.userOrderPlanId);
    this.router.navigate(['Admin/create-schedule'])
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getAllSchedules(); 
  }

  onGetAllSchedulesForSearch(){
    this.bOffset=0;
    this.pageIndex=0;

    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByBranchId: this.selectedFilterbranchId,
      filterValue:this.filterValue
    };

    sessionStorage.setItem('paginationDataForScheduleList', JSON.stringify(paginationData));
    this.getAllSchedules();
  }

  onGetAllSchedules(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterValue=null;

    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByBranchId: this.selectedFilterbranchId,
      filterValue:this.filterValue
    };

    sessionStorage.setItem('paginationDataForScheduleList', JSON.stringify(paginationData));
    this.getAllSchedules();
  }

}
