import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { IBranchActions, MetaService, featureConstants } from 'app/meta.service';
import { ViewUserComponent } from 'app/pages/Users/view-user/view-user.component';
import { ViewBranchComponent } from '../view-branch/view-branch.component';
import { ToastrService } from 'ngx-toastr';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import {VerifyDeactivateComponent} from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';

export interface Element {
  is_removed: boolean;
  id: String;
  BranchName: string;
  Location: string;
  Number: string;
  // IsRemoved:boolean;
  AssignUser: string;
  ViewUser: string;

  Actions: string;


}
// const ELEMENT_DATA: Element[] = [
//   {BranchName: 'Jubilee Hills Branch', Location: 'Hyderabad', IsRemoved: false,Number:'+91-9542999999', CreatedBy: 'ADMIN'},
//   {BranchName: 'Gachibowli Branch', Location: 'Hyderabad', IsRemoved: false,Number:'+91-9542999999', CreatedBy: 'ADMIN'},

// ];

@Component({
  selector: 'app-branches-list',
  templateUrl: './branches-list.component.html',
  styleUrls: ['./branches-list.component.scss']
})

export class BranchesListComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['BranchName', 'Location', 'Number', 'ViewUser', 'Actions'];
  dataSource = new MatTableDataSource<Element>();
  branches_list: [];
  branchType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  IBranch: IBranchActions = {
    branchActive: '',
    branchId: "",
    branchName: "",
  }
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue=null;
  search="";
  filterValueToDisplay="";
  branchDetails = [];
  timeSlots = [];
  scrHeight: number;
  scrWidth: number;
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToBranchCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_BRANCHES) > -1
  isUserAllowedToBranchEdit = this.userAllowedFeatures.indexOf(featureConstants.EDIT_BRANCHES) > -1
  isUserAllowedToBranchDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_BRANCHES) > -1
  isUserAllowedToBranchReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_BRANCHES) > -1
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router, public dialog: MatDialog,public meta: MetaService, public apiService: ApiService, private toastr: ToastrService) {
    this.getScreenSize();
 
  }
  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue=paginationData.filterValue;
      this.IBranch.branchActive=paginationData.filterByStatus;
      this.filterValueToDisplay=paginationData.filterValue;
    
    }
    this.filterBranches()
  }
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
     this.filterValue = (event.target as HTMLInputElement).value;
     
    //
    // }
    this.onFilterBranchesForSearch()
  }
  getBranches() {
    let data = {"filter_by_branch_active_status": true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.branches;
        // this.branches_list = resp.data.branches
        // this.toastr.success("Branches Loaded successfully");
        
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  filterBranches() {
    let data = {
      filter_by_branch_active_status: this.IBranch.branchActive,
      offset: this.bOffset,
      limit: this.bLimit,
      search_term:this.filterValue || null
    }
    
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.branches;
        this.totalCount=resp.data.total_branches_count;
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }
  createBranch() {
    sessionStorage.removeItem('editBranchId')
    this.router.navigateByUrl('Admin/create-branch');
  }
  editBranch(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByStatus:this.IBranch.branchActive
    };
    sessionStorage.setItem('paginationDataForBranch', JSON.stringify(paginationData));
    sessionStorage.setItem('editBranchId', row)
    // this.router.navigate(['Admin/create-branch', { branch_id: row }]);
    this.router.navigateByUrl('Admin/edit-branch');

  }

  getBranchById(row) {
    this.branchDetails = []
    this.timeSlots = []
    this.apiService.getBranchById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchDetails.push(resp.data.branch);

        this.branchDetails.forEach(element => {
          this.timeSlots = element.timings
        });
        this.viewBranch(this.branchDetails, this.timeSlots)
      }

    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  viewWidth:any;
  viewBranch(branchDetails, timeSlots) {
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewBranchComponent, {
      data: {
        branchDetails: branchDetails,
        timeSlots: timeSlots
      },
      // height: '90%',
      width: this.viewWidth,
    });
  }
  // viewUserByBranch(row) {
  //   this.router.navigate(['Admin/users', { branch_id: row }]);
  // }


  verifyDeactivate(branch_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "All the people under this branch will be unassigned.After reactivating the branch we have assign branch to the every user."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivateBranch(branch_id=branch_id)
      }
    });
  }

  deactivateBranch(branch_id: String) {
    this.apiService.deactivateBranch(branch_id = branch_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          branch => {
            if (branch.id == branch_id)
              branch.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivateBranch(branch_id: String) {
    this.apiService.reactivateBranch(branch_id = branch_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          branch => {
            if (branch.id == branch_id)
              branch.is_removed = false
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterBranches(); 
  }

  onFilterBranchesForSearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByStatus:this.IBranch.branchActive
    };
    sessionStorage.setItem('paginationDataForBranch', JSON.stringify(paginationData));


    this.filterBranches();
  }

  onFilterBranches(){
    this.pageIndex=0;
    this.bOffset=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByStatus:this.IBranch.branchActive
    };
    sessionStorage.setItem('paginationDataForBranch', JSON.stringify(paginationData));


    this.filterBranches();
  }



}
