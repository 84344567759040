import { Component, Inject, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, UntypedFormControl, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { IAttendance, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ITicketActions } from "app/meta.service";
import moment from 'moment';
@Component(
    {
        selector: 'app-timeslot-discounts',
        templateUrl: './create-timeslot-discounts.component.html',
        styleUrls: ['./create-timeslot-discounts.component.scss']
    }
)
export class CreateTimeSlotDiscountsComponent {
    public timeslotDiscountsForm: FormGroup;
    startDate = null
    endDate = null;
    newDate: any;
    data;
    timeSlots = [];
    timeslot_discount_id: string;
    isUpdate: boolean = false;
    title: string = 'CREATE TIMESLOT DISCOUNT';
    constructor( private _formBuilder: FormBuilder,private router: Router,public apiService: ApiService,private toastr: ToastrService,private route: ActivatedRoute){
        // this.route.params.subscribe((params) => {
        //     this.timeslot_discount_id = params['timeslot_discount_id'];
        // });
        this.timeslot_discount_id = sessionStorage.getItem('editTimeslotDiscountId')
    }
    back() {
        this.router.navigateByUrl('Admin/timeslot-discounts');
    }
    ngOnInit() {
        this.getTimeslotAliases();
        this.buildForm()
        this.newDate = new Date();
        this.newDate = moment(new Date(this.newDate)).format("YYYY-MM-DD").toString();
        if (this.timeslot_discount_id != null) {
            this.getTimeSlotDiscountById();
            this.isUpdate = true;
            this.title = "EDIT TIMESLOT DISCOUNT"
        }
    }
    public buildForm() {
        this.timeslotDiscountsForm = this._formBuilder.group({
            timeSlot: new FormControl('', [Validators.required]),
            discountPercentage: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)])
        });
    }
    resetForm(){
        this.buildForm()
        this.startDate = null;
        this.endDate = null;
      }
    createTimeslotDiscount() {
        let start_date = null;
        let end_date = null;
        if (this.startDate) {
            start_date = moment(new Date(this.startDate)).format("YYYY-MM-DD").toString()
        }
        if (this.endDate) {
            end_date = moment(new Date(this.endDate)).format("YYYY-MM-DD").toString()
        }
        let requestData = { 
            time_slot: this.timeslotDiscountsForm.controls.timeSlot.value,
            start_date: start_date,
            end_date: end_date,
            discount_percentage: this.timeslotDiscountsForm.controls.discountPercentage.value,
            branch_ids:[]
        }
        this.apiService.createTimeslotDiscount(requestData).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.router.navigateByUrl('Admin/timeslot-discounts');
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }
    updateTimeslotDiscount() {
        let requestData = { 
            time_slot: this.timeslotDiscountsForm.controls.timeSlot.value,
            start_date: moment(new Date(this.startDate)).format("YYYY-MM-DD").toString(),
            end_date: moment(new Date(this.endDate)).format("YYYY-MM-DD").toString(),
            discount_percentage: this.timeslotDiscountsForm.controls.discountPercentage.value,
            branch_ids:[]
        }
        this.apiService.updateTimeslotDiscount(requestData, this.timeslot_discount_id).subscribe(
            (resp) => {
                if (resp.status == 'success') {
                    this.router.navigateByUrl('Admin/timeslot-discounts');
                }
            },
            (err) => {
                this.toastr.error(err.error.message);
            }
        );
    }
    getTimeSlotDiscountById() {
        this.apiService.getTimeslotDiscountById(this.timeslot_discount_id).subscribe((resp)=>{
            if (resp.status == "success") {
                this.timeslotDiscountsForm.controls.timeSlot.setValue(resp.data.timeslot_discount_details.time_slot_alias.alias)
                this.startDate = resp.data.timeslot_discount_details.start_date
                this.endDate = resp.data.timeslot_discount_details.end_date
                this.timeslotDiscountsForm.controls.discountPercentage.setValue(resp.data.timeslot_discount_details.discount_percentage)
            }},
            (err) => {
                this.toastr.error(err.error.message)
            }
        )
    }
    getTimeslotAliases() {
        this.apiService.getAllTimeslotAliases().subscribe((resp)=>{
            if (resp.status == "success") {
                this.timeSlots = resp.data.time_slots;
            }
            },
            (err) => {
                this.toastr.error(err.error.message)
            })
    }

}