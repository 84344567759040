<div class="container-fluid">
    <div class="d-flex m-2 mb-4">
      <div class=" mr-2">
        <button mat-icon-button (click)="back()">
          <mat-icon style="color:#00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
        </button>
      </div>
      <h2 class="h3 createHeading">{{title}}</h2>
    </div>
        
    <div class="m-2">
      <div class="card-header mb-4">
        <h4 class="p-3 ml-2.5 " style="">
          Access Controls
        </h4>
      </div>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel *ngFor="let head of heading; let i = index ">
          <mat-expansion-panel-header>
            <mat-panel-title [ngClass]="{'h6': step === 0}"
              style="color: #000000 !important; font-weight: bold; font-size: 18px;">
              {{headingArr[i]}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style="font-size: 16px">Features</th>
                <th scope="col"></th>
                <th scope="col" style="font-size: 16px">Web</th>
                <th scope="col" style="font-size: 16px">Mobile</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataArr[i]">
                <td width="60%" class="fieldName">{{row.name}}</td>
                <td width="10px"></td>
                <td width="20px"><mat-checkbox [disabled]="!row.is_available_on_web_app"
                    [checked]="row.is_checked_on_web_app" (change)='selectedWed(row.alias,$event)'
                    class="example-margin"></mat-checkbox></td>
                <td width="20px"><mat-checkbox [disabled]="!row.is_available_on_mobile_app"
                    [checked]="row.is_checked_on_mobile_app" (change)='selectedMobile(row.alias,$event)'
                    class="example-margin"></mat-checkbox></td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
  
    </div>
  
    <div class="m-2 btnposition">
      <div class="bottomBtns mb-3 ">
        <button class="ml-4 addBtn" (click)="back()" mat-flat-button>
          <span class="ml-2 mr-1 txt">Cancel</span>
        </button>
        <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="updateUserAccesses()"
          [disabled]="false" [ngClass]="{'btn-clr': true}">
          <span class="ml-2 mr-1 txt" style="color: white">Update </span>
        </button>
      </div>
    </div>
  
  </div>