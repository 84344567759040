import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { IAttendance, MetaService, featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { ViewCouponComponent } from '../view-coupon/view-coupon.component';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
export interface Icoupon {
  id: String;
  is_removed: boolean;
  couponTypeId: string;
  status:string;
}
@Component({
  selector: 'app-get-coupon',
  templateUrl: './get-coupon.component.html',
  styleUrls: ['./get-coupon.component.scss']
})

export class GetCouponComponent {

  scrHeight: number;
  scrWidth: number;

  searchTerm = "";
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValueToDisplay=""
  filterValue=null;
  couponTypesList:[]
  ICoupon: Icoupon = {
    id: "",
    is_removed: false,
    couponTypeId: "",
    status:"",
  }
  userBranchNames=[]
  couponStatus = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  
  displayedColumns = ["name", "code", "discount", "couponUsageCount", "coupontype", "validfrom", "validto", "CreatedBy", "CreatedDate", "Actions"]; 
  dataSource = new MatTableDataSource<Icoupon>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewCoupons = this.userAllowedFeatures.indexOf(featureConstants.VIEW_COUPONS) > -1
  isUserAllowedToCreateCoupons = this.userAllowedFeatures.indexOf(featureConstants.CREATE_COUPONS) > -1
  isUserAllowedToEditCoupons = this.userAllowedFeatures.indexOf(featureConstants.EDIT_COUPONS) > -1
  isUserAllowedToDeactivateCoupons = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_COUPONS) > -1
  isUserAllowedToReactivateCoupons = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_COUPONS) > -1

  constructor(public apiService: ApiService,public meta : MetaService, public toastr: ToastrService, private router: Router, public dialog: MatDialog) {
    this.getScreenSize();
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForDevice')
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
   
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.ICoupon.couponTypeId=paginationData.filterByCouponTypeId;
      this.ICoupon.status=paginationData.filterByActiveStatus;
      this.filterValue=paginationData.filterValue;
      this.filterValueToDisplay=paginationData.filterValue;
    }
    
    this.getCouponTypes()
    this.filterCoupons()

    // this.getCoupons()
    
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getCoupons(){
    this.apiService.getCouponDetails().subscribe((resp)=>{
      if (resp.status == "success") {
        this.dataSource.data = resp.data.coupons;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    
     
    this.onFilterCouponsForSearch()
  }

  verifyDeactivate(coupon_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "This Coupon won't be visible on Mobile App."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deactivateCoupon(coupon_id=coupon_id)
      }
    });
  }

  viewWidth:any
  viewCoupon(couponId) {
    sessionStorage.setItem('viewCouponId', couponId)

    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewCouponComponent, {
      data: {},
      // height: '90%',
      width: this.viewWidth,
    });

    dialogRef.afterClosed().subscribe(result => {
      sessionStorage.removeItem('viewCouponId')
    })
    
  }

  createCoupon() {
    sessionStorage.removeItem('editCouponId')
    this.router.navigateByUrl('Admin/create-coupon');
  }
  
  updateCoupon(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByCouponTypeId: this.ICoupon.couponTypeId,
      filterByActiveStatus: this.ICoupon.status,
      filterValue: this.filterValue
    };

    sessionStorage.setItem('paginationDataForCoupon', JSON.stringify(paginationData)); 
    sessionStorage.setItem('editCouponId', row.id)
    this.router.navigateByUrl('Admin/edit-coupon')
    // this.router.navigate(['Admin/create-coupon', { coupon_id:row.id }])
    
  }
  reactivateCoupon(coupon_id: String) {
    this.apiService.reactivateCoupon(coupon_id = coupon_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          coupon => {
            if (coupon.id == coupon_id){
              coupon.is_removed = false
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  deactivateCoupon(coupon_id: String) {
    this.apiService.deactivateCoupon(coupon_id = coupon_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          coupon => {
            if (coupon.id == coupon_id){
              coupon.is_removed = true
            }
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  getCouponTypes() {
    let data = {"filter_by_active_status": true}
    this.apiService.getCouponTypeByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        // this.dataSource.data = resp.data.branches;
        this.couponTypesList = resp.data.coupon_types
        // this.toastr.success("Branches Loaded successfully");
      }
    })
  }

  filterCoupons() {
    let data = {}
      data = {
        filter_by_coupon_type_id: this.ICoupon.couponTypeId,
        filter_by_active_status: this.ICoupon.status,
        offset: this.bOffset,
        limit: this.bLimit,
        search_term: this.filterValue || null
      }
   
    this.apiService.getCouponsByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data=resp.data.coupons;
        this.totalCount=resp.data.total_count;
      }
    });
  }
  
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterCoupons(); 
  }

  onFilterCouponsForSearch(){
    this.bOffset=0;
    this.pageIndex=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByCouponTypeId: this.ICoupon.couponTypeId,
      filterByActiveStatus: this.ICoupon.status,
      filterValue: this.filterValue
    };

    sessionStorage.setItem('paginationDataForCoupon', JSON.stringify(paginationData)); 

    this.filterCoupons()
  }
  onFilterCoupons(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByCouponTypeId: this.ICoupon.couponTypeId,
      filterByActiveStatus: this.ICoupon.status,
      filterValue: this.filterValue
    };

    sessionStorage.setItem('paginationDataForCoupon', JSON.stringify(paginationData)); 
    this.filterCoupons()
  }
}

