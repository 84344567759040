import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import { MetaService } from 'app/meta.service';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss']
})
export class CancelPlanComponent {

  public cancelPlanInfoRequestForm: FormGroup;
  public cancelPlanInfoResponse: any = {}
  public showCalculatableForm: boolean = true
  public showCancelPlanInfo: boolean = false
  public showErrorMsg: boolean = false
  public errorMsg: string = ''

  handleStartingSpaceTextInput = handleStartingSpaceTextInput


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CancelPlanComponent>, 
    public meta: MetaService,
    public apiService: ApiService, 
    private _formBuilder: FormBuilder, 
    private toastr: ToastrService,
  ) {

  }

  ngOnInit(){
    this.meta.dialogClosed = false
    this.buildForms()
  }

  public buildForms(){
    this.cancelPlanInfoRequestForm = this._formBuilder.group({
      cancellationFeeAmount: new FormControl(null),
      cancellationFeePercentage: new FormControl(null),
      couponValidityInDays: new FormControl(null),
    });
  }

  allowNumkeyPress(event: any) {
    const pattern = /[0-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  allowFloatNumkeyPress(event: any) {
    const pattern = /[0-9\.]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateAndGetRequestBody(){

    if(this.cancelPlanInfoRequestForm.controls.cancellationFeeAmount.value == ''){
      this.cancelPlanInfoRequestForm.controls.cancellationFeeAmount.setValue(null)
    }

    if(this.cancelPlanInfoRequestForm.controls.cancellationFeePercentage.value == ''){
      this.cancelPlanInfoRequestForm.controls.cancellationFeePercentage.setValue(null)
    }

    if(this.cancelPlanInfoRequestForm.controls.couponValidityInDays.value == ''){
      this.cancelPlanInfoRequestForm.controls.couponValidityInDays.setValue(null)
    }

    if(
      this.cancelPlanInfoRequestForm.controls.cancellationFeeAmount.value
      && this.cancelPlanInfoRequestForm.controls.cancellationFeePercentage.value
    ){
      this.showErrorMsg = true
      this.errorMsg = "Enter either Fee amount or percentage"
      return null
    }

    let requestBody = {
      "user_order_plan_id": this.data.data.userOrderPlanId,
      "cancellation_fee_amount": this.cancelPlanInfoRequestForm.controls.cancellationFeeAmount.value,
      "cancellation_fee_percentage": this.cancelPlanInfoRequestForm.controls.cancellationFeePercentage.value,
      "coupon_validity_in_days": this.cancelPlanInfoRequestForm.controls.couponValidityInDays.value,
    }

    return requestBody
    
  }

  getCancelPlanInfo(){
    let requestBody = this.validateAndGetRequestBody()
    if(!requestBody){
      return
    }

    this.apiService.cancelPlanInfoAPI(requestBody).subscribe((response) => {
      if (response.status == "success") {
        this.showCalculatableForm = false
        this.showCancelPlanInfo = true
        this.showErrorMsg = false
        this.cancelPlanInfoResponse = response.data
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  cancelPlan(){

    let requestBody = this.validateAndGetRequestBody()
    if(!requestBody){
      return
    }

    this.apiService.cancelPlanAPI(requestBody).subscribe((response) => {
      if (response.status == "success") {
        this.meta.dialogClosed = true
        this.dialogRef.close()
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );

  }

  backToCalculateInfoRequest(){
    this.showCalculatableForm = true
    this.showCancelPlanInfo = false
    this.showErrorMsg = false
    this.cancelPlanInfoRequestForm.reset()
  }

}
