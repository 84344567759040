<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
      <div class="row mt-8 justify-content-between">
      <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
        <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
          LEAVES
        </h1>
      </div>

      <div class="Col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToLeaveCreate">
        <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                (click)="createLeave()">
                <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 txt" style="color: white">Create Leave</span>
            </button>
    </div>
  </div> 

    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-2" *ngIf="isUserAllowedToViewBranches">
          <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
            <mat-select [placeholder]="'All Branches'" [(ngModel)]="IAttendance.branchId"
              (selectionChange)="onFilterAttendance()">
              <mat-option value="">All Branches</mat-option>
              <mat-option *ngFor="let branch of branches_list" [value]="branch.id">{{branch.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-2" *ngIf="isUserAllowedToViewRoles">
          <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
            <mat-select [placeholder]="'All Branches'" [(ngModel)]="IAttendance.roleId"
              (selectionChange)="onFilterAttendance()">
              <mat-option value="">All Roles</mat-option>
              <mat-option *ngFor="let role of roleList" [value]="role.id">{{role.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
          <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
            
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterValueToDisplay" [autocomplete]="'off'" [placeholder]="'Search User Name'">
          </mat-form-field>
        </div>
    
    </div>




    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="UserName">
                <th mat-header-cell *matHeaderCellDef >User Name</th>
                <td mat-cell *matCellDef="let row"> {{row.full_name | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Role">
                <th mat-header-cell *matHeaderCellDef >Role</th>
                <td mat-cell *matCellDef="let row"> {{row.role.name}} </td>
            </ng-container>

            <ng-container matColumnDef="Branch">
                <th mat-header-cell *matHeaderCellDef >Branch</th>
                <td mat-cell *matCellDef="let row"> {{row.branchNames?.join(", ")}} </td>
            </ng-container>

            <ng-container matColumnDef="LastLeave">
                <th mat-header-cell *matHeaderCellDef >Last Activity On</th>
                <td mat-cell *matCellDef="let row"> {{row.last_leave_applied_at  | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                      <button  mat-menu-item (click)="getUserLeaves(row)">View User Leaves</button> 
                    </mat-menu>
                  
                </td>
                  
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <div>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="12">
                <h3 style="display: flex;justify-content: center; margin:10%;">
                  No Records Found
                </h3>
                </td>
              </tr>
            </div>
          </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
  (page)="onPaginateChange($event)" [length]="totalCount"
  [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
  aria-label="Select page of users" showFirstLastButtons></mat-paginator>
    <!-- <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
</div>
