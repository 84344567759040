<div class="container-fluid">
    <div class="row">

        <div class="row mt-8 justify-content-between">
            <div class="col-sm-5 col-md-4 col-lg-2">
                <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                    Referral&nbsp;Percentages
                </h1>
            </div>
            <div class="col-sm-6 col-md-4  d-flex justify-content-end" *ngIf="isUserAllowedToReferralPercentageCreate">
                <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                    (click)="addReferralPercentages()">
                    <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                    <span class="ml-2 mr-1 txt" style="color: white">Create Referral Percentages</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                    <mat-select [placeholder]="'All'" [(ngModel)]="filterOnActiveStatus"
                        (selectionChange)="onPaginateChange($event)">
                        <!-- <mat-option value="">All</mat-option> -->
                        <mat-option *ngFor="let activeType of activeStatusTypes" [value]="activeType.value">{{activeType.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input matInput (keyup)="onPaginateChange($event)" [autocomplete]="'off'"
                        [(ngModel)]="filterOnSearchTerm" [placeholder]="'Search Name'">
                </mat-form-field>
            </div>
        </div>

        <div class="mat-elevation-z8 mt-2 overflow-auto">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.name | titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.description}}</td>
                </ng-container>

                <ng-container matColumnDef="referrerBonus">
                    <th mat-header-cell *matHeaderCellDef>Referrer Bonus (%)</th>
                    <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.referrer_bonus_percentage}} </td>
                </ng-container>

                <ng-container matColumnDef="refereeDiscount">
                    <th mat-header-cell *matHeaderCellDef>Referee Discount (%)</th>
                    <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.referee_discount_percentage}}</td>
                </ng-container>
                
                <ng-container matColumnDef="createdBy">
                    <th mat-header-cell *matHeaderCellDef>Created By</th>
                    <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                        {{row.created_by?.full_name}}
                        <span *ngIf="row.created_by?.role">({{row.created_by.role.name}})</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="createdDate">
                    <th mat-header-cell *matHeaderCellDef>Created Date</th>
                    <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.created_at | dateFormat}}</td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                    <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row ">
                        <button mat-button [matMenuTriggerFor]="menu"
                            [disabled]="!isUserAllowedToReferralPercentageEdit && !isUserAllowedToReferralPercentageDeactivate && !isUserAllowedToReferralPercentageReactivate"
                        >
                            <mat-icon
                                style="color:#0038FF;font-size: 30px;"
                                [disabled]="(isUserAllowedToDeviceEdit && isUserAllowedToDeviceDeactivate && isUserAllowedToDeviceReactivate)"
                                svgIcon="heroicons_outline:dots-vertical">
                            </mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToReferralPercentageEdit"
                                (click)="editReferralPercentages(row)">Edit</button>
                            <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToReferralPercentageDeactivate"
                                (click)="verifyDeactivateReferralPercentages(row.id)">Deactivate</button>
                            <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToReferralPercentageReactivate"
                                (click)="reactivateReferralPercentages(row.id)">Reactivate</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <div>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="12">
                            <h3 style="display: flex;justify-content: center; margin:10%;">
                                No Records Found
                            </h3>
                        </td>
                    </tr>
                </div>
            </table>
        </div>

        <mat-paginator 
            style="display:flex;justify-content: end;" 
            (page)="onPaginateChange($event)"
            [length]="totalCount" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
            aria-label="Select page" showFirstLastButtons
        >
        </mat-paginator>
    </div>