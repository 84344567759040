<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">EDIT&nbsp;RESCHEDULE</h2>
        </div>

        <div class="card dataCard m-1" *ngIf="previousSlotInfo">
            <div class="m-2">
                <h4 class="headings">Existing Slot</h4>
                <div class="row ml-2">
                    <div class=" col-sm-6 col-md-6 col-lg-4">
                        <span class="listFont">Customer:</span>
                        <span style="font-size: 20px; font-weight: 400;" class="">{{previousSlotInfo.user.full_name}}</span>
                    </div>
                    <div class=" col-sm-6 col-md-6 col-lg-4">
                        <span class="listFont">Trainer:</span>
                        <span style="font-size: 20px; font-weight: 400;" class=""> {{previousSlotInfo.trainer.full_name}}</span>
                    </div>
                    <div class=" col-sm-6 col-md-6 col-lg-4">
                        <span class="listFont">Slot Date:</span>
                        <span style="font-size: 20px; font-weight: 400;" class=""> {{previousSlotInfo.slotDate| dateFormat}}</span>
                    </div>
                    <div class=" col-sm-6 col-md-6 col-lg-4">
                        <span class="listFont">Slot Time:</span>
                        <span style="font-size: 20px; font-weight: 400;" class=""> {{previousSlotInfo.slotTime}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="card dataCard m-1" *ngIf="requestedDateTimeSlots">            
            <div class="m-2">
                <h4 class="headings">Requested Date, Time Slots</h4>
                <div class="row m-2" *ngFor="let dateTimeSlot of requestedDateTimeSlots">
                    <div class=" col-sm-6 col-md-6 col-lg-4">
                        <span class="listFont">Date:</span>
                        <span style="font-size: 20px; font-weight: 400;" class="">{{dateTimeSlot.slotDate | dateFormat}}</span>
                    </div>
                    <div class=" col-sm-6 col-md-6 col-lg-8">
                        <span class="listFont">Time Slot:</span>
                        <!-- <span style="font-size: 20px; font-weight: 400;" class=""> {{dateTimeSlot.timeSlots.join(', ')}}</span> --> 
                        <span style="font-size: 20px; font-weight: 400;" class=""> {{dateTimeSlot.timeSlot}}</span> 
                    </div>
                </div>
            </div>
        </div>

        <div class="card"  style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <div class="row  mr-3 ml-3 mt-3">

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Slot Date
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
                                <input matInput readonly [matDatepicker]="picker" 
                                [(ngModel)]="selectedSlotDate" (ngModelChange)="getAvailableTimeSlots()"
                                [placeholder]="'Slot Date'" >
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Slot Time
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="slotTime" style="width: 90%;">
                                <mat-select [placeholder]="'Select Time'" [(ngModel)]="selectedSlotTimeAlias" (ngModelChange)="getAvailableTrainers()">
                                    <mat-option *ngFor="let timeSlot of availableSlotTimes" [value]="timeSlot.alias">{{timeSlot.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Trainer
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="trainerId" style="width: 90%;">
                                <mat-select [placeholder]="'Select Trainer'" [(ngModel)]="selectedTrainerId">
                                    <mat-option *ngFor="let trainer of availableTrainers" [value]="trainer.id">{{trainer.full_name}}&nbsp;({{trainer.role.name}})</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn"  mat-flat-button (click)="reset()">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
           
            <button class="ml-4 mr-6 addBtn" [ngClass]="{'btn-clr': selectedTrainerId}" mat-flat-button [color]="'primary'" (click)="updateReSchedule()" [disabled]="!selectedTrainerId">
                <span class="ml-2 mr-1 txt" style="color: white">Update</span>
            </button>
        </div>
    </div>
</div>