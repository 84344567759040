import { Component,ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViewUserComponent } from 'app/pages/Users/view-user/view-user.component';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator ,PageEvent} from '@angular/material/paginator';
import { ApiService } from 'app/api-services/api.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { IReport, featureConstants } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { PayrollReportComponent } from '../payroll-report/payroll-report.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';

export interface Element {
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved:boolean;
  CreatedBy:string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-YYYY',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-reports',
  templateUrl: './view-payroll-reports.component.html',
  styleUrls: ['./view-payroll-reports.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ViewPayrollReportsComponent{
 
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['userName', 'activeStatus', 'dutyTimePay', 'overTimePay', 'sessionsPay', 'branchHolidaysPay', 'dayOffsPay', 'paidLeavesPay', 'totalPay'];
  dataSource = new MatTableDataSource<Element>();
  sortedData=[];
  branches_list:[]
  IDReport: IReport = {
    branchId: "",
    roleId:"",
    userId:""
  }
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue = '';
  branchIdNameList = []
  selectedBranchIds = []
  roleIdNameList = []
  selectedroleIds = []
  userIdNameList = []
  selectedUserIds = []
  
  payrollDate = null;
  roleList=[]
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToViewRoles = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1
  isUserAllowedToViewPayrollReport = this.userAllowedFeatures.indexOf(featureConstants.ALLOWED_TO_PAYROLL_REPORT) > -1

  constructor( private router: Router,public dialog: MatDialog, public apiService: ApiService,private _liveAnnouncer: LiveAnnouncer, private toastr: ToastrService) {
   
  }
  ngOnInit():void {
    var today = new Date();
    var endOfLastMonth = new Date(today.getFullYear(),today.getMonth(),0)
    this.payrollDate = endOfLastMonth
    this.getRoles();
    this.getBranches();
    this.getUsers();
    this.getPayrollReport()
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.onGetPayrollReport()
  }

  getRoles() {
    let data = {
      "filter_by_role_active_status": true
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleIdNameList = resp.data.roles.map(role => {
          return {
            "id": role.id, "name": role.name
          }
        });
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getBranches() {
    let data = {
      "filter_by_branch_active_status": true
    }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchIdNameList = resp.data.branches.map(branch => {
          return {
            "id": branch.id, "name": branch.name
          }
        })
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getUsers() {
    this.resetCheckedUsers()
    let data = {
      "filter_by_branch_ids": this.selectedBranchIds,
      "filter_by_role_ids": this.selectedroleIds
    }
    this.apiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userIdNameList = resp.data.users.map(user => {
          return {
            "id": user.id, "full_name": user.full_name
          }
        })
      }
    })
  }
  
  // SelecteAll checkbox for Users
  allBranchesChecked = false
  someBranchesChecked() {
    if (this.selectedBranchIds.length == this.branchIdNameList.length) {
      this.allBranchesChecked = true
    } else {
      this.allBranchesChecked = false
    }
    return this.selectedBranchIds.length > 0 && !this.allBranchesChecked;
  }
  setAllBranchesChecked(change) {
    if (change.checked) {
      this.allBranchesChecked = true;
      this.selectedBranchIds = this.branchIdNameList.map(e => e.id);
    } else {
      this.allBranchesChecked = false;
      this.selectedBranchIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Roles
  allRolesChecked = false
  someRolesChecked() {
    if (this.selectedroleIds.length == this.roleIdNameList.length) {
      this.allRolesChecked = true
    } else {
      this.allRolesChecked = false
    }
    return this.selectedroleIds.length > 0 && !this.allRolesChecked;
  }
  setAllRolesChecked(change) {
    if (change.checked) {
      this.allRolesChecked = true;
      this.selectedroleIds = this.roleIdNameList.map(e => e.id);
    } else {
      this.allRolesChecked = false;
      this.selectedroleIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Users
  allUsersChecked = false
  someUsersChecked() {
    if (this.selectedUserIds.length == this.userIdNameList.length) {
      this.allUsersChecked = true
    } else {
      this.allUsersChecked = false
    }
    return this.selectedUserIds.length > 0 && !this.allUsersChecked;
  }
  setAllUsersChecked(change) {
    if (change.checked) {
      this.allUsersChecked = true;
      this.selectedUserIds = this.userIdNameList.map(e => e.id);
    } else {
      this.allUsersChecked = false;
      this.selectedUserIds = [];
    }
  }

  getPayrollReport() {
    let payrollDate = new Date(this.payrollDate)
    let payrollMonth = payrollDate.getMonth() + 1
    let payrollYear = payrollDate.getFullYear()
    let requestBody = {
      payroll_month: payrollMonth,
      payroll_year: payrollYear,
      filter_by_branch_ids: this.selectedBranchIds,
      filter_by_role_ids: this.selectedroleIds,
      filter_by_user_ids: this.selectedUserIds  
    }

    let queryParams = {
      offset: this.bOffset,
      limit: this.bLimit
    }
    if(this.filterValue){
      queryParams['search_term'] = this.filterValue
    }

    this.apiService.getPayrollReport(requestBody,queryParams).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.pay_details
        this.totalCount=response.data.total_count
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }


  // Resetting details
  resetCheckedUsers() {
    this.allUsersChecked = false;
    this.selectedUserIds = []
  }

  resetAll() {
    this.payrollDate = null;
    this.selectedBranchIds=[];
    this.selectedroleIds=[];
    this.selectedUserIds=[];
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  openPayrollReportPopUp() {
    let dialogRef = this.dialog.open(PayrollReportComponent, {
      data: {
        // Data: row,
      },
      width: '50%',
    });
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getPayrollReport(); 
  }

  onGetPayrollReport(){
    this.bOffset=0;
    this.pageIndex=0;
    this.getPayrollReport()
  }

}


