
import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { viewUser } from 'app/meta.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PlanTypesComponent } from '../getPlanTypes/plan-types.component';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

export interface Iuser {
  full_name: string;
  email: string;
  mobile: boolean;

}
@Component({
  selector: 'app-create-plan-types',
  templateUrl: './create-plan-types.component.html',
  styleUrls: ['./create-plan-types.component.scss']
})
export class CreatePlanTypesComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  name = null;
  
  data;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data_: any,
    public apiService: ApiService, private router: Router, private toastr: ToastrService,private _formBuilder: FormBuilder, private dialogRef: MatDialogRef <CreatePlanTypesComponent>
  ) {
    this.data = data_
  }

  plan_type_id : string;
  isUpdate:boolean=false;
  title: string = 'CREATE PLAN TYPE';
  ngOnInit() {
    if(this.data != null){
      this.isUpdate = true;
      this.title = "EDIT PLAN TYPE"
      this.name = this.data.data.name
    }
  }
  
  createPlanType() {
    let payload = {
      name: this.name,
    }
    this.apiService.createPlanType(payload).subscribe((resp) => {
      this.dialogRef.close();
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  UpdatePlanType() {
    let payload = {
      name: this.name,
    }
    this.apiService.updatePlanType(payload,this.data.data.id).subscribe((resp) => {
      this.dialogRef.close();
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  isNameChanged() {
    if (this.name) {
      return this.name != this.data.data.name
    }
  }
}


