import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';


export interface IWalletTransaction {
  id: String;
  // We can add other params later
}

@Component({
  selector: 'app-user-wallets-list',
  templateUrl: './user-wallets-list.component.html',
  styleUrls: ['./user-wallets-list.component.scss']
})
export class UserWalletsListComponent {
  scrHeight: number;
  scrWidth: number;

  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10
  bOffset: number = 0;
  bLimit : number = 10;

  userId = null;
  userFirstName = null;

  displayedColumns = ["Description", "entityType", "transactionValue", "createdAt"]; 
  dataSource = new MatTableDataSource<IWalletTransaction>();


  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    public apiService: ApiService, 
    public dialog: MatDialog, 
    public toastr: ToastrService, 
    public router: Router
  ){
    this.getScreenSize()

    this.userId = sessionStorage.getItem('UserWalletForUserId') || this.userId;
    this.userFirstName = sessionStorage.getItem('UserWalletForUserName') || this.userFirstName;
  }

  back() {
    sessionStorage.removeItem('UserWalletForUserId')
    sessionStorage.removeItem('UserWalletForUserName')
    sessionStorage.removeItem('paginationDataForUserWalletsList')
    this.router.navigateByUrl('Admin/users');
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit(){
    const paginationDataString = sessionStorage.getItem('paginationDataForUserWalletsList');
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
    }
    this.getUserWalletTransactions()
  }

  getUserWalletTransactions() {
    let data = {
      filter_by_user_id: this.userId,
      offset : this.bOffset,
      limit : this.bLimit
    }
    this.apiService.getUserWalletTransactions(data).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.wallet_transactions;
        this.totalCount=response.data.total_transactions_count;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPaginateChange(event: PageEvent): void {
    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;  
  
    this.getUserWalletTransactions(); 
  }

}
