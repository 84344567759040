import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { FileUploadManagerComponent } from 'app/pages/commonComponents/file-upload-manager/file-upload-manager.component';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

@Component({
    selector: 'app-create-ticket',
    templateUrl: './create-ticket.component.html',
    styleUrls: ['./create-ticket.component.scss'],
})
export class CreateTicketComponent {


  @ViewChild('fileUploadComponent', { static: false }) fileUploadComponent: FileUploadManagerComponent;

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

    public basicDetailsForm: FormGroup;
    ticketId: string;
    isUpdate: boolean = false;
    title: string = 'CREATE TICKET';
    tagsList: [];
  offSet = null;
  limit = null;
  
  branchesList = [];
  selected_branch_id = null;
  roleList = [];
  selected_role_id = null;
  userList = [];

    constructor( private _formBuilder: FormBuilder,private router: Router,public apiService: ApiService,private toastr: ToastrService,private route: ActivatedRoute){
        this.buildForm();
    }
    back() {
        this.router.navigateByUrl('Admin/tickets');
    }
  ngOnInit() {
    this.getRoles();
    this.getBranches();
    this.getUsers();  
    this.getTags();
      
    }
    get f() { return this.basicDetailsForm.controls; }
    public buildForm() {
      this.basicDetailsForm = this._formBuilder.group({
        description: new FormControl(null),
        subject: new FormControl(null, [Validators.required]),
        selectedCustomerId: new FormControl(null, [Validators.required]),
        selectedTagId: [null, [Validators.required]],
        entityId: new FormControl(null)
      });

    }
    resetForm(){
      this.buildForm()
      this.fileUploadComponent.reset()
    }
  
      getRoles() {
        let data = { "filter_by_role_active_status": true }
        this.apiService.getRolesByFilter(data).subscribe((resp) => {
          if (resp.status == "success") {
            this.roleList = [({"id": null, "name": "All Roles"})]
            this.roleList.push(...resp.data.roles)
          }
        },
          (err) => {
            this.toastr.error(err.error.message)
          })
      }
      getBranches() {
        let data = { "filter_by_branch_active_status": true }
        this.apiService.getBranchByFilter(data).subscribe((resp) => {
          if (resp.status == "success") {
            this.branchesList = [({"id": null, "name": "All Branches"})]
            this.branchesList.push(...resp.data.branches)
          }
        },
          (err) => {
            this.toastr.error(err.error.message)
          })
      }
  getUsers() {
    this.basicDetailsForm.controls.selectedCustomerId.reset()
        let filter_by_branch_ids = []
        let filter_by_role_ids = []
        if (this.selected_branch_id) {
          filter_by_branch_ids.push(this.selected_branch_id)
        }
        if (this.selected_role_id) {
          filter_by_role_ids.push(this.selected_role_id)
        }
        let data = {
          "filter_by_branch_ids": filter_by_branch_ids,
          "filter_by_role_ids": filter_by_role_ids
        }
        this.apiService.getUserByFilterReport(data).subscribe((resp) => {
          if (resp.status == "success") {
            this.userList = resp.data.users
          }
        })
      }
  
    getTags() {
        // Get the tags
        let data =  {
            filter_by_active_status: "true",
            offset: this.offSet,
            limit: this.limit
            }
        this.apiService.getTagsByFilter(data).subscribe((resp) => {
            if (resp.status == "success") {
            this.tagsList = resp.data.tags // Add the given users to customers_list
            }
            
        })
    }
  createTicket() {
    let requestData = {
            ticket: {
                user_id: this.basicDetailsForm.controls.selectedCustomerId.value,
                subject: this.basicDetailsForm.controls.subject.value,
                description: this.basicDetailsForm.controls.description.value,
                tag_id: this.basicDetailsForm.controls.selectedTagId.value,
                entity_id: this.basicDetailsForm.controls.entityId.value
      }
    }
    
    this.apiService.createTicket(requestData).subscribe((resp) => {
      if (resp.status == "success") {
        // See how to make it out common in both create and update branch
        // Uploading Files
        this.ticketId = resp.data.ticket.id
        if (this.fileUploadComponent.files.length || this.fileUploadComponent.existingFileData) {          
          if (this.fileUploadComponent.files.length) {
            let fileUploadRequest = this.fileUploadComponent.uploadFiles("tickets/" + this.ticketId)
            fileUploadRequest.subscribe((resp) => {
              // Successfully saved all files
              // And Started updating uploaded files in branch files
              let fileUrls = resp["file_urls"]
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateTicketFiles(payload, this.ticketId).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/tickets');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }, (err) => {
              this.toastr.error("Failed to upload Files");
            })
          }
          else {
            // can update only if any files are removed
            if (this.fileUploadComponent.actualexistingFilesCount != this.fileUploadComponent.existingFileData.length) {
              let fileUrls = []
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateTicketFiles(payload, this.ticketId).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/tickets');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }
            else {this.router.navigateByUrl('Admin/tickets');}
          }
        }
        else{this.router.navigateByUrl('Admin/tickets');}
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
    });
}
  
}