<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
            <h1 class="h3 ml-4 mr-2 heading">RESCHEDULE&nbsp;REQUESTS</h1>
            </div>
    </div>


    <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-2">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                    <mat-select [placeholder]="'All'" [(ngModel)]="IReschedule.rescheduleStatus" (selectionChange)="onGetAllRescheduleRequests()">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let status of statusList" [value]="status.value">{{ status.viewValue}}</mat-option>
                    </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
            <mat-form-field
                class="flex-auto gt-xs:pr-3"
                style="width: 90%"
                [subscriptSizing]="'dynamic'"
            >
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:search'"
                ></mat-icon>
                <input
                    matInput
                    (keyup)="applyFilter($event)"
                    [autocomplete]="'off'"
                    [placeholder]="'Search User Name'"
                    [(ngModel)]="filterValueToDisplay"
                />
            </mat-form-field>
        </div>
            
    </div>
    
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="Customer">
                <th mat-header-cell *matHeaderCellDef >Customer</th>
                <td mat-cell *matCellDef="let row"> {{row.customer | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Trainer">
                <th mat-header-cell *matHeaderCellDef >Trainer</th>
                <td mat-cell *matCellDef="let row"> {{row.trainer | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef >Date</th>
                <td mat-cell *matCellDef="let row"> {{row.slotDate | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="Time">
                <th mat-header-cell *matHeaderCellDef >Time</th>
                <td mat-cell *matCellDef="let row"> {{row.slotTime | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef >Status</th>
                <td mat-cell *matCellDef="let row"> {{row.rescheduleStatus | titlecase}} </td>
            </ng-container>
            <ng-container matColumnDef="RequestSentTo">
                <th mat-header-cell *matHeaderCellDef >Request Sent To</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.reschedule_request_sent_to.replaceAll('_', ' ') | titlecase}}
                </td>
            </ng-container>                     
            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef >Created By</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.created_by?.full_name}}<span *ngIf="row.created_by?.role">({{row.created_by?.role.name}})</span> 
                </td>
            </ng-container>
            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef >Created Date</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.created_at | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="updateReschedule(row)">Edit</button>
                    </mat-menu>
                  </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- when there is no matching data it will be visible -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>

    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>
