import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import moment from 'moment';
import { IRescheduleActions} from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reschedules-list',
  templateUrl: './reschedules-list.component.html',
  styleUrls: ['./reschedules-list.component.scss']
})
export class ReschedulesListComponent {

  scrHeight: number;
  scrWidth: number;

  searchTerm = null;
  selectedRescheduleRequestStatus = null;

  dataSource = new MatTableDataSource<Element>();
  searchInputControl: UntypedFormControl = new UntypedFormControl()
  
  displayedColumns = ["Customer", "Trainer", "Date", "Time", "Status", "RequestSentTo", "CreatedBy", "CreatedDate", "Actions"];
 
  IReschedule: IRescheduleActions = {
    rescheduleStatus: "",
  }
  statusList= [
    { value: 'PENDING', viewValue: 'Pending' },
    { value: 'CANCEL', viewValue: 'Cancel' },
    { value: 'ACCEPTED', viewValue: 'Accepted' },
    { value: 'REJECTED', viewValue: 'Rejected' },
    { value: 'ALLOTED_BY_ADMIN', viewValue: 'Alloted_By_Admin' }
  ]
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue= null;
  filterValueToDisplay=""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public apiService: ApiService, public toastr: ToastrService, public router: Router) {
    this.getScreenSize();
  }
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('PaginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.selectedRescheduleRequestStatus=paginationData.filterByRescheduleRequestsAlias;
      this.IReschedule.rescheduleStatus=paginationData.filterByRescheduleBookingStatuses;
      this.filterValue=paginationData.filterValue;
      this.filterValueToDisplay=paginationData.filterValue;
    }
    this.getAllRescheduleRequests()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.onGetAllRescheduleRequestsForSearch()
  }
  getAllRescheduleRequests() {

    let filterData = {
      filter_by_reschedule_requests_alias: this.selectedRescheduleRequestStatus,
      filter_by_reschedule_booking_statuses: this.IReschedule.rescheduleStatus,
      offset:this.bOffset,
      limit:this.bLimit,
      search_term:this.filterValue || null
    }
    this.apiService.getReschedulesRequestsByFilter(filterData).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.reschedule_requests.map(reschedule_data => {
          return {
            "rescheduleSlotBookingId": reschedule_data.id,
            "customer": reschedule_data.previous_slot.user.full_name,
            "trainer": reschedule_data.previous_slot.trainer.full_name,
            "slotDate": moment(new Date(reschedule_data.previous_slot.start_date_time)).format("YYYY-MM-DD").toString(),
            "slotTime": moment(new Date(reschedule_data.previous_slot.start_date_time)).format("hh:mm:ss A").toString(),
            "rescheduleStatus": reschedule_data.booking_status.split('_').join(' '),
            "reschedule_request_sent_to":reschedule_data.reschedule_request_sent_to,
            "created_at": reschedule_data.created_at,
            "created_by": reschedule_data.created_by,
          }
        });
        this.totalCount=response.data.total_count;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    ); 
  }
  updateReschedule(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByRescheduleRequestsAlias: this.selectedRescheduleRequestStatus,
      filterByRescheduleBookingStatuses: this.IReschedule.rescheduleStatus,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForReschedulerequests', JSON.stringify(paginationData));
    sessionStorage.setItem('rescheduleSlotIdForUpdateSchedule', row.rescheduleSlotBookingId);
    this.router.navigate(['Admin/edit-reschedule'])
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getAllRescheduleRequests(); 
  }

  onGetAllRescheduleRequestsForSearch(){
    this.bOffset=0;
    this.pageIndex=0;
   
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByRescheduleRequestsAlias: this.selectedRescheduleRequestStatus,
      filterByRescheduleBookingStatuses: this.IReschedule.rescheduleStatus,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForReschedulerequests', JSON.stringify(paginationData));
    this.getAllRescheduleRequests()
  }

  onGetAllRescheduleRequests(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByRescheduleRequestsAlias: this.selectedRescheduleRequestStatus,
      filterByRescheduleBookingStatuses: this.IReschedule.rescheduleStatus,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForReschedulerequests', JSON.stringify(paginationData));
    this.getAllRescheduleRequests()
  }

}
