import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import { viewUser } from 'app/meta.service';
import moment from 'moment';

@Component({
  selector: 'app-view-add-on-plan',
  templateUrl: './view-add-on-plan.component.html',
  styleUrls: ['./view-add-on-plan.component.scss']
})

export class ViewAddOnPlanComponent {
  AddOnplans = [];
  planBenefits=[];
  planDuration = [];
  suitableForCustomers = null;
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService, private datePipe: DatePipe
  ) { }
  ngOnInit() {
    this.getUserById();
  }
  getUserById() {
    this.AddOnplans = this.data.data;
    this.AddOnplans.forEach(element => {
      this.planBenefits = element.benefits;
      this.planDuration = element.plan_durations;
      this.suitableForCustomers = element.suitable_for_customers;
      element.trainerRoleNames = element.schedule_roles.map(scheduleRole => {
        return scheduleRole.name
      })

    });
  }
}


