import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { freezeAppAlertsAPIUrls } from 'app/api-services/api-urls';
import { ApiService } from 'app/api-services/api.service';
import { ResourceServiceService } from 'app/api-services/resource-service';
import { map, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FreezeAppAlertsApiService extends ApiService{

  constructor(
    public router: Router, 
    public httpClient: HttpClient, 
    public resourceService: ResourceServiceService
  ) { 
    super(router=router, httpClient=httpClient, resourceService=resourceService)
  }

  public getFreezeAppAlertsByFilter(queryData: Object) {
    const base = this.requestGet(freezeAppAlertsAPIUrls.getFreezeAppAlerts, queryData).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public createFreezeAppAlertAPI(payload: Object) {
    const base = this.requestPost(freezeAppAlertsAPIUrls.createFreezeAppAlerts, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

}
