<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
  <div class="row">
    <div class="row mt-8 justify-content-between">
      <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
        <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
        USAGE&nbsp;REPORTS  
      </h1>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end">
        <button class="addBtn m-1" style="width: 170px" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
          (click)="onClickDownload()" *ngIf="isUserAllowedToViewUsageReport">
          <mat-icon class="white-icon" [svgIcon]="'mat_outline:file_download'"></mat-icon>
          <span class="ml-2 mr-1 txt" style="color: white">Usage Report</span>
        </button>
      </div>
    </div>


    <div class="row">
          <div class="col-sm-12 col-md-3 col-lg-2 form-drop-down">
                  <mat-form-field class="flex-auto gt-xs:pr-3 w-full " >
                    <mat-select [placeholder]="'All Branches'"  [(ngModel)]="selectedBranchIds" (ngModelChange)="getUsers(); onGetUsageReport();" multiple>
                      <mat-checkbox class="" [checked]="allBranchesChecked" [indeterminate]="someBranchesChecked()" (change)="setAllBranchesChecked($event); getUsageReport1();">
                        Select All
                      </mat-checkbox>
                      <mat-option *ngFor="let branch of branchIdNameList" [value]="branch.id">{{branch.name | titlecase  }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                
        </div>
      <div class="col-sm-12 col-md-3 col-lg-2 form-drop-down " >
          <mat-form-field class="flex-auto gt-xs:pr-3 w-full" >
            <mat-select [placeholder]="'All Roles'"   [(ngModel)]="selectedroleIds" (ngModelChange)="getUsers(); onGetUsageReport();" multiple>
              <mat-checkbox class="" [checked]="allRolesChecked" [indeterminate]="someRolesChecked()" (change)="setAllRolesChecked($event); getUsageReport1();">
                  Select All
              </mat-checkbox>
              <mat-option *ngFor="let role of roleIdNameList" [value]="role.id">{{role.name | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3 col-lg-2 form-drop-down" >
          <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
            <mat-select [placeholder]="'All Users'" [(ngModel)]="selectedUserIds" (ngModelChange)="onGetUsageReport()" multiple >
              <mat-checkbox class="" [checked]="allUsersChecked" [indeterminate]="someUsersChecked()" (change)="setAllUsersChecked($event); getUsageReport1();">
                  Select All
              </mat-checkbox>
              <mat-option *ngFor="let user of userIdNameList" [value]="user.id">{{user.full_name | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      <!--<div class="col-sm-6 col-md-4 col-lg-2  mt-8">
        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
            <mat-select [placeholder]="'Active Tags'" [(ngModel)]="userActive" (selectionChange)="getUsageReport()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let tag of UserStatusType" [value]="tag.value">{{tag.viewValue}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-2  mt-8">
      <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
          <mat-select [placeholder]="'Active Tags'" [(ngModel)]="selectedGender" (selectionChange)="getUsageReport()">
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let tag of UserStatusType" [value]="tag.value">{{tag.viewValue}}</mat-option>
          </mat-select>
      </mat-form-field>
  </div>
      <div class="col-sm-6 col-md-4 col-lg-2  mt-8">
        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'" [placeholder]="'Search User'">
        </mat-form-field>
      </div>-->
      <div class="col-sm-12 col-md-3 col-lg-2" *ngIf="isUserAllowedToViewBranches">
        <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
            <input matInput readonly [matDatepicker]="picker" [(ngModel)]="fromDate" (ngModelChange)="onGetUsageReport()"
                [placeholder]="'From Date'" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2" *ngIf="isUserAllowedToViewRoles">     
    
        <mat-form-field class="flex-auto gt-xs:pr-3 w-full">
            <input matInput readonly [matDatepicker]="picker1" [(ngModel)]="toDate" [min]="fromDate" [max]="maxDate" (ngModelChange)="onGetUsageReport()"
                [placeholder]="'To Date'" (dateChange)="onGetUsageReport()" >
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8 mt-2 overflow-auto">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef >User Name</th>
        <td mat-cell *matCellDef="let row"> {{row.user | titlecase}} </td>

      </ng-container>
      <ng-container matColumnDef="defaultRole">
        <th mat-header-cell *matHeaderCellDef>Default Role</th>
        <td mat-cell *matCellDef="let row"> {{row.default_role}}</td>
      </ng-container>
      <ng-container matColumnDef="attendanceDate">
        <th mat-header-cell *matHeaderCellDef >Attendance Date</th> 
        <td mat-cell *matCellDef="let row"> {{row.attendance_date}}</td>
      </ng-container>
      <ng-container matColumnDef="dutyTime">
        <th mat-header-cell *matHeaderCellDef >Duty time (Min)</th>
        <td mat-cell *matCellDef="let row"> {{row.duty_time_in_minutes}}</td>
      </ng-container>
      <ng-container matColumnDef="overTime">
        <th mat-header-cell *matHeaderCellDef >Over time (Min)</th>
        <td mat-cell *matCellDef="let row"> {{row.over_time_in_minutes}}</td>
      </ng-container>
      <ng-container matColumnDef="totalActiveTime">
        <th mat-header-cell *matHeaderCellDef >Total Active time (Min)</th>
        <td mat-cell *matCellDef="let row"> {{row.total_active_time_in_minutes}}</td>
      </ng-container>

      <ng-container matColumnDef="activeStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Status</th>
        <td mat-cell *matCellDef="let row"> {{row.active_status}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <!-- Row shown when there is no matching data. -->
      <div>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">
            <h3 style="display: flex;justify-content: center; margin:10%;">
              No Records Found
            </h3>
          </td>
        </tr>
      </div>
    </table>

  </div>
  <!-- <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 10, 25, 100]"
      aria-label="Select page of reports"></mat-paginator> -->

      <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>