import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { IAttendance, featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-active-leave-list',
  templateUrl: './active-leave-list.component.html',
  styleUrls: ['./active-leave-list.component.scss']
})

export class ActiveLeaveListComponent {

  scrHeight: number;
  scrWidth: number;

  searchTerm = "";

  branches_list:[]
  IAttendance: IAttendance = {
    branchId: "",
    roleId:"",
  }
  roleList=[]
  userBranchNames = []

  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue=null;
  filterValueToDisplay=""
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToViewRoles = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1
  isUserAllowedToLeaveCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_LEAVES) > -1
  
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  
  displayedColumns = ["UserName", "Role", "Branch", "LastLeave", "Actions"]; 
  dataSource = new MatTableDataSource<Element>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public apiService: ApiService, public toastr: ToastrService, private router: Router) {
    this.getScreenSize();
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForDevice')
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
   
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue=paginationData.filterValue;
      this.IAttendance.branchId=paginationData.filterByBranchId;
      this.IAttendance.roleId=paginationData.filterByRoleId;
      this.filterValueToDisplay=paginationData.filterValue;
    }
    
    if (this.isUserAllowedToViewRoles) {
      this.getRoles()
    }
    if (this.isUserAllowedToViewBranches) {
      this.getBranches()
    }
    this.filterattendance()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort; 
  }

  getAllLeaves() {

    let filterData = {

    }

    this.apiService.getActiveLeavesListByFilter(filterData).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.leaves;
        this.dataSource.data.forEach((element: any) => {
          var branchNames = [];
          this.userBranchNames = []
          element.branches.forEach(ele => {
            branchNames.push(ele.name)
            this.userBranchNames = branchNames
          });
          element.branchNames = branchNames;
          
        });
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }



  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    // }
    this.onFilterAttendanceForSearch()
  }

  getUserLeaves(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByBranchId: this.IAttendance.branchId,
      filterByRoleId: this.IAttendance.roleId
    };

    sessionStorage.setItem('paginationDataForLeave', JSON.stringify(paginationData)); 
    sessionStorage.setItem('LeavesForUserId', row.id);
    sessionStorage.setItem('UserName', row.full_name);
    const  state = {userId: row}
   
    this.router.navigate(['Admin/user-leaves'], {state})
  }

  createLeave() {
    this.router.navigateByUrl('Admin/create-leave');
  }

  getBranches() {
    let data = {"filter_by_branch_active_status": true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        // this.dataSource.data = resp.data.branches;
        this.branches_list = resp.data.branches
        // this.toastr.success("Branches Loaded successfully");
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  getRoles(){
    const data = {
      filter_by_role_active_status:true,
    }
    this.apiService.getRolesByFilter(data).subscribe((resp)=>{
      this.roleList =resp.data.roles
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  filterattendance() {
  
    
    let data = {
        filter_by_branch_id: this.IAttendance.branchId,
        filter_by_role_id: this.IAttendance.roleId,
        offset:this.bOffset,
        limit:this.bLimit,
        filter_by_search_term:this.filterValue || null
      }

     
   
    this.apiService.getActiveLeavesListByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.leaves;
        this.dataSource.data.forEach((element: any) => {
          var branchNames = [];
          this.userBranchNames = []
          element.branches.forEach(ele => {
            branchNames.push(ele.name)
            this.userBranchNames = branchNames
          });
          element.branchNames = branchNames;
          
        });
        this.totalCount=resp.data.total_leaves_count;
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    });
  }

  


  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.filterattendance(); 
  }

  onFilterAttendanceForSearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByBranchId: this.IAttendance.branchId,
      filterByRoleId: this.IAttendance.roleId
    };
    
    sessionStorage.setItem('paginationDataForLeave', JSON.stringify(paginationData)); 

    this.filterattendance()
  }

  onFilterAttendance(){
    this.pageIndex=0;
    this.bOffset=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue:this.filterValue,
      filterByBranchId: this.IAttendance.branchId,
      filterByRoleId: this.IAttendance.roleId
    };
    
    sessionStorage.setItem('paginationDataForLeave', JSON.stringify(paginationData)); 
    this.filterattendance()
  }
    
}
