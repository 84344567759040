<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>VIEW COUPON</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
        </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography">
            <div class="card dataCard">
                <div class="mt-2 ml-3 ">
                    <h1 class="headings">
                        Details
                    </h1>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Coupon Name:
                            </div>
                            <div class="fw-bold">
                               {{coupon.name}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Coupon Code:
                            </div>
                            <div class="fw-bold">
                               {{coupon.coupon_code}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Coupon Type:
                            </div>
                            <div class="fw-bold">
                                {{coupon.coupon_type.name}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Discount Price:
                            </div>
                            <div class="fw-bold">
                                {{coupon.discount_price}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Discount Percentage:
                            </div>
                            <div class="fw-bold">
                                {{coupon.discount_percentage}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Minimum Purchase Price:
                            </div>
                            <div class="fw-bold">
                                {{coupon.min_purchase_price}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Maximum Usages:
                            </div>
                            <div class="fw-bold">
                                {{coupon.max_usages}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Coupon Usage Count:
                            </div>
                            <div class="fw-bold">
                                {{coupon.coupon_usage_count}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card dataCard mt-4" style="height: auto !important;">
                <div class="mt-2 ml-3 ">
                    <h4 class="headings">
                        Terms And Conditions
                    </h4>
                    <div class="col-sm-12 col-md-6 d-flex flex-column align-items-center m-auto"
                        style="max-height:450px; overflow-y:scroll;">
                        <table class="table" style="width: 90%">
                            <thead class="thead-dark"
                                style="background: #000; color: #fff;  position: sticky; top: 0; z-index: 1;">
                                <tr>
                                    <th scope="col">Terms And Conditions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let termPoint of coupon.terms_and_conditions">
                                    <td>{{ termPoint }}</td>
                                  </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div class="card dataCard mt-4" style="height: auto !important;">
                <div class="mt-2 ml-3 ">
                    <h1 class="headings">
                        Duration
                    </h1>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Start Date:
                            </div>
                            <div class="fw-bold">
                               {{coupon?.valid_from}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                End Date:
                            </div>
                            <div class="fw-bold">
                                {{coupon?.valid_to}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Type of Customers:
                            </div>
                            <div class="fw-bold">
                                {{coupon.applicable_to}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-8 mb-4">
                            <div class="listFont fw-bold">
                                Applicabe To Customers:
                            </div>
                            <div class="fw-bold">
                                {{applicableToUserNames.join(', ')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-dialog-content>
    </div>

</div>
