import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe, DateTimeFormatPipe, TimeFormatPipe } from './customDatePipes';


@NgModule({
  declarations: [
    DateFormatPipe,
    DateTimeFormatPipe,
    TimeFormatPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    DateFormatPipe,
    DateTimeFormatPipe,
    TimeFormatPipe,
  ]
})
export class SharedComponentsModule { 
  static forRoot() {
    return {
      ngModule: SharedComponentsModule,
      providers: []
    };
  }
}
