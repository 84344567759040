<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class=" mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{pageTitle}}</h2>
        </div>

        <div class="card" [formGroup]="basicDetailsForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Referral Percentages Details
                </h4>
                <div class="row mr-3 ml-3">

                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Name
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(100))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="name" style="background: #FFFFFF;" matInput placeholder="Enter Device Name"
                                    formControlName="name" required pattern="^[a-zA-Z][\w\s]*$" maxlength="100"
                                    (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('name').value)">
                                <mat-error *ngIf="basicDetailsForm.get('name').hasError('required')">
                                    Name is required
                                </mat-error>
                                <mat-error *ngIf="basicDetailsForm.get('name').hasError('pattern')">
                                    Name has invalid characters
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Description
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="description" style="background: #FFFFFF;" matInput
                                    placeholder="Enter Description" formControlName="description"
                                    (keypress)="handleStartingSpaceTextInput($event, basicDetailsForm.get('description').value)">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Referrer Bonus (in %)
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="referrer_bonus_percentage" maxlength="10" style="background: #FFFFFF;"
                                    matInput oninput="if(!this.value.match(/^\d*\.?\d{0,7}$/)) this.value='';"
                                    placeholder="Enter Bonus Percentage" formControlName="referrerBonusPercentage">
                                <mat-error *ngIf="basicDetailsForm.get('referrerBonusPercentage').hasError('required')">
                                    Referrer Bonus Percentage is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="fw-bold fieldName">
                            Referee Discount (in %)
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="referee_discount_percentage" maxlength="10" style="background: #FFFFFF;"
                                    matInput oninput="if(!this.value.match(/^\d*\.?\d{0,7}$/)) this.value='';"
                                    placeholder="Enter Discount Percentage" formControlName="refereeDiscountPercentage">
                                <mat-error *ngIf="basicDetailsForm.get('refereeDiscountPercentage').hasError('required')">
                                    Referee Discount Percentage is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="m-2 btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn" *ngIf="!isUpdate" (click)="resetForm()" mat-flat-button>
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>

            <button class="ml-4 addBtn" *ngIf="isUpdate" (click)="back()" mat-flat-button>
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="createReferralPercentages()"
                *ngIf="!isUpdate" [disabled]="!basicDetailsForm.valid" [ngClass]="{'btn-clr': basicDetailsForm.valid}">
                <span class="ml-2 mr-1 txt" style="color: white">Create </span>
            </button>
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="updateReferralPercentages()"
                *ngIf="isUpdate" [disabled]="!basicDetailsForm.valid" [ngClass]="{'btn-clr': basicDetailsForm.valid}">
                <span class="ml-2 mr-1 txt" style="color: white">Update </span>
            </button>
        </div>
    </div>

</div>