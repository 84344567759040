import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';

@Component({
  selector: 'app-view-user-accesses',
  templateUrl: './view-user-accesses.component.html',
  styleUrls: ['./view-user-accesses.component.scss']
})
export class ViewUserAccessesComponent {

  selectedfeaturesList = [];
  heading = [];
  headingArr = [];
  dataArr = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.updateUserAccessesDetails()
  }

  updateUserAccessesDetails(){

    this.selectedfeaturesList = this.data.userAccessesFeatures;

    this.heading = Object.entries(this.selectedfeaturesList);
    this.heading.forEach(element => {
      this.headingArr.push(element[0])
      this.dataArr.push(element[1])
    });

  }

}
