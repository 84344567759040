import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
    AddOnPlans,
    AddOnTypes,
    attendanceApi,
    branchApis,
    Coupons,
    CouponTypes,
    deviceApi,
    fileServerAPIUrls,
    LeavesApi,
    levelApi,
    plans,
    planSubTypes,
    planTypes,
    reschedules,
    roleApis,
    schedules,
    Tags,
    Tickets,
    TimeslotDiscounts,
    UserApis,
    userBranches,
    UserPayables,
    userProfile,
} from './api-services/api-urls';
import { ResourceServiceService } from './api-services/resource-service';
import { user } from './mock-api/common/user/data';
import { environment } from 'environments/environment';
import { getOrCreateDeviceId } from './utils/auth_utils';

@Injectable({
    providedIn: 'root',
})
export class FileServerService {
    access_token: string;

    public apiUrl: string = environment.apiUrl;

    headers = new HttpHeaders({
        'device-id': getOrCreateDeviceId(),
        'device-type': 'WEB_APP',
    });
    constructor(
        public router: Router,
        public httpClient: HttpClient,
        private resourceService: ResourceServiceService
    ) {}

    ngOnInit() {
        this.access_token = 'Bearer' + localStorage.getItem('accessToken');
    }

    //  Error Handeling
    private handleError(errorResponse: HttpErrorResponse) {
        let errorMessage = '';
        if (errorResponse.error instanceof ErrorEvent) {
            errorMessage = `Error: Client Side Error: ${errorResponse.error.message}`;
        } else {
            // errorMessage = `Error: Server side error: ${errorResponse.error.message}`;
            // errorMessage = "Server side error: There is a problem with the server. We are notified & working on it. Please try again later!"
        }

        //         window.alert(errorMessage);
        return throwError(errorResponse);
    }

    // get api
    private requestGet(path: string, httpParams?: any): Observable<any> {
        const url = this.apiUrl + path;
        const filteredValue = {};
        for (let [key, value] of Object.entries(httpParams)) {
            if (value || value === 0) {
                filteredValue[key] = value;
            }
        }
        const base = this.httpClient
            .get(url, {
                headers: this.headers,
                params: filteredValue,
            })
            .pipe(catchError(this.handleError));
        const request = base.pipe(
            map((response) => {
                return response;
            })
        );
        return request;
    }

    // post api
    private requestPost(
        path: any,
        param: any,
        HttpParams: any = false
    ): Observable<any> {
        if (HttpParams) {
            //Adding path params in url - Send httpParams only when path params are present
            path = path(HttpParams);
        }
        const url = this.apiUrl + path;
        const base = this.httpClient
            .post(url, param, {
                headers: this.headers,
            })
            .pipe(catchError(this.handleError));
        const request = base.pipe(
            map((response) => {
                return response;
            })
        );
        return request;
    }

    public getFiles(fileUrls) {
        let payload = { file_urls: fileUrls }
        const base = this.requestPost(fileServerAPIUrls.getFiles, payload).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((data) => {
                return data;
            })
        );
        return request;
    }

    public uploadFiles(files) {
        const base = this.requestPost(
            fileServerAPIUrls.uploadFiles,
            files
        ).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((data) => {
                return data;
            })
        );
        return request;
    }

    public deleteFiles(fileUrls) {

        let payload = {file_urls: fileUrls}

        const base = this.requestPost(fileServerAPIUrls.deleteFiles, payload).pipe(catchError(this.handleError));
        const request = base.pipe(
            map((data) => {
                return data;
            })
        );
        return request;
    }
}
