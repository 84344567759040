<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-sm-12 col-lg-8 mt-8 d-flex flex-column flex-sm-row">
                <h1 class="h3 ml-1 mr-6 heading">{{title}}</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" *ngIf="isUserAllowedToViewBranches">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select [placeholder]="'All Branches'" [(ngModel)]="filterByBranchId" (selectionChange)="onGetPlanWaitlists()">
                    <mat-option value="">All Branches</mat-option>
                    <mat-option *ngFor="let branch of branchesList" [value]="branch.id">{{branch.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select  [(ngModel)]="filterByActiveStatus"  [placeholder]="'Active Status'"(selectionChange)="onGetPlanWaitlists()">
                    <mat-option *ngFor="let status of activeStatuses" [value]="status.value">{{status.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select  [(ngModel)]="filterByNotifyStatus"  [placeholder]="'Notify Status'"(selectionChange)="onGetPlanWaitlists()">
                    <mat-option *ngFor="let notifyStatus of notifyStatuses" [value]="notifyStatus.value">{{notifyStatus.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input
                        matInput
                        (keyup)="applyFilter($event)"
                        [autocomplete]="'off'"
                        [placeholder]="'Search User Name'"
                        [(ngModel)]="filterBySearchTerm"
                    />
                </mat-form-field>
            </div>
        </div>
    
    <div class="mat-elevation-z8 mt-3 overflow-auto">
       
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="user" >
                <th mat-header-cell *matHeaderCellDef>User Name</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.userName}}({{row.userMobileNumber}}) </td>
            </ng-container>
            <ng-container matColumnDef="plan" >
                <th mat-header-cell *matHeaderCellDef>Plan (Duration)</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.planName}} <span *ngIf="row.planDuration">({{row.planDuration}})</span></td>
            </ng-container>
            <ng-container matColumnDef="branch">
                <th mat-header-cell *matHeaderCellDef>Branch</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.branchName}}</td>
            </ng-container>
            <ng-container matColumnDef="timeSlot">
                <th mat-header-cell *matHeaderCellDef>Time Slot</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.timeSlot}}</td>
            </ng-container>
            <ng-container matColumnDef="trainer">
                <th mat-header-cell *matHeaderCellDef>Trainer (Mobile Number)</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.trainerName}} <span *ngIf="row.trainerMobileNumber">({{row.trainerMobileNumber}})</span></td>
            </ng-container>
            <ng-container matColumnDef="trainerCode">
                <th mat-header-cell *matHeaderCellDef>Trainer Code</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.trainerCode}}</td>
            </ng-container>
            <ng-container matColumnDef="notifyStatus">
                <th mat-header-cell *matHeaderCellDef>Notify Status</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.notifyStatus.split('_').join(' ') | titlecase}}</td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef>Created By</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.createdByName}} <span *ngIf="row.createdByRoleName">({{row.createdByRoleName}})</span></td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef>Created At</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.createdAt | dateTimeFormat}}</td>
            </ng-container>
            
            
            <!-- <ng-container matColumnDef="Actions" >
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                  <button mat-button [matMenuTriggerFor]="menu" [disabled]="row.is_removed">
                    <mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToViewSchedules && row?.payment_status === 'SUCCESS'" (click)="getUserPlanSchedules(row)">View Plan Schedules</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToCancelPlans && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="cancelPlanPage(row)">Cancel Plan</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUpdatePlanTrainer && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="getAvailableTrainersToUpdatePlanTrainer(row)">Update Trainer</button>
                  </mat-menu>
                </td>
              </ng-container> -->
              

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>
</div>