import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { ViewAddOnPlanComponent } from 'app/pages/Plans/add-on-plan/view-add-on-plan/view-add-on-plan.component';
import { FileUploadManagerComponent } from 'app/pages/commonComponents/file-upload-manager/file-upload-manager.component';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { environment } from 'environments/environment';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith, Subject } from 'rxjs';

export interface State {
  id: string;
  name: string;
}
export interface cities {
  id: string;
  name: string;
}
@Component({
  selector: 'app-create-branch',
  templateUrl: './create-branch.component.html',
  styleUrls: ['./create-branch.component.scss'],
  providers: [FileUploadManagerComponent]
})
export class CreateBranchComponent {

  @ViewChild('fileUploadComponent', { static: false }) fileUploadComponent: FileUploadManagerComponent;

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  constDaysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' },
  ]
  allDaysList = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  daysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' },

  ]
  dayType = [
    { value: 'WORK_DAY', viewValue: 'Working Day' },
    { value: 'DAY_OFF', viewValue: 'Day Off' },
  ]

  // OnSuccessfulCreateOrUpdate: Subject<boolean> = new Subject<boolean>();

  allComplete: boolean = false

  startTime: string;
  minTime: string;
  endTime: string;
  MyDateString: string;
  selectedDay = [];
  selectedDay_type: string = '';
  days: string[] = [];
  daysType: string;
  timeSlots = [];
  selectedTimeSlots = [];
  selected: string[];
  selectedValue: any;
  searchTxt: any;
  disableAdd: boolean = true;
  public basicDetailsForm: FormGroup;
  public adddressForm: FormGroup;
  filteredStates: Observable<State[]>;
  filteredCities: Observable<cities[]>;
  statesList: State[];
  citiesList: cities[];
  cityCtrl = new FormControl();
  disableTime: boolean = true;
  disableUpdate: boolean = false;
  showErrorMsgToAddTimeSlots: boolean = true;
  errorMsgToAddTimeSlots: string = '';
  branch_id: string;
  isUpdate: boolean = false;
  title: string = "CREATE BRANCH"
  isValidTimings = false;
  sortedTimeSlots = []

  stateFormCtrl = new FormControl(null, [Validators.required]);
  cityFormCtrl = new FormControl({ value: null, disabled: true }, [
    Validators.required,
  ]);
  convertedTime: string;
  daysArray = []
  stateCtrl = new FormControl();
  newDate: any;
  myDate = new Date();
  userTimeZone: string;
  
  constructor(private _formBuilder: FormBuilder, public apiService: ApiService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private datePipe: DatePipe) {
    // this.route.params.subscribe((params) => {
    //   this.branch_id = params['branch_id'];
    // });
    this.branch_id = sessionStorage.getItem('editBranchId')
  }

  async ngOnInit() {
    this.getUserTimeZone()
    if (this.branch_id) {
      this.isUpdate = true;
      this.title = "EDIT BRANCH"
      this.getBranchById()
    }
    this.buildForm();
    this.getState();
    
    this.stateFormCtrl.valueChanges.subscribe((stateId) => {
      this.cityFormCtrl.reset();
      this.cityFormCtrl.disable();
      if (stateId) {
        this.apiService.getCities(stateId).subscribe((resp) => {
          if (resp.status == "success") {
            this.citiesList = resp.data.cities;
          }
        },
          (err) => {
            this.toastr.error(err.error.message)
          })
        this.cityFormCtrl.enable();
      }
    });

  }
  
  getUserTimeZone(): void {
    this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  private filterStates(value: string): State[] {
    const filterValue = value.toLowerCase();

    return this.statesList.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  };
  private filterCities(name: String): cities[] {
    const filterValue = name.toLowerCase();
    return this.citiesList.filter(city => city.name.toLowerCase().indexOf(filterValue) === 0);
  }
  addressId: string = ""
  getBranchById() {
    this.apiService.getBranchById(this.branch_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.basicDetailsForm.controls.branchName.setValue(resp.data.branch.name);
        this.basicDetailsForm.controls.email.setValue(resp.data.branch.email);
        this.basicDetailsForm.controls.mobileNumber.setValue(resp.data.branch.mobile_number);
        this.adddressForm.controls.pincode.setValue(resp.data.branch.address.pincode);
        this.adddressForm.controls.line1.setValue(resp.data.branch.address.line_1);
        this.adddressForm.controls.line2.setValue(resp.data.branch.address.line_2);
        this.stateFormCtrl.setValue(resp.data.branch.address.city.state.id);
        this.cityFormCtrl.setValue(resp.data.branch.address.city.id);
        this.addressId = resp.data.branch.address.id;
        this.selectedTimeSlots = resp.data.branch.timings;
        this.selectedTimeSlots.forEach(element => {
          element.start_time = this.convertToIst(element.start_time);
          element.end_time = this.convertToIst(element.end_time)
        });

        // Filling the days array, so that we won't update it
        this.selectedTimeSlots.forEach((element: any) => {
          if (!this.daysArray.includes(element.day_of_week)) {
            this.daysArray.push(element.day_of_week)
            if (element.day_type == "DAY_OFF") {
              let day_off_day_index = this.daysList.map(e => e.value).indexOf(element.day_of_week);
              if (day_off_day_index > -1) {
                this.daysList.splice(day_off_day_index, 1)
              }
            }
          }
        });
        if (this.daysArray.length == 7) {
          this.isValidTimings = true
        } else {
          this.isValidTimings = false
        }

        const convertTime = timeStr => {
          let zone = "AM"
          if (timeStr != null) {
            timeStr = moment(timeStr).format("HH:mm:ss");
            let [hours, minutes, seconds] = timeStr.split(':');
            if (hours >= '12') {
              zone = "PM"
              if (hours > '12') {
                hours = parseInt(hours, 10) - 12;
              } else {
                hours = parseInt(hours, 10);
              }
            }
            if (hours < '10' && typeof (hours) == 'number') {
              hours = '0' + hours
            }
            if (hours == '00') {
              hours = '12'
            }
           
            return `${hours}:${minutes} ${zone}`;
          } else {
            return null;
          }
        }
        const newdata = resp.data.branch.timings.map((ele) => {
          return (
            {
              "day": this.constDaysList.find(each => each.value == ele.day_of_week).viewValue,
              "dayType": this.dayType.find(each => each.value == ele.day_type).viewValue,
              "startTime": convertTime(ele.start_time),
              "endTime": convertTime(ele.end_time),
            }
          )
        })
        this.timeSlots = newdata;
        this.sortTimeSlots()
        // this.adddressForm.controls.line1.setValue(resp.data.branch.address.pincode);


        this.fileUploadComponent.existingFileData = resp.data.branch.file_name_urls.map((ele) => {
          return {
            fileName: ele.filename,
            fileBaseUrl: ele.file_url,
            fileUrl: environment.apiUrl + ele.file_url
          }
        })
        this.fileUploadComponent.actualexistingFilesCount = this.fileUploadComponent.existingFileData.length
      }
    },
      (err) => {
        this.toastr.error(err.error.message);
      })
  }
  convertToIst(date: any) {
    if (date != null) {
      this.newDate = this.datePipe.transform(this.myDate, 'YYYY-MM-dd');
      date = this.newDate + " " + date;
      // var endTime = moment(new Date(date)).format("hh:mm").toString();
      return date
    } else {
      return null
    }
  }
  getState() {
    this.apiService.getStates().subscribe((resp) => {
      if (resp.status == "success") {
        this.statesList = resp.data.states;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  getCities(event) {
    this.cityCtrl.setValue('');
    this.citiesList = []
    this.apiService.getCities(event).subscribe((resp) => {
      if (resp.status == "success") {
        this.citiesList = resp.data.cities;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  SelectedCity(event) {
    this.adddressForm.controls['cityId'].setValue(event);
  }

  back() {
    sessionStorage.removeItem('editBranchId')
    this.router.navigateByUrl('Admin/branches');
  }

  selectedDays(event) {
    let selectedDayValues = [...event];
    if (selectedDayValues.length >= 7) {
      this.allComplete = true
    } else {
      this.allComplete = false 
    }
    this.selectedDay = []
    this.days = []
    let anyDayOffExists = false
    selectedDayValues.forEach(selectedDayValue => {
      let selectedObj = this.daysList.find(each => each.value === selectedDayValue)
      this.selectedDay.push({ "value": selectedObj.value, "viewValue": selectedObj.viewValue });
      this.days.push(selectedObj.value)
      if (this.timeSlots.length > 0) {
        let TimeSlots = this.timeSlots.find(each => each.day === selectedObj.viewValue)
        if (TimeSlots && TimeSlots.dayType == "Day Off") {
          this.disableAdd = true;
          anyDayOffExists = false;
        } else {
        }
      }
      if (anyDayOffExists) {
        this.showErrorMsgToAddTimeSlots = true;
        this.errorMsgToAddTimeSlots = "You can't add day off twice to the same day"
        setTimeout(() => {
          this.resetTimeSlots()
        }, 1000);
      }
    });
  }

  selectedDayType(event) {
    this.disableTime = true;
    this.disableAdd = true;
    this.showErrorMsgToAddTimeSlots = false;
    let selectedTypeObj = this.dayType.find(each => each.value === event);
    this.selectedDay_type = selectedTypeObj.viewValue;
    this.daysType = selectedTypeObj.value;
    let isNoteToAddWithoutTimings = false;

    this.selectedDay.forEach(selectedDay => {
      let existingTimeSlotForGivenDay = this.timeSlots.find(each => each.day === selectedDay.viewValue)
      if (existingTimeSlotForGivenDay) {
        // console.log("I am in existing slot")
        if (this.selectedDay_type == 'Day Off' && existingTimeSlotForGivenDay.dayType == 'Working Day') {
          this.showErrorMsgToAddTimeSlots = true;
          this.errorMsgToAddTimeSlots = "You can't add Day Off and Working Day for same day"
        } else if (this.selectedDay_type == 'Day Off' && existingTimeSlotForGivenDay.dayType == 'Day Off') {
          this.showErrorMsgToAddTimeSlots = true;
          this.errorMsgToAddTimeSlots = "You can't add Day Off twice for same day "
        } else if (this.selectedDay_type == 'Working Day' && existingTimeSlotForGivenDay.dayType == 'Day Off') {
          this.showErrorMsgToAddTimeSlots = true;
          this.errorMsgToAddTimeSlots = "You cant add Working Day and Day Off for same day"
        }
        else {
          this.disableTime = false;
          this.disableAdd = false;
        }
        if (this.showErrorMsgToAddTimeSlots) {
          setTimeout(() => {
            this.resetTimeSlots()
          }, 1000);
        }
      } else if (this.selectedDay_type == 'Day Off') {
        this.disableTime = true;
        this.disableAdd = false;
        isNoteToAddWithoutTimings = true;
      } else {
        this.disableTime = false;
        this.disableAdd = true;
      }
    })
    if (this.showErrorMsgToAddTimeSlots) {
      this.disableTime = true;
      this.disableAdd = true;
    }
    if (isNoteToAddWithoutTimings && !this.showErrorMsgToAddTimeSlots) {
      this.showErrorMsgToAddTimeSlots = true;
      this.errorMsgToAddTimeSlots = "Please continue to add without timings"
    }
  }   

  deleteDay(slot) {
    let deletableTimeslot = slot;
    let i = this.timeSlots.findIndex(timeSlot => JSON.stringify(timeSlot) == JSON.stringify(slot))
    this.timeSlots.splice(i, 1);
    this.selectedTimeSlots.splice(i, 1)
    if (deletableTimeslot.dayType == 'Day Off') {
      this.constDaysList.forEach(e => {
        if (e.viewValue == deletableTimeslot.day) {
          this.daysList.push(e)
        }
      })
      // Sorting the days list
      let daysListInOrder = []
      this.constDaysList.forEach(e => {
        if (!daysListInOrder.includes(e.value)) {
          daysListInOrder.push(e.value)
        }
      })
      this.daysList = this.daysList.sort(
        (a, b) =>
          (daysListInOrder.indexOf(a.value) < daysListInOrder.indexOf(b.value)) ? -1 : (daysListInOrder.indexOf(a.value) > daysListInOrder.indexOf(b.value)) ? 1 : 0)
    }
    this.sortTimeSlots()
    
    this.daysArray = []
    this.selectedTimeSlots.forEach((element: any) => {
      if (!this.daysArray.includes(element.day_of_week)) {
        this.daysArray.push(element.day_of_week)
      }
    });
    
    this.isValidTimings = false
    if (this.daysArray.length == 7) {
      this.isValidTimings = true
    }
    this.someComplete()
  }
  utcStartTime: string;
  utcEndTime: string;
  verifyTimeSlots() {
    this.disableAdd = false;
  }
  addTimeSlots() {
    // this.utcStartTime = new Date(this.startTime).toUTCString();
    // this.utcEndTime = new Date(this.endTime).toUTCString()
    // var utcStart = moment(new Date(this.utcStartTime)).format("hh:mm A").toString();
    // this.convertUTCToTime(this.utcStartTime)
    // console.log(utcStart)
    this.showErrorMsgToAddTimeSlots = false;
    if (this.selectedDay.length <= 0 && this.selectedDay_type) {
      this.showErrorMsgToAddTimeSlots = true;
      this.errorMsgToAddTimeSlots = 'Please select a day before adding';
      setTimeout(() => {
        this.resetTimeSlots()
      }, 1000);
      return
    }
    if (this.endTime && this.startTime != "") {
      var endTime = moment(new Date(this.endTime)).format("hh:mm A").toString();
      var strTime = moment(new Date(this.startTime)).format("hh:mm A").toString();
      var enTime = moment(new Date(this.endTime)).format("YYYY-MM-DD HH:mm:ss").toString();
      var stTime = moment(new Date(this.startTime)).format("YYYY-MM-DD HH:mm:ss").toString();
    } else if (this.selectedDay_type == 'Working Day') {
      this.showErrorMsgToAddTimeSlots = true;
      this.errorMsgToAddTimeSlots = 'Please select valid Start Time and End Time before adding';
      setTimeout(() => {
        this.resetTimeSlots()
      }, 1000);
      return
    }

    if (enTime == "Invalid date" || stTime == "Invalid date") {
      this.endTime = null
      this.startTime = null
    } else {
      this.endTime;
      this.startTime;
    }

    let areTimeslotsAddable = true;
    let tempTimeSlots = []
    let tempSelectedTimeSlots = [];
    let overlappingTimeDays = []

    this.selectedDay.forEach(selectedDay => {

      if (this.daysType == "DAY_OFF") {
        let object = {
          day: selectedDay.viewValue,
          dayType: this.selectedDay_type,
          startTime: null,
          endTime: null,
        }
        tempTimeSlots.push(object);
        let obj = {
          day_of_week: selectedDay.value,
          day_type: this.daysType,
          start_time: null,
          end_time: null,
        }
        tempSelectedTimeSlots.push(obj);
        // setTimeout(() => {
        //   this.resetTimeSlots()
        // }, 1000);
      } else {
        if ((this.endTime != null || undefined) && (this.startTime != null || undefined)) {
          let object = {
            day: selectedDay.viewValue,
            dayType: this.selectedDay_type,
            startTime: strTime,
            endTime: endTime,
          }
          tempTimeSlots.push(object);
          let obj = {
            day_of_week: selectedDay.value,
            day_type: this.daysType,
            start_time: stTime,
            end_time: enTime,
          }
          tempSelectedTimeSlots.push(obj);
          // Checking for the existence of overlapping timings
          this.selectedTimeSlots.forEach(e => {
            let e_start_time = new Date(e.start_time)
            let e_end_time = new Date(e.end_time)
            let obj_start_time = new Date(obj.start_time)
            let obj_end_time = new Date(obj.end_time)

            if (e.day_of_week == selectedDay.value) {
              // console.log(e.day_of_week, selectedDay.value)
              // console.log(e_start_time, e_end_time, typeof(e_start_time), typeof(e_end_time))
              // console.log(obj_start_time, obj_end_time, typeof(obj_start_time), typeof(obj_end_time))
              // console.log(e.day_of_week == selectedDay.value)
              // console.log(e_start_time <= obj_start_time && obj_start_time <= e_end_time)
              // console.log(e_start_time <= obj_end_time && obj_end_time <= e_end_time)
              // console.log(e_start_time <= obj_start_time && obj_end_time <= e_end_time)
              // console.log(e.start_time <= obj.start_time && obj.end_time <= e.end_time)
            }

            if (
              e.day_of_week == selectedDay.value && (
                e_start_time <= obj_start_time && obj_start_time <= e_end_time ||
                e_start_time <= obj_end_time && obj_end_time <= e_end_time ||
                (
                  obj_start_time <= e_start_time && e_end_time <= obj_end_time
                ))
            ) {
              areTimeslotsAddable = false
              if (overlappingTimeDays.indexOf(selectedDay.viewValue) == -1) {
                overlappingTimeDays.push(selectedDay.viewValue)
              }
            }
          })
        }
      }
    });

    if (areTimeslotsAddable) {
      tempTimeSlots.forEach(dayTimeObj => {
        if (dayTimeObj) {
          this.timeSlots.push(dayTimeObj)
        }
      })
      tempSelectedTimeSlots.forEach(dayTimeObj => {
        if (dayTimeObj) {
          this.selectedTimeSlots.push(dayTimeObj)
        }
      })
      this.sortTimeSlots()

      this.selectedTimeSlots.forEach((element: any) => {
        if (this.daysArray.includes(element.day_of_week)) {
        } else {
          this.daysArray.push(element.day_of_week)
          if (element.day_type == "DAY_OFF") {
            let day_off_day_index = this.daysList.map(e => e.value).indexOf(element.day_of_week);
            if (day_off_day_index > -1) {
              this.daysList.splice(day_off_day_index, 1)
            }
          }
        }
      });
      if (this.daysArray.length == 7) {
        this.isValidTimings = true
      } else {
        this.isValidTimings = false
      }
      this.resetTimeSlots()
    } else {
      this.showErrorMsgToAddTimeSlots = true
      this.errorMsgToAddTimeSlots = "Overlapping times given for " + overlappingTimeDays.join(', ')
      setTimeout(() => {
        this.resetTimeSlots()
      }, 1000);
    }
  }
  
  sortTimeSlots() {
    let daysListInOrder = []
    this.constDaysList.forEach(elem => {
      if (!daysListInOrder.includes(elem.viewValue)) {
        daysListInOrder.push(elem.viewValue)
      }
    })
    this.sortedTimeSlots = structuredClone(this.timeSlots)
    this.sortedTimeSlots = this.sortedTimeSlots.sort(
      (a, b) => (daysListInOrder.indexOf(a.day) < daysListInOrder.indexOf(b.day)) ? -1 : (daysListInOrder.indexOf(a.day) > daysListInOrder.indexOf(b.day)) ? 1 : 0
    ).sort(
      (a, b) => {
        if (((daysListInOrder.indexOf(a.day) < daysListInOrder.indexOf(b.day)) ? -1 : (daysListInOrder.indexOf(a.day) > daysListInOrder.indexOf(b.day)) ? 1 : 0) == 0 && a.dayType == 'Working Day') {
          var nowDate = new Date().toISOString().split('T')[0];
          let aZone = a.startTime.slice(-3,)
          let bZone = b.startTime.slice(-3,)
          
          var astartTime = new Date(nowDate + ' ' + a.startTime.slice(0, -2));
          var bstartTime = new Date(nowDate + ' ' + b.startTime.slice(0, -2))

          if (aZone == bZone) {
            return (astartTime < bstartTime) ? -1 : (astartTime > bstartTime) ? 1 : 0
          } else if (aZone == 'PM' && bZone == 'AM') {
            return 1
          } else if (aZone == 'AM' && bZone == 'PM') {
            return -1
          }
        }
      }
    )
  }
  convertUTCToTime(date: any) {
    const utcDate = new Date(date);
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();

    const convertedHours = hours < 10 ? '0' + hours : hours;
    const convertedMinutes = minutes < 10 ? '0' + minutes : minutes;

    this.convertedTime = `${convertedHours}:${convertedMinutes}`;
    return this.convertedTime;
  }
  resetForm() {
    this.buildForm();
    this.stateFormCtrl.reset()
    this.cityFormCtrl.reset()
    this.selectedTimeSlots = []
    this.timeSlots = []
    this.resetTimeSlots()
    this.daysArray = []
    this.sortedTimeSlots = []
    this.daysList = structuredClone(this.constDaysList)
    this.someComplete()
    this.fileUploadComponent.reset()
  }
  allowNumkeyPress(event: any) {
    const pattern = /[0-9\+\-]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  resetTimeSlots() {
    this.selectedDay = [];
    this.startTime = "";
    this.endTime = "";
    this.days = [];
    this.selectedDay_type = "";
    this.daysType = ""
    this.showErrorMsgToAddTimeSlots = false;
    this.disableAdd = true;
    this.disableTime = true;
    // this.disableAdd =false;

  }

  public buildForm() {
    this.basicDetailsForm = this._formBuilder.group({
      email: new FormControl(null, [Validators.pattern("^[A-Za-z0-9._%+-]+@[a-z.-]+\\.[a-z]{2,4}$"),Validators.maxLength(100)]),
      branchName: new FormControl('', [Validators.required,
      Validators.pattern("^[a-zA-Z][\\w\\s-]*$"),Validators.maxLength(300)
      ]),
      mobileNumber: new FormControl('', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]),
    });
    this.adddressForm = this._formBuilder.group({
      pincode: new FormControl('', [Validators.required]),
      state: this.stateFormCtrl,
      cityId: this.cityFormCtrl,
      // country: new FormControl(''),
      line1: new FormControl('', [Validators.required,Validators.maxLength(300)],),
      line2: new FormControl('',[Validators.maxLength(300)]),
    })
  }

  createBranch() {
    const payload: any = {}
    const emailControl = this.basicDetailsForm.controls.email;
    const emailValue = emailControl.valid ? emailControl.value : '';
    if (emailValue !== '') {
      payload['branch'] = {
        name: this.basicDetailsForm.controls.branchName.value,
        email: emailValue,
        mobile_number: this.basicDetailsForm.controls.mobileNumber.value,
        timezone_region: this.userTimeZone,
      };
    } else {
      payload['branch'] = {
        name: this.basicDetailsForm.controls.branchName.value,
        mobile_number: this.basicDetailsForm.controls.mobileNumber.value,
        timezone_region: this.userTimeZone,
      };
    }
    
    /* payload['branch'] = {
       name: this.basicDetailsForm.controls.branchName.value,
       email: this.basicDetailsForm.controls.email?.value,
       mobile_number: this.basicDetailsForm.controls.mobileNumber.value,
       timezone_region:this.userTimeZone,
     }*/
    
    payload.branch['address'] = {
      line_1: this.adddressForm.controls.line1.value,
      line_2: this.adddressForm.controls.line2.value,
      pincode: this.adddressForm.controls.pincode.value,
      city_id: this.adddressForm.controls.cityId.value,
    }
    payload.branch['availability_timings'] = this.selectedTimeSlots
    // We need to add holidays in UI at later point
    payload.branch['holidays'] = []

    this.apiService.createBranch(payload).subscribe((resp) => {
      if (resp.status == "success") {

        // See how to make it out common in both create and update branch
        // Uploading Files
        this.branch_id = resp.data.branch.id
        if (this.fileUploadComponent.files.length || this.fileUploadComponent.existingFileData) {          
          if (this.fileUploadComponent.files.length) {

            let fileUploadRequest = this.fileUploadComponent.uploadFiles("branches/" + this.branch_id)
            fileUploadRequest.subscribe((resp) => {
              // Successfully saved all files
  
              // Started updating uploaded files in branch files
              let fileUrls = resp["file_urls"]
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateBranchFiles(payload, this.branch_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/branches');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }, (err) => {
              this.toastr.error("Failed to upload Files");
            })
          }
          else {

            // can update only if any files are removed

            if (this.fileUploadComponent.actualexistingFilesCount != this.fileUploadComponent.existingFileData.length) {
              let fileUrls = []
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateBranchFiles(payload, this.branch_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/branches');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }
            else {this.router.navigateByUrl('Admin/branches');}
          }
        }
        else{this.router.navigateByUrl('Admin/branches');}
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
  
  updateBranch() {

    // this.OnSuccessfulCreateOrUpdate.next(true)
    const payload: any = {

    }
    const emailControl = this.basicDetailsForm.controls.email;
    const emailValue = emailControl.valid ? emailControl.value : '';
    if (emailValue !== '') {
      payload['branch'] = {
        name: this.basicDetailsForm.controls.branchName.value,
        email: emailValue,
        mobile_number: this.basicDetailsForm.controls.mobileNumber.value,
        timezone_region: this.userTimeZone,
      };
    } else {
      payload['branch'] = {
        name: this.basicDetailsForm.controls.branchName.value,
        mobile_number: this.basicDetailsForm.controls.mobileNumber.value,
        timezone_region: this.userTimeZone,
      };
    }
    /* payload['branch'] = {
       name: this.basicDetailsForm.controls.branchName.value,
       email: this.basicDetailsForm.controls.email.value,
       mobile_number: this.basicDetailsForm.controls.mobileNumber.value,
       timezone_region:this.userTimeZone,
 
     }*/
    payload.branch['address'] = {
      id: this.addressId,
      line_1: this.adddressForm.controls.line1.value,
      line_2: this.adddressForm.controls.line2.value,
      pincode: this.adddressForm.controls.pincode.value,
      city_id: this.adddressForm.controls.cityId.value,
    }
    payload.branch['timings'] = this.selectedTimeSlots
    // We need to add holidays in UI at later point
    payload.branch['holidays'] = []
    this.apiService.updateBranch(payload, this.branch_id).subscribe((resp) => {
      if (resp.status == "success") {
        // See how to make it out common in both create and update branch
        // Uploading Files
        if (this.fileUploadComponent.files.length || this.fileUploadComponent.existingFileData) {          
          if (this.fileUploadComponent.files.length) {
            let fileUploadRequest = this.fileUploadComponent.uploadFiles("branches/" + this.branch_id)
            fileUploadRequest.subscribe((resp) => {
              // Successfully saved all files
              // And Started updating uploaded files in branch files
              let fileUrls = resp["file_urls"]
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateBranchFiles(payload, this.branch_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/branches');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }, (err) => {
              this.toastr.error("Failed to upload Files");
            })
          }
          else {
            // can update only if any files are removed
            if (this.fileUploadComponent.actualexistingFilesCount != this.fileUploadComponent.existingFileData.length) {
              let fileUrls = []
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateBranchFiles(payload, this.branch_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/branches');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }
            else {this.router.navigateByUrl('Admin/branches');}
          }
        }
        else{this.router.navigateByUrl('Admin/branches');}
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  isCreateOrUpdatebranchValid() {
    return !this.basicDetailsForm.valid || !this.adddressForm.valid || !this.isValidTimings
  }

  someComplete(): boolean {
    if (this.days.length == this.daysList.length) {
      this.allComplete = true
    } else {
      this.allComplete = false
    }

    return this.days.length > 0 && !this.allComplete;
  }

  setAll(change) {
    if (change.checked) {
      this.allComplete = true;
      this.days = this.daysList.map(e => e.value);
    } else {
      this.allComplete = false;
      this.days = [];
    }
    this.selectedDays(this.days)
  }

}
