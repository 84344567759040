<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-lg-8 mt-8 d-flex flex-column flex-sm-row">
           
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{userFirstName}}</h2>
        </div>
    </div>
    
    <div class="mat-elevation-z8 mt-8 overflow-auto">
       
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="Description">
                <th style="width:60%" mat-header-cell *matHeaderCellDef mat-sort-header>Transaction</th>
                <td style="width:60%" mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> 
                    <table>
                        <ng-container *ngFor="let descriptionPoint of row.description_points; let i=index" >
                            <tr><td><span><b>{{i+1}}.</b></span>&nbsp;{{ descriptionPoint }}</td></tr>
                        </ng-container>
                    </table>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="entityType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.entity_type?.split('_').join(' ') | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="transactionValue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Value</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.transaction_value}}</td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row?.created_at | dateTimeFormat}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>