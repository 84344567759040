import { Component } from '@angular/core';

@Component({
  selector: 'app-view-freeze-app-alert',
  templateUrl: './view-freeze-app-alert.component.html',
  styleUrls: ['./view-freeze-app-alert.component.scss']
})
export class ViewFreezeAppAlertComponent {

}
