import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ApiService } from 'app/api-services/api.service';
import { MY_FORMATS } from 'app/app.component';
import { ExcelDownloadService } from 'app/excel-download.service';
import { MetaService } from 'app/meta.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-usage-report',
  templateUrl: './usage-report.component.html',
  styleUrls: ['./usage-report.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class UsageReportComponent {

  branchIdNameList = []
  selectedBranchIds = []
  roleIdNameList = []
  selectedroleIds = []
  userIdNameList = []
  selectedUserIds = []
  attendanceData;
  

  fromDate = null;
  toDate = null;
  maxDate = new Date();;
  constructor(public apiService: ApiService, private toastr: ToastrService, private excelDownloadService: ExcelDownloadService, public _formBuilder: FormBuilder, public meta: MetaService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.getRoles();
    this.getBranches();
    this.getUsers();
  }

  getRoles() {
    let data = {
      "filter_by_role_active_status": true
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleIdNameList = resp.data.roles.map(role => {
          return {
            "id": role.id, "name": role.name
          }
        });
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getBranches() {
    let data = {
      "filter_by_branch_active_status": true
    }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchIdNameList = resp.data.branches.map(branch => {
          return {
            "id": branch.id, "name": branch.name
          }
        })
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getUsers() {
    this.resetCheckedUsers()
    let data = {
      "filter_by_branch_ids": this.selectedBranchIds,
      "filter_by_role_ids": this.selectedroleIds
    }
    this.apiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userIdNameList = resp.data.users.map(user => {
          return {
            "id": user.id, "full_name": user.full_name
          }
        })
      }
    })
  }
  
  // SelecteAll checkbox for Users
  allBranchesChecked = false
  someBranchesChecked() {
    if (this.selectedBranchIds.length == this.branchIdNameList.length) {
      this.allBranchesChecked = true
    } else {
      this.allBranchesChecked = false
    }
    return this.selectedBranchIds.length > 0 && !this.allBranchesChecked;
  }
  setAllBranchesChecked(change) {
    if (change.checked) {
      this.allBranchesChecked = true;
      this.selectedBranchIds = this.branchIdNameList.map(e => e.id);
    } else {
      this.allBranchesChecked = false;
      this.selectedBranchIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Roles
  allRolesChecked = false
  someRolesChecked() {
    if (this.selectedroleIds.length == this.roleIdNameList.length) {
      this.allRolesChecked = true
    } else {
      this.allRolesChecked = false
    }
    return this.selectedroleIds.length > 0 && !this.allRolesChecked;
  }
  setAllRolesChecked(change) {
    if (change.checked) {
      this.allRolesChecked = true;
      this.selectedroleIds = this.roleIdNameList.map(e => e.id);
    } else {
      this.allRolesChecked = false;
      this.selectedroleIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Users
  allUsersChecked = false
  someUsersChecked() {
    if (this.selectedUserIds.length == this.userIdNameList.length) {
      this.allUsersChecked = true
    } else {
      this.allUsersChecked = false
    }
    return this.selectedUserIds.length > 0 && !this.allUsersChecked;
  }
  setAllUsersChecked(change) {
    if (change.checked) {
      this.allUsersChecked = true;
      this.selectedUserIds = this.userIdNameList.map(e => e.id);
    } else {
      this.allUsersChecked = false;
      this.selectedUserIds = [];
    }
  }

  getUsageReport() {
    let fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString()
    let toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString()
    let requestBody = {
      from_date: fromDate,
      to_date: toDate,
      filter_by_branch_ids: this.selectedBranchIds,
      filter_by_role_ids: this.selectedroleIds,
      filter_by_user_ids: this.selectedUserIds
    }

    this.apiService.getUsageReport(requestBody, {}).subscribe((response) => {
      if (response.status == "success") {
        this.attendanceData = response.data.attendance_reports
        let fileName = "AttendanceReports_" + fromDate + "/" + toDate + '.' + 'xlsx'
        let headerNames = ["User", 'User Active Status', 'Branch', 'Default Role', 'Role', "Gender", "Duty Time in Minutes", "Over Time in Minutes", "Attendance Date", "Total Active Time in Minutes"]
        this.excelDownloadService.downloadExcelFile(this.attendanceData, headerNames, fileName);
        this.meta.dialogClosed = false;
        this.resetAll()
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  // Resetting details
  resetCheckedUsers() {
    this.allUsersChecked = false;
    this.selectedUserIds = []
  }

  resetAll() {
    this.fromDate = null;
    this.toDate = null;
    this.selectedBranchIds=[];
    this.selectedroleIds=[];
    this.selectedUserIds=[];
  }

}
