import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService, roles, featureConstants } from 'app/meta.service';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';
import { OtpConformationComponent } from '../otp-conformation/otp-conformation.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { getuserAllowedFeatures, handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { VERSION } from 'lodash';
import { FileUploadManagerComponent } from 'app/pages/commonComponents/file-upload-manager/file-upload-manager.component';
import { environment } from 'environments/environment';


const moment = _moment;
export interface State {
  id: string;
  name: string;
}
export interface cities {
  id: string;
  name: string;
}

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  providers: [FileUploadManagerComponent]
})

export class CreateUserComponent {
  @ViewChild('fileUploadComponent', { static: false }) fileUploadComponent: FileUploadManagerComponent;

  handleStartingSpaceTextInput = handleStartingSpaceTextInput
  
  constDaysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' },
  ]
  allDaysList = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI','SAT']
  daysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' },
  ]
  dayOffSelecteddayValuesList: [] = []
  dayType = [
    { value: 'WORK_DAY', viewValue: 'Working Day' },
    { value: 'DAY_OFF', viewValue: 'Day Off' },
  ]
  allComplete: boolean = false
  items = [{
    value: 100,
    viewValue: 100
  }, {
    value: 200,
    viewValue: 200
  }, {
    value: 300,
    viewValue: 300
  }];

  roleList = [];
  branchesList: [];
  startTime: string;
  endTime: string;
  MyDateString = null;
  MyJoiningDateString = null;
  selectedDay = [];
  selectedDay_type: string;
  days:string[] = [];
  daysType: string;
  timeSlots = [];
  selectedTimeSlots = [];
  selected: string[];
  selectedValue: any;
  searchTxt: any;
  daysArray = [];
  timeMsg: boolean;
  sortedTimeSlots = []
  casualLeavesCount;
  sickLeavesCount;
  userLeaveConfig;

  disableAdd: boolean = true;
  showMsg: boolean = false
  disableTime: boolean = true;
  isValidTimings: boolean = false;
  should_add_address: boolean = false;
  should_add_availability_timings: boolean = false;
  should_add_branch_at_user_creation: boolean = false;
  can_add_multiple_branches: boolean = false;
  public basicDetailsForm: FormGroup;
  public userAddressForm: FormGroup;
  filteredStates: Observable<State[]>;
  filteredCities: Observable<cities[]>;
  statesList: State[];
  citiesList: cities[];
  cityCtrl = new FormControl();
  stateCtrl = new FormControl();
  branch_list = []
  maxDate = new Date();
  showErrorMsgToAddTimeSlots: boolean = false;
  errorMsgToAddTimeSlots: string = '';
  user_id: string;
  addressId:string;
  mobileNumber:string="";
  email:string="";
  selected_branch_id = null;
  stateFormCtrl = new FormControl(null, [Validators.required]);
  cityFormCtrl = new FormControl({ value: null, disabled: true }, [
    Validators.required,
  ]);
  isUpdate: boolean = false;
  title: string = "CREATE USER";
  showloading:boolean =false;
  convertedTime:string;
  newDate: any;
  myDate = new Date();
  selectedDate:any;
  userTimeZone: string;
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToViewRoles = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1
  selected_report_to_user_ids = []
  report_to_users = []
  filtered_report_to_users = []
  role_id_name_aliases = []
  constructor(private router: Router, private meta: MetaService, public dialog: MatDialog, private route: ActivatedRoute, public apiService: ApiService, public _authService: AuthService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private toastr: ToastrService,) {
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map((state: any) => state ? this.filterStates(state) : this.statesList.slice())
      );
    // this.route.params.subscribe((params) => {
    //   this.user_id = params['user_id'];
    // });

    this.user_id = sessionStorage.getItem('editUserId')

  }
  ngOnInit() {
    this.showloading = false
    this.getRoles();
    this.getBranches();
    this.buildForm();
    // this.getRoles();
    this.getUserTimeZone()
    this.getState();

    if (this.user_id) {
      this.getUserByID();
     
      this.isUpdate = true;
      this.title = "EDIT USER"
      // this.apiService.getRoleById(this.role_id).subscribe((resp)=>{
      // })
    } else {
      // this.filterRole();
      // this.getFeatures();
    }
   
    this.stateFormCtrl.valueChanges.subscribe((stateId) => {
      this.cityFormCtrl.reset();
      this.cityFormCtrl.disable();
      if (stateId) {
        this.apiService.getCities(stateId).subscribe((resp) => {
          if (resp.status == "success") {
            this.citiesList = resp.data.cities;
          }
        },
        (err) => {
          this.toastr.error(err.error.message)
        })
        this.cityFormCtrl.enable();
      }
    });

  }
  getUserTimeZone(): void {
    this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  private filterStates(value: string): State[] {
    const filterValue = value.toLowerCase();

    return this.statesList.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  };
  private filterCities(name: String): cities[] {
    const filterValue = name.toLowerCase();
    return this.citiesList.filter(city => city.name.toLowerCase().indexOf(filterValue) === 0);
  }
  userDetails:[]
  getUserByID() {
    this.apiService.getUserById(this.user_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.userDetails = resp.data.user
        this.selected_report_to_user_ids = []
        resp.data.user.report_to_users.forEach(userObj => {
          this.selected_report_to_user_ids.push(userObj.id)
        })
        this.selectRole(resp.data.user.role.id);
        this.showloading = true;

        this.fileUploadComponent.existingFileData = resp.data.user.file_name_urls.map((ele) => {
          return {
            fileName: ele.filename,
            fileBaseUrl: ele.file_url,
            fileUrl: environment.apiUrl + ele.file_url
          }
        })
        this.fileUploadComponent.actualexistingFilesCount = this.fileUploadComponent.existingFileData.length
       
        // this.basicDetailsForm.controls.fullName.setValue()
        // this.basicDetailsForm.controls.fullName.setValue(resp.data.user.full_name);
        // this.basicDetailsForm.controls.email.setValue(resp.data.user.email);
        // this.mobileNumber = resp.data.user.mobile_number;
        // this.email = resp.data.user.email;
        // this.basicDetailsForm.controls.mobileNumber.setValue(resp.data.user.mobile_number);
        // this.basicDetailsForm.controls.gender.setValue(resp.data.user.gender);
        // this.basicDetailsForm.controls.role.setValue(resp.data.user.role.name);
        // this.basicDetailsForm.controls.dateOfBirth.setValue(resp.data.user.dob);
        // this.basicDetailsForm.controls.dateOfJoining.setValue(resp.data.user.doj)

        // if (this.should_add_address) {
        //   this.addressId = resp.data.user.address.id
        //   this.userAddressForm.controls.line1.setValue(resp.data.user.address.line_1);
        //   this.userAddressForm.controls.line2.setValue(resp.data.user.address.line_2);
        //   this.userAddressForm.controls.pincode.setValue(resp.data.user.address.pincode);
        //   this.stateFormCtrl.setValue(resp.data.user.address.city.state.id);
        //   this.cityFormCtrl.setValue(resp.data.user.address.city.id);
        // }
        // if (this.should_add_availability_timings) {
        //   this.selectedTimeSlots = resp.data.user.timings;
        //   this.isValidTimings = true;

        //   const newdata = resp.data.user.timings.map((ele) => {
        //     return (
        //       {
        //         "day": this.daysList.find(each => each.value == ele.day_of_week).viewValue,
        //         "dayType": this.dayType.find(each => each.value == ele.day_type).viewValue,
        //         // "start_time": moment(new Date(ele.start_time)).format("HH:mm").toString(),
        //         // "end_time": moment(new Date(ele.end_time)).format("HH:mm").toString(),
        //         // "start_time": new Date(ele.end_time).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'}),
        //         "startTime": ele.start_time ? ele.start_time.substring(0, ele.start_time.length - 3) : ele.start_time,
        //         "endTime": ele.end_time ? ele.end_time.substring(0, ele.end_time.length - 3) : ele.end_time,
        //       }
        //     )
        //   })
        //   this.timeSlots = newdata;
        // }
        // if (this.should_add_branch_at_user_creation) {
        //   resp.data.user.branches.forEach(element => {
        //     this.branch_list.push(element.id)
        //   });
        // }
      }
    }, (err) => {
      this.toastr.error(err.error.message);
      this.router.navigateByUrl('Admin/users');
  })
  }
  back() {
    this.router.navigateByUrl('Admin/users');
  }
  getRoles() {
    const data = {
      filter_by_role_active_status: true,
    }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleList = resp.data.roles;
        // this.dataSource.data = resp.data
        // this.roleList.forEach(role_obj => {
        //   this.role_id_name_aliases.push({"id": role_obj.id, "name": role_obj.name, "alias": role_obj.alias})
        // })
        this.get_report_to_users();
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
      this.router.navigateByUrl('Admin/users');
    })
  }

  getState() {
    this.apiService.getStates().subscribe((resp) => {
      if (resp.status == "success") {
        this.statesList = resp.data.states
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
      this.router.navigateByUrl('Admin/users');
    })
  }
  getCities(event) {
    this.apiService.getCities(event).subscribe((resp) => {
      if (resp.status == "success") {
        this.citiesList = resp.data.cities;
        this.router.navigateByUrl('Admin/users');
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  SelectedCity(event) {
    this.userAddressForm.controls['cityId'].setValue(event);
  }
  getBranches() {
    let data = {filter_by_branch_active_status: true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchesList = resp.data.branches
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
      this.router.navigateByUrl('Admin/users');
    })
  }
  selectedState(event) {
    this.apiService.getCities(event).subscribe((resp) => {
    },
    (err) => {
      this.toastr.error(err.error.message)
    })

  }

  applyFilterOnUsers(searchValue) {
    this.filtered_report_to_users = this.report_to_users.filter(optionObj => 
      optionObj.full_name.toLowerCase().includes(searchValue.toLowerCase()) || this.selected_report_to_user_ids.indexOf(optionObj.id) > -1
    )
  }

  get_report_to_users() {
    // todo: For now, keeping the role_names as constants here. They should be moved when cleaning code
    let filter_by_role_ids = []
    let requiredRoleNames = ["ADMIN", "STAFF"];
    this.role_id_name_aliases.forEach(role_obj => {
      if (requiredRoleNames.indexOf(role_obj.alias) > -1) {
        filter_by_role_ids.push(role_obj.id)
      }
    })
    let data = {
      filter_by_user_active_status: true,
      filter_by_role_ids: filter_by_role_ids
    }
    this.apiService.getUserByFilter(data).subscribe((resp) => {
      if (resp.status == "success") { 
        this.report_to_users = resp.data.users
        this.filtered_report_to_users = this.report_to_users
      }
    })
  }
 
  selectRole(event) {
    let selectedObj = this.roleList.find(each => each.id === event);
    this.should_add_address = selectedObj?.should_add_address
    this.should_add_availability_timings = selectedObj?.should_add_availability_timings
    this.should_add_branch_at_user_creation = selectedObj?.should_add_branch_at_user_creation
    this.can_add_multiple_branches = selectedObj?.can_add_multiple_branches

    if (!this.should_add_address) { 
      this.userAddressForm.reset()
      this.stateFormCtrl.reset()
      this.cityFormCtrl.reset()
    }
    if (!this.should_add_branch_at_user_creation) {
      this.branch_list = []
      this.selected_branch_id = null
    }
    if (!this.should_add_availability_timings) {
      this.timeSlots = [];
      this.selectedTimeSlots = []
      this.resetTimeSlots()
      this.daysArray = []
      this.sortedTimeSlots = []
      this.daysList = structuredClone(this.constDaysList)
    }

    if (this.user_id) {
      // to check if mobile number or email is changed while updating
      this.mobileNumber = this.userDetails['mobile_number'];
      this.email = this.userDetails['email'];

      this.basicDetailsForm.controls.fullName.setValue(this.userDetails['full_name']);
      this.basicDetailsForm.controls.email.setValue(this.userDetails['email']);
      this.basicDetailsForm.controls.mobileNumber.setValue(this.userDetails['mobile_number']);
      this.basicDetailsForm.controls.gender.setValue(this.userDetails['gender']);
      this.basicDetailsForm.controls.role.setValue(this.userDetails['role'].name);
      this.basicDetailsForm.controls.dateOfBirth.setValue(this.userDetails['dob']);
      this.basicDetailsForm.controls.dateOfJoining.setValue(this.userDetails['doj']);
      this.basicDetailsForm.controls.designation.setValue(this.userDetails['designation'])

      this.userLeaveConfig = this.userDetails['leave_config']
      if (this.userLeaveConfig) {
        this.basicDetailsForm.controls.casualLeavesCount.setValue(this.userDetails['leave_config']["monthly_casual_leaves_count"])
        this.basicDetailsForm.controls.sickLeavesCount.setValue(this.userDetails['leave_config']["monthly_sick_leaves_count"])
      } else {
        this.basicDetailsForm.controls.casualLeavesCount.setValue(null)
        this.basicDetailsForm.controls.sickLeavesCount.setValue(null)
      }

      if (this.should_add_address) {
        this.addressId = this.userDetails['address']?.id
        this.userAddressForm.controls.line1.setValue(this.userDetails['address'].line_1);
        this.userAddressForm.controls.line2.setValue(this.userDetails['address'].line_2);
        this.userAddressForm.controls.pincode.setValue(this.userDetails['address'].pincode);
        this.stateFormCtrl.setValue(this.userDetails['address'].city.state.id);
        this.cityFormCtrl.setValue(this.userDetails['address'].city.id);
      }

      if (this.should_add_branch_at_user_creation) {
        if (this.can_add_multiple_branches) {
          this.userDetails['branches'].forEach(element => {
            this.branch_list.push(element.id)
          });
        }
        else {
          this.selected_branch_id = this.userDetails['branches'][0]?.id
        }
      }

      if (this.should_add_availability_timings) {
        this.selectedTimeSlots = this.userDetails['timings'];

        this.selectedTimeSlots.forEach(element => {
          element.start_time=this.convertToIst(element.start_time);
          element.end_time=this.convertToIst(element.end_time)
        });
        
        // Filling the days array, so that we won't update it
        this.selectedTimeSlots.forEach((element: any) => {
          if (!this.daysArray.includes(element.day_of_week)) {
            this.daysArray.push(element.day_of_week)
            if (element.day_type == "DAY_OFF") {
              let day_off_day_index = this.daysList.map(e => e.value).indexOf(element.day_of_week);
              if (day_off_day_index > -1) {
                this.daysList.splice(day_off_day_index, 1)
              }
            }
          }
        });
        if (this.daysArray.length == 7) {
          this.isValidTimings = true
        } else {
          this.isValidTimings = false
        }

        const convertTime = timeStr => {
          let zone = "AM"
         if(timeStr != null){
          timeStr = moment(timeStr).format("HH:mm:ss");
          let [hours, minutes,seconds] = timeStr.split(':');
          if (hours >= '12') {
            zone = "PM"
             if (hours > '12') {
               hours = parseInt(hours, 10) - 12;
             }else{
              hours = parseInt(hours, 10);
            }
           }
           if (hours < '10' && typeof (hours) == 'number') {
              hours = '0' + hours
           }
           if (hours == '00') {
             hours = '12'
           }
       
          return `${hours}:${minutes} ${zone}`;
       }else{
        return null;
       }
         }
          const newdata = this.userDetails['timings'].map((ele) => {
          
          return (
            {
              "day": this.constDaysList.find(each => each.value == ele.day_of_week).viewValue,
              "dayType": this.dayType.find(each => each.value == ele.day_type).viewValue,
              // "start_time": moment(new Date(ele.start_time)).format("hh:mm A").toString(),
              // "end_time": moment(new Date(ele.end_time)).format("HH:mm").toString(),
              // "start_time": new Date(ele.end_time).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'}),
              // "startTime": ele.start_time ? ele.start_time.substring(0, ele.start_time.length - 3) : ele.start_time,
              "startTime": convertTime(ele.start_time),
              "endTime" : convertTime(ele.end_time),
              // "endTime": ele.end_time ? ele.end_time.substring(0, ele.end_time.length - 3) : ele.end_time,
            }
          )
        })
        this.timeSlots = newdata;
        this.sortTimeSlots()
      }
      
    }
    
  }
  
  convertToIst(date: string) {
    if(date != null){
      this.newDate = this.datePipe.transform(this.myDate, 'YYYY-MM-dd');
      // date = this.newDate + " " + date
      date = moment(new Date(this.newDate + " " + date)).format("YYYY-MM-DD HH:mm:ss").toString();
      // var endTime = moment(new Date(date)).format("hh:mm").toString();
      return date
    }else{
      return null
    }
    }
  // getTimeFormate(timeStr){

  //     let [hours, minutes,seconds] = timeStr.split(':');
  //     if (hours >= '12') {
  //        hours =  parseInt(hours,10) - 12;
  //     }
  //     console.log(typeof(hours))
  //     if(hours < '10' && typeof(hours) == 'number' ){
  //         hours = '0'+hours
  //     }
   
  //     return `${hours}:${minutes}:${seconds}`;
  // }

  public buildForm() {
    this.basicDetailsForm = this._formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[a-z.-]+\\.[a-z]{2,4}$"),Validators.maxLength(100)]),
      fullName: new FormControl(null, [Validators.required,Validators.maxLength(100)],),
      mobileNumber: new FormControl('', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]),
      role: ['', [Validators.required]],
      gender: new FormControl(null),
      dateOfBirth: new FormControl(null),
      dateOfJoining: new FormControl(null),
      designation: new FormControl(null,[Validators.maxLength(100)]),
      casualLeavesCount: new FormControl(null),
      sickLeavesCount: new FormControl(null),
    });
    this.userAddressForm = this._formBuilder.group({
      line1: new FormControl('', [Validators.required]),
      line2: new FormControl(''),
      pincode: new FormControl('', [Validators.required]),
      state: this.stateFormCtrl,
      cityId: this.cityFormCtrl,
    });

  }
  get f() { return this.basicDetailsForm.controls; }
  
  addDOJEvent(type: string, event: any) {
    if(event.value != null){
     if(this.basicDetailsForm.controls.dateOfJoining.value){
      this.MyJoiningDateString = moment(new Date(this.basicDetailsForm.controls.dateOfJoining.value)).format("YYYY-MM-DD").toString();
     }
    }else{
      this.MyJoiningDateString = null
    }
    // this.MyDateString=this.datePipe.transform(this.basicDetailsForm.controls.dateOfBirth.value, 'dd-MM-yyyy').toString();

  }
  addDOBEvent(type:string,event:any) {
    if(event.value != null){
      if(this.basicDetailsForm.controls.dateOfBirth.value){
        this.MyDateString = moment(new Date(this.basicDetailsForm.controls.dateOfBirth.value)).format("YYYY-MM-DD").toString();
      }
    }else{
      this.MyDateString =null;
    }
   
    // this.basicDetailsForm.controls.dateOfBirth.setValue(this.MyDateString)
  }
 
  generateOtp() {
     // if(this.email != this.basicDetailsForm.controls.email.value ){
    //   payload.user.email = this.basicDetailsForm.controls.email.value;
    //   payload.user.otp= this.meta.otpValue
    //   this.otpTrigger =true
    // }
    const data = {'mobile_number': null, 'email': null, 'otp': null};
    if(this.mobileNumber != this.basicDetailsForm.controls.mobileNumber.value || this.email != this.basicDetailsForm.controls.email.value){
      if(this.mobileNumber != this.basicDetailsForm.controls.mobileNumber.value){
        data['mobile_number'] = this.basicDetailsForm.controls.mobileNumber.value;
      }
      if(this.email != this.basicDetailsForm.controls.email.value){
        data["email"] = this.basicDetailsForm.controls.email.value;
      }
      if (this.isUpdate) {
        this.apiService.generateOtpUserUpdate(data, this.user_id).subscribe((resp) => {
          if (resp.status == "success") {
            let dialogRef = this.dialog.open(OtpConformationComponent, {
              width: '30%',
            });
            dialogRef.afterClosed().subscribe(result => {

              if (this.meta.dialogClosed) {
                this.updateUser()
              }
            });
          }
        },
          (err) => {
            this.toastr.error(err.error.message)
          }
        );
      }
      this.otpTrigger =true
    }else{
      this.updateUser()
    }
     if (this.basicDetailsForm.valid && !this.user_id) {
      const payload = {
        "mobile_number": this.basicDetailsForm.controls.mobileNumber.value,
        "email": this.basicDetailsForm.controls.email.value
      }
      this.apiService.getOtpUser(payload).subscribe((resp) => {
        if (resp.status == "success") {

          let dialogRef = this.dialog.open(OtpConformationComponent, {
            width: '30%',
          });
          dialogRef.afterClosed().subscribe(result => {

            if (this.meta.dialogClosed) {
              this.createUser()
            }
          },
          (err) => {
            this.toastr.error(err.error.message)
          });
        }

      },
        (err) => {
          this.toastr.error(err.error.message)
        });
     } else {
       // Commenting this out, as the message is showing but the API is calling successfully, have to check later
      // this.toastr.error("Form is not Valid")
    }

  }
  allowNumkeyPress(event: any) {
    const pattern = /[0-9\+\-]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  resetTimeSlots() {
    this.selectedDay = [];
    this.startTime = "";
    this.endTime = "";
    this.days = [];
    this.selectedDay_type = "";
    this.daysType = ""
    this.showErrorMsgToAddTimeSlots = false;
    this.disableAdd = true;
    this.disableTime = true;
  }

  getSelectedOptions(selected) {
    this.selected = selected;
  }
  // selectedDays(event) {
  //   this.showMsg=false;
  //   let selectedObj = this.daysList.find(each => each.value === event)
  //   this.selectedDay = selectedObj.viewValue;
  //   this.days = selectedObj.value;
  //   if(this.timeSlots.length>0){
  //     let TimeSlots = this.timeSlots.find(each =>each.day === this.selectedDay)
  //     if(TimeSlots.dayType == "Day Off"){
  //       this.disableAdd = true;
  //     }else{
  //       this.disableAdd=false
  //     }
  //   }
  //   // else if(TimeSlots.dayType == "Working Day"){
  //   //   this.disableAdd=false;
  //   // }
  //   // Swal.fire({
  //   //   position: 'center',
  //   //   icon: 'error',
  //   //   width: 600,
  //   //   title: 'Day Already Exists',
  //   //   showConfirmButton: false,
  //   //   timer: 1500
  //   // })

  // }
  selectedValues: string[] = [];
  selectedViewValues: string[] = [];
  selectedData: { value: string, viewValue: string }[] = [];
  selectedDays(event) {
  
    // this.selectedViewValues = this.selectedValues.map(value => {
    //   const selectedOption = this.daysList.find(day => day.value === value);
    //   return selectedOption ? selectedOption.viewValue : '';
    // });
    // this.selectedData = this.selectedValues.map(value => {
    //   return this.daysList.find(day => day.value === value);
    // });
    // console.log(this.selectedViewValues,this.selectedData,this.selectedValues)
    let selectedDayValues = [...event];
    if (selectedDayValues.length >= 7) {
      this.allComplete = true
    } else {
      this.allComplete = false
    }
    this.selectedDay = []
    this.days = []
    let anyDayOffExists = false
    selectedDayValues.forEach(selectedDayValue => {
      let selectedObj = this.daysList.find(each => each.value === selectedDayValue)
      this.selectedDay.push({"value": selectedObj.value, "viewValue": selectedObj.viewValue});
      this.days.push(selectedObj.value)
      if (this.timeSlots.length > 0) {
        let TimeSlots = this.timeSlots.find(each => each.day === selectedObj.viewValue)
        if (TimeSlots && TimeSlots.dayType == "Day Off") {
          this.disableAdd = true;
          anyDayOffExists = false;
        } else {
        }
      }
      if (anyDayOffExists) {
        this.showErrorMsgToAddTimeSlots = true;
        this.errorMsgToAddTimeSlots = "You can't add day off twice to the same day"
        setTimeout(() => {
          this.resetTimeSlots()
        }, 1000);
      }
    });
  }
  selectedDayType(event) {
    this.disableTime = true;
    this.disableAdd = true;
    this.showErrorMsgToAddTimeSlots = false;
    let selectedTypeObj = this.dayType.find(each => each.value === event);
    this.selectedDay_type = selectedTypeObj.viewValue;
    this.daysType = selectedTypeObj.value;
    let isNoteToAddWithoutTimings = false;

    this.selectedDay.forEach(selectedDay => {
      let existingTimeSlotForGivenDay = this.timeSlots.find(each => each.day === selectedDay.viewValue)
      if (existingTimeSlotForGivenDay) {
        if (this.selectedDay_type == 'Day Off' && existingTimeSlotForGivenDay.dayType == 'Working Day') {
          this.showErrorMsgToAddTimeSlots = true;
          this.errorMsgToAddTimeSlots = "You can't add Day Off and Working Day for same day"
        } else if (this.selectedDay_type == 'Day Off' && existingTimeSlotForGivenDay.dayType == 'Day Off') {
          this.showErrorMsgToAddTimeSlots = true;
          this.errorMsgToAddTimeSlots = "You can't add Day Off twice for same day "
        } else if (this.selectedDay_type == 'Working Day' && existingTimeSlotForGivenDay.dayType == 'Day Off') {
          this.showErrorMsgToAddTimeSlots = true;
          this.errorMsgToAddTimeSlots = "You can't add Working Day and Day Off for same day"
        }
        else {
          this.disableTime = false;
          this.disableAdd = false;
        }
        if (this.showErrorMsgToAddTimeSlots) {
          setTimeout(() => {
            this.resetTimeSlots()
          }, 1000);
        }
      } else if (this.selectedDay_type == 'Day Off') {
        this.disableTime = true;
        this.disableAdd = false;
        isNoteToAddWithoutTimings = true;
      } else {
        this.disableTime = false;
        this.disableAdd = true;
      }
    })
    if (this.showErrorMsgToAddTimeSlots) {
      this.disableTime = true;
      this.disableAdd = true;
    }
    if (isNoteToAddWithoutTimings && !this.showErrorMsgToAddTimeSlots) {
      this.showErrorMsgToAddTimeSlots = true;
      this.errorMsgToAddTimeSlots = "Please continue to add without timings"
    }
  }

  deleteDay(slot) {
    let deletableTimeslot = slot;
    let i = this.timeSlots.findIndex(timeSlot => JSON.stringify(timeSlot) == JSON.stringify(slot))
    this.timeSlots.splice(i, 1);
    this.selectedTimeSlots.splice(i, 1)
    if (deletableTimeslot.dayType == 'Day Off') {
      this.constDaysList.forEach(e => {
        if (e.viewValue == deletableTimeslot.day) {
          this.daysList.push(e)
        }
      })
      // Sorting the days list
      let daysListInOrder = []
      this.constDaysList.forEach(e => {
        if (!daysListInOrder.includes(e.value)) {
          daysListInOrder.push(e.value)
        }
      })
      this.daysList = this.daysList.sort(
        (a, b) =>
          (daysListInOrder.indexOf(a.value) < daysListInOrder.indexOf(b.value)) ? -1 : (daysListInOrder.indexOf(a.value) > daysListInOrder.indexOf(b.value)) ? 1 : 0)
    }
    this.sortTimeSlots()
    
    this.daysArray = []
    this.selectedTimeSlots.forEach((element: any) => {
      if (!this.daysArray.includes(element.day_of_week)) {
        this.daysArray.push(element.day_of_week)
      }
    });
    
    this.isValidTimings = false
    if (this.daysArray.length == 7) {
      this.isValidTimings = true
    }
    this.someComplete()
  }
  utcStartTime:string;
  utcEndTime: string;
  verifyTimeSlots() {
    this.disableAdd = false;
  }
  addTimeSlots() {
    // this.utcStartTime = new Date(this.startTime).toUTCString();
    // this.utcEndTime = new Date(this.endTime).toUTCString()
    // var utcStart = moment(new Date(this.utcStartTime)).format("hh:mm A").toString();
    // this.convertUTCToTime(this.utcStartTime)
    // console.log(utcStart)
    this.showErrorMsgToAddTimeSlots = false;
    if (this.selectedDay.length <= 0 && this.selectedDay_type) {
      this.showErrorMsgToAddTimeSlots = true;
      this.errorMsgToAddTimeSlots = 'Please select a day before adding';
      setTimeout(() => {
        this.resetTimeSlots()
      }, 1000);
      return
    }
    if (this.endTime && this.startTime != "") {
      var endTime = moment(new Date(this.endTime)).format("hh:mm A").toString();
      var strTime = moment(new Date(this.startTime)).format("hh:mm A").toString();
      var enTime = moment(new Date(this.endTime)).format("YYYY-MM-DD HH:mm:ss").toString();
      var stTime = moment(new Date(this.startTime)).format("YYYY-MM-DD HH:mm:ss").toString();
    } else if (this.selectedDay_type == 'Working Day') {
      this.showErrorMsgToAddTimeSlots = true;
      this.errorMsgToAddTimeSlots = 'Please select valid Start Time and End Time before adding';
      setTimeout(() => {
          this.resetTimeSlots()
        }, 1000);
      return
    }

    if (enTime == "Invalid date" || stTime == "Invalid date") {
      this.endTime = null
      this.startTime = null
    } else {
      this.endTime ;
      this.startTime ;
    }

    let areTimeslotsAddable = true;
    let tempTimeSlots = []
    let tempSelectedTimeSlots = [];
    let overlappingTimeDays = []

    this.selectedDay.forEach(selectedDay => {

      if (this.daysType == "DAY_OFF") {
        let object = {
          day: selectedDay.viewValue,
          dayType: this.selectedDay_type,
          startTime: null,
          endTime: null,
        }
        tempTimeSlots.push(object);
        let obj = {
          day_of_week: selectedDay.value,
          day_type: this.daysType,
          start_time: null,
          end_time: null,
        }
        tempSelectedTimeSlots.push(obj);
        // setTimeout(() => {
        //   this.resetTimeSlots()
        // }, 1000);
      } else {
        if ((this.endTime != null || undefined) && (this.startTime != null || undefined)) {
          let object = {
            day: selectedDay.viewValue,
            dayType: this.selectedDay_type,
            startTime: strTime,
            endTime: endTime,
          }
          tempTimeSlots.push(object);
          let obj = {
            day_of_week: selectedDay.value  ,
            day_type: this.daysType,
            start_time: stTime,
            end_time: enTime,
          }
          tempSelectedTimeSlots.push(obj);
          // Checking for the existence of overlapping timings
          this.selectedTimeSlots.forEach(e => {
            let e_start_time = new Date(e.start_time)
            let e_end_time = new Date(e.end_time)
            let obj_start_time = new Date(obj.start_time)
            let obj_end_time = new Date(obj.end_time)

            if(e.day_of_week == selectedDay.value){
            // console.log(e.day_of_week, selectedDay.value)
            // console.log(e_start_time, e_end_time, typeof(e_start_time), typeof(e_end_time))
            // console.log(obj_start_time, obj_end_time, typeof(obj_start_time), typeof(obj_end_time))
            // console.log(e.day_of_week == selectedDay.value)
            // console.log(e_start_time <= obj_start_time && obj_start_time <= e_end_time)
            // console.log(e_start_time <= obj_end_time && obj_end_time <= e_end_time)
            // console.log(e_start_time <= obj_start_time && obj_end_time <= e_end_time)
              // console.log(e.start_time <= obj.start_time && obj.end_time <= e.end_time)
            }

            if (
              e.day_of_week == selectedDay.value && (
              e_start_time <= obj_start_time && obj_start_time <= e_end_time ||
              e_start_time <= obj_end_time && obj_end_time <= e_end_time ||
              (
                obj_start_time <= e_start_time && e_end_time <= obj_end_time
              ))
            ) {
              areTimeslotsAddable = false
              if (overlappingTimeDays.indexOf(selectedDay.viewValue) == -1) {
                overlappingTimeDays.push(selectedDay.viewValue)
              }
            }
          })
        }
      }
    });

    if (areTimeslotsAddable) {
      tempTimeSlots.forEach(dayTimeObj => {
        if (dayTimeObj) {
          this.timeSlots.push(dayTimeObj)
        }
      })
      tempSelectedTimeSlots.forEach(dayTimeObj => {
        if (dayTimeObj) {
          this.selectedTimeSlots.push(dayTimeObj)
        }
      })
      this.sortTimeSlots()

      this.selectedTimeSlots.forEach((element: any) => {
        if (this.daysArray.includes(element.day_of_week)) {
        } else {
          this.daysArray.push(element.day_of_week)
          if (element.day_type == "DAY_OFF") {
            let day_off_day_index = this.daysList.map(e => e.value).indexOf(element.day_of_week);
            if (day_off_day_index > -1) {
              this.daysList.splice(day_off_day_index, 1)
            }
          }
        }
      });
      if (this.daysArray.length == 7) {
        this.isValidTimings = true
      } else {
        this.isValidTimings = false
      }
      this.resetTimeSlots()
    } else {
      this.showErrorMsgToAddTimeSlots = true
      this.errorMsgToAddTimeSlots = "Overlapping times given for " + overlappingTimeDays.join(', ')
      setTimeout(() => {
          this.resetTimeSlots()
        }, 1000);
    }
  }

  sortTimeSlots() {
    let daysListInOrder = []
    this.constDaysList.forEach(elem => {
      if (!daysListInOrder.includes(elem.viewValue)) {
        daysListInOrder.push(elem.viewValue)
      }
    })
    this.sortedTimeSlots = structuredClone(this.timeSlots)
    this.sortedTimeSlots = this.sortedTimeSlots.sort(
      (a, b) => (daysListInOrder.indexOf(a.day) < daysListInOrder.indexOf(b.day)) ? -1 : (daysListInOrder.indexOf(a.day) > daysListInOrder.indexOf(b.day)) ? 1 : 0
    ).sort(
      (a, b) => {
        if (((daysListInOrder.indexOf(a.day) < daysListInOrder.indexOf(b.day)) ? -1 : (daysListInOrder.indexOf(a.day) > daysListInOrder.indexOf(b.day)) ? 1 : 0) == 0  && a.dayType=='Working Day') {
          var nowDate = new Date().toISOString().split('T')[0];
          let aZone = a.startTime.slice(-3,)
          let bZone = b.startTime.slice(-3,)
          
          var astartTime = new Date(nowDate + ' ' + a.startTime.slice(0,-2));
          var bstartTime = new Date(nowDate + ' ' + b.startTime.slice(0,-2))

          if (aZone == bZone) {
            return (astartTime < bstartTime) ? -1 : (astartTime > bstartTime) ? 1 : 0
          } else if (aZone == 'PM' && bZone == 'AM') {
            return 1
          } else if (aZone == 'AM' && bZone == 'PM') {
            return -1
          }
        }
      }
    )
  }

  convertUTCToTime(date:any) {
    const utcDate = new Date(date);
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();

    const convertedHours = hours < 10 ? '0' + hours : hours;
    const convertedMinutes = minutes < 10 ? '0' + minutes : minutes;

    this.convertedTime = `${convertedHours}:${convertedMinutes}`;
    return this.convertedTime
  }
  resetForm() {
    this.buildForm();
    this.selected_report_to_user_ids = []
    this.should_add_availability_timings = false;
    this.should_add_address = false;
    this.should_add_branch_at_user_creation = false
    this.timeSlots = [];
    this.selectedTimeSlots = []
    this.stateFormCtrl.reset()
    this.cityFormCtrl.reset()
    this.branch_list = []
    this.resetTimeSlots()
    this.daysArray = []
    this.sortedTimeSlots = []
    this.daysList = structuredClone(this.constDaysList)
    this.someComplete()
    this.fileUploadComponent.reset()
  }

  payload: any;
  createUser() {
    const payload: any = {}

    let leave_config = null;
    if (this.basicDetailsForm.controls.casualLeavesCount || this.basicDetailsForm.controls.sickLeavesCount) {
      leave_config = {
        monthly_casual_leaves_count: this.basicDetailsForm.controls.casualLeavesCount ? (this.basicDetailsForm.controls.casualLeavesCount.value ? this.basicDetailsForm.controls.casualLeavesCount.value:null) : null,
        monthly_sick_leaves_count: this.basicDetailsForm.controls.sickLeavesCount ? (this.basicDetailsForm.controls.sickLeavesCount.value ? this.basicDetailsForm.controls.sickLeavesCount.value:null) : null
      }
    }

    let selectedBranchIds = []
    if (this.should_add_branch_at_user_creation) {
      if (this.can_add_multiple_branches) {
        selectedBranchIds = this.branch_list
      }
      else {
        selectedBranchIds = [this.selected_branch_id]
      }
    }

    payload['user'] = {
      full_name: this.basicDetailsForm.controls.fullName.value,
      email: this.basicDetailsForm.controls.email.value,
      mobile_number: this.basicDetailsForm.controls.mobileNumber.value,
      otp: this.meta.otpValue,
      gender: this.basicDetailsForm.controls.gender.value,
      dob: this.MyDateString,
      timezone_region:this.userTimeZone,
      doj:this.MyJoiningDateString,
      role_id: this.basicDetailsForm.controls.role.value,
      report_to_user_ids: this.selected_report_to_user_ids,
      designation: this.basicDetailsForm.controls.designation.value,

      branch_ids: selectedBranchIds,
      leave_config: leave_config
      
    }
    if (this.should_add_address) {
      payload.user['address'] = {
        line_1: this.userAddressForm.controls.line1.value,
        line_2: this.userAddressForm.controls.line2.value,
        pincode: this.userAddressForm.controls.pincode.value,
        city_id: this.userAddressForm.controls.cityId.value,
      }
    }

    if (this.should_add_availability_timings) {
      payload.user['timings'] = this.selectedTimeSlots
    }

    this.apiService.createUser(payload).subscribe((resp) => {
      if (resp.status == "success") {
        // Swal.fire({
        //   position: 'center',
        //   icon: 'success',
        //   width: 400,
        //   title: 'User Created Successfully',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
        

        // See how to make it out common in both create and update user
        // Uploading Files
        this.user_id = resp.data.id
        if (this.fileUploadComponent.files.length || this.fileUploadComponent.existingFileData) {          
          if (this.fileUploadComponent.files.length) {

            let fileUploadRequest = this.fileUploadComponent.uploadFiles("users/" + this.user_id)
            fileUploadRequest.subscribe((resp) => {
              // Successfully saved all files
  
              // Started updating uploaded files in user files
              let fileUrls = resp["file_urls"]
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateUserFiles(payload, this.user_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in user files
                  this.router.navigateByUrl('Admin/users');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }, (err) => {
              this.toastr.error("Failed to upload Files");
            })
          }
          else {
            // can update only if any files are removed
            if (this.fileUploadComponent.actualexistingFilesCount != this.fileUploadComponent.existingFileData.length) {
              let fileUrls = []
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateUserFiles(payload, this.user_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/users');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }
            else {this.router.navigateByUrl('Admin/users');}
          }
        }
        else{this.router.navigateByUrl('Admin/users');}
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  otpTrigger:boolean=false
  updateUser() {
    const payload: any = {}

    let selectedBranchIds = []
    if (this.should_add_branch_at_user_creation) {
      if (this.can_add_multiple_branches) {
        selectedBranchIds = this.branch_list
      }
      else {
        selectedBranchIds = [this.selected_branch_id]
      }
    }

    payload['user'] = {
      full_name: this.basicDetailsForm.controls.fullName.value,
      gender: this.basicDetailsForm.controls.gender.value,
      dob: this.MyDateString,
      doj:this.MyJoiningDateString,
      role_id: this.basicDetailsForm.controls.role.value,
      mobile_number:null,
      timezone_region:this.userTimeZone,
      email:null,
      otp: null,
      branch_ids: selectedBranchIds,
      report_to_user_ids: this.selected_report_to_user_ids,
      designation: this.basicDetailsForm.controls.designation.value
    }
    if(this.mobileNumber != this.basicDetailsForm.controls.mobileNumber.value ){
      payload.user.mobile_number = this.basicDetailsForm.controls.mobileNumber.value;
      payload.user.otp= this.meta.otpValue
      this.otpTrigger =true
    }
    if(this.email != this.basicDetailsForm.controls.email.value ){
      payload.user.email = this.basicDetailsForm.controls.email.value;
      payload.user.otp= this.meta.otpValue
      this.otpTrigger =true
    }
    if (this.should_add_address) {
      payload.user['address'] = {
        id:this.addressId,
        line_1: this.userAddressForm.controls.line1.value,
        line_2: this.userAddressForm.controls.line2.value,
        pincode: this.userAddressForm.controls.pincode.value,
        city_id: this.userAddressForm.controls.cityId.value,
      }
    }
    if (
      this.basicDetailsForm.controls.casualLeavesCount || this.basicDetailsForm.controls.sickLeavesCount
    ) {
      let monthly_casual_leaves_count = null;
      let monthly_sick_leaves_count = null;
      if (this.userDetails['leave_config']) {
        monthly_casual_leaves_count = this.userDetails['leave_config']["monthly_casual_leaves_count"];
        monthly_sick_leaves_count = this.userDetails['leave_config']["monthly_sick_leaves_count"];
      } else {
        this.userDetails['leave_config'] = { "monthly_casual_leaves_count": null, "monthly_sick_leaves_count": null }
      }

      if (this.basicDetailsForm.controls.casualLeavesCount &&
        this.userDetails['leave_config']["monthly_casual_leaves_count"] != this.basicDetailsForm.controls.casualLeavesCount.value
      ) {
        monthly_casual_leaves_count = this.basicDetailsForm.controls.casualLeavesCount.value ? this.basicDetailsForm.controls.casualLeavesCount.value : null
      }

      if (this.basicDetailsForm.controls.sickLeavesCount &&
        this.userDetails['leave_config']["monthly_sick_leaves_count"] != this.basicDetailsForm.controls.sickLeavesCount.value
      ) {
        monthly_sick_leaves_count = this.basicDetailsForm.controls.sickLeavesCount.value ? this.basicDetailsForm.controls.sickLeavesCount.value : null
      }

      payload.user["leave_config"] = {
        monthly_casual_leaves_count: monthly_casual_leaves_count,
        monthly_sick_leaves_count: monthly_sick_leaves_count,
        id: this.userLeaveConfig ? this.userLeaveConfig['id'] : null
      }
    } else {
      this.userDetails['leave_config'] = { "monthly_casual_leaves_count": 0, "monthly_sick_leaves_count": 0 }
      payload.user["leave_config"] = {
        monthly_casual_leaves_count: 0,
        monthly_sick_leaves_count: 0,
        id: this.userLeaveConfig ? this.userLeaveConfig['id'] : null
      }
    }
    
    if (this.should_add_availability_timings) {
      payload.user['timings'] = this.selectedTimeSlots
    }
    this.apiService.updateUser(payload,this.user_id).subscribe((resp) => {
      if (resp.status == "success") {
        // this.toastr.success('User Created Successfully')
        // Swal.fire({
        //   position: 'center',
        //   icon: 'success',
        //   width: 400,
        //   title: 'User Created Successfully',
        //   showConfirmButton: false,
        //   timer: 1500
        // })

        // See how to make it out common in both create and update branch
        // Uploading Files
        if (this.fileUploadComponent.files.length || this.fileUploadComponent.existingFileData) {          
          if (this.fileUploadComponent.files.length) {

            let fileUploadRequest = this.fileUploadComponent.uploadFiles("users/" + this.user_id)
            fileUploadRequest.subscribe((resp) => {
              // Successfully saved all files
  
              // Started updating uploaded files in branch files
              let fileUrls = resp["file_urls"]
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateUserFiles(payload, this.user_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/users');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }, (err) => {
              this.toastr.error("Failed to upload Files");
            })
          }
          else {
            // can update only if any files are removed
            if (this.fileUploadComponent.actualexistingFilesCount != this.fileUploadComponent.existingFileData.length) {
              let fileUrls = []
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateUserFiles(payload, this.user_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/users');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }
            else {this.router.navigateByUrl('Admin/users');}
          }
        }
        else{this.router.navigateByUrl('Admin/users');}

      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
  isCreateOrUpdateUserValid() {
    return (
      this.basicDetailsForm.invalid ||
      (this.should_add_branch_at_user_creation && ((this.can_add_multiple_branches && this.branch_list.length <= 0) || (!this.can_add_multiple_branches && !this.selected_branch_id))) ||
      (this.should_add_address && this.userAddressForm.invalid) ||
      (this.should_add_availability_timings && !this.isValidTimings)
    )
  }


  someComplete(): boolean {
    if (this.days.length == this.daysList.length) {
      this.allComplete = true
    } else {
      this.allComplete = false
    }

    return this.days.length > 0 && !this.allComplete;
  }

  setAll(change) {
    if (change.checked) {
      this.allComplete = true;
      this.days = this.daysList.map(e => e.value);
    } else {
      this.allComplete = false;
      this.days = [];
    }
    this.selectedDays(this.days)
  }
}