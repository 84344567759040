import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-schedule',
  templateUrl: './create-schedule.component.html',
  styleUrls: ['./create-schedule.component.scss']
})
export class CreateScheduleComponent {

  title = null;

  isUpdateSchedule = false;
  userOrderPlanId = null;
  slotBookingId = null;
  customer = null;
  userOrderPlanInfo = null;
  slotBookingInfo = null;

  selectedSlotDate = null;
  availableSlotTimes = []
  selectedSlotTimeAlias = null;
  availableTrainers = []
  selectedTrainerId = null;


  constructor(private router: Router, public apiService: ApiService, public toastr: ToastrService) {
    this.userOrderPlanId = sessionStorage.getItem('userOrderPlanIdToCreateSchedule')
    this.slotBookingId = sessionStorage.getItem('slotBookingIdToUpdateSchedule')

    if ((this.userOrderPlanId && this.slotBookingId) || (!this.userOrderPlanId && !this.slotBookingId)) {
      this.back()
    }
    if (this.userOrderPlanId) {
      this.isUpdateSchedule = false;
      this.title = "CREATE SCHEDULE"
      this.getUserOrderPlanInfo()
    }
    if (this.slotBookingId) {
      this.isUpdateSchedule = true;
      this.title = "EDIT SCHEDULE"
      this.getSlotBookingInfo()
    }
  }

  back() {
    if (!this.isUpdateSchedule) {
      sessionStorage.removeItem('userOrderPlanIdToCreateSchedule')
      this.router.navigate(['Admin/active-schedules']);
    }
    if (this.isUpdateSchedule) {
      sessionStorage.removeItem('slotBookingIdToUpdateSchedule')
      this.router.navigate(['Admin/user-schedules']);
    }
  }

  getUserOrderPlanInfo() {
    this.apiService.getSchedulesUserOrderPlanById(this.userOrderPlanId).subscribe((response) => {
      if (response.status == "success") {
        this.userOrderPlanInfo = response.data.user_plan
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  getSlotBookingInfo() {
    this.apiService.getScheduleById(this.slotBookingId).subscribe((response) => {
      this.slotBookingInfo = response.data.slot_booking
      this.slotBookingInfo.slotDate = moment(new Date(this.slotBookingInfo.start_date_time)).format("YYYY-MM-DD").toString();
      this.slotBookingInfo.slotTime = moment(new Date(this.slotBookingInfo.start_date_time)).format("hh:mm:ss A").toString();
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  getAvailableTimeSlots() {
    this.selectedSlotTimeAlias = null;
    this.selectedTrainerId = null;
    let slotDateString = moment(new Date(this.selectedSlotDate)).format("YYYY-MM-DD").toString();
    let branch_id = null;
    if (this.userOrderPlanInfo) {
      branch_id = this.userOrderPlanInfo.branch.id
    }
    if (this.slotBookingInfo) {
      branch_id = this.slotBookingInfo.branch.id
    }

    let requestBody = {
      "user_order_plan_id": this.userOrderPlanId,
      "slot_booking_id": this.slotBookingId,
      "slot_date": slotDateString
    }
    this.apiService.getAvailableTimeSlotsToCreateupdateSchedule(requestBody).subscribe((response) => {
      if (response.status == "success") {
        this.availableSlotTimes = response.data.timeslots
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }

  getAvailableTrainers() {
    this.selectedTrainerId = null;
    let slotDateString = moment(new Date(this.selectedSlotDate)).format("YYYY-MM-DD").toString();
    let requestBody = {
      "time_slot": this.selectedSlotTimeAlias,
      "slot_date": slotDateString,
      "user_order_plan_id": this.userOrderPlanId,
      "slot_booking_id": this.slotBookingId,
    }
    this.apiService.getSchedulesAvailableTrainers(requestBody).subscribe((response) => {
      if (response.status == "success") {
        this.availableTrainers = response.data.available_trainers
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }

  createSchedule() {
    let slotDateString = moment(new Date(this.selectedSlotDate)).format("YYYY-MM-DD").toString();
    let requestBody = {
      "time_slot": this.selectedSlotTimeAlias,
      "slot_date": slotDateString,
      "trainer_id": this.selectedTrainerId
    }
    let pathParams = {
      "user_order_plan_id": this.userOrderPlanId
    }

    this.apiService.createSchedule(requestBody, pathParams).subscribe((response) => {
      if (response.status == "success") {
        this.back()
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }

  updateSchedule() {
    let slotDateString = moment(new Date(this.selectedSlotDate)).format("YYYY-MM-DD").toString();
    let requestBody = {
      "time_slot": this.selectedSlotTimeAlias,
      "slot_date": slotDateString,
      "trainer_id": this.selectedTrainerId
    }
    let pathParams = {
      "slot_booking_id": this.slotBookingId
    }
    this.apiService.updateSchedule(requestBody, pathParams).subscribe((response) => {
      if (response.status == "success") {
        this.back()
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }

  reset() {
    this.selectedSlotDate = null;
    this.selectedSlotTimeAlias = null;
    this.selectedTrainerId = null;
    this.availableSlotTimes = []
    this.availableTrainers = []
  }
}
