import { Injectable } from '@angular/core';
import { BranchesListComponent } from './pages/branches/branches-list/branches-list.component';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  public otpValue: string;
  public dialogClosed:boolean;
  constructor() { }
}
export interface IroleActions {
  roleAction: string;
  roleType: string;
  roleStatus: string;
}
export interface IUserActions {
  userRole: string;
  userBranch: string;
  userStatus: string;
}
export interface roles {
  name: string;
  id: string;
}
export interface IBranchActions {
  branchName: string;
  branchId: string;
  branchActive: string;
}
export interface IDeviceActions {
  branchId: string;
  deviceActive: string;
}
export interface IRescheduleActions {
  rescheduleStatus: string;
}
export interface PlanTypeActions {
  planTypeId: string;
  planTypeActive: string;
}
export interface LeaveActions {
  filter_by_from_date: string;
  filter_by_to_date: string;
  filter_by_leave_status: string;
}
export interface PlansActions {
  
  plansId: string;
  plansActive: string;
  planTypeId:string;
  planSubTypeId:string
}
export interface IAttendance {
  branchId: string;
  roleId:string;
}
export interface viewUser {
  email: string,
  mobile_number: string,
  full_name: string,
  id: string,
  gender: string,
  dob: string,
  avatar: string,
  doj: string,
  height: string,
  weight: string,
  role: {
   
  },
  branches:branchDetails[],
  entity_address: entity_address,
  entity_timings:entity_timings []
}
export interface branchDetails{
  id: string,
  name: string,
}
export interface entity_address {
    line_1: string,
    line_2: string,
    pincode: string,
    map_location: string,
    city_id: string,
    entity_group_id: string
}
export interface role {
  id: string,
  name: string,
  alias: string,
}
export interface entity_timings{
    day_of_week: string,
    day_type: string,
    start_time: string,
    end_time: string,
    entity_group_id: string
}
export interface ITicketActions {
  userId: string;
  tagId: string;
  ticketId: string;
}

export interface ITagActions {
  tagId: string;
  tagActive: string;
}

export interface IReport {
  branchId: string;
  roleId:string;
  userId:string;
}

export enum featureConstants {
  VIEW_USERS = "VIEW_USERS",
  CREATE_USERS = "CREATE_USERS",
  EDIT_USERS = "EDIT_USERS",
  DEACTIVATE_USERS = "DEACTIVATE_USERS",
  REACTIVATE_USERS = "REACTIVATE_USERS",

  VIEW_ROLES = "VIEW_ROLES",
  CREATE_ROLES = "CREATE_ROLES",
  EDIT_ROLES = "EDIT_ROLES",
  DEACTIVATE_ROLES = "DEACTIVATE_ROLES",
  REACTIVATE_ROLES = "REACTIVATE_ROLES",

  VIEW_BRANCHES = "VIEW_BRANCHES",
  CREATE_BRANCHES = "CREATE_BRANCHES",
  EDIT_BRANCHES = "EDIT_BRANCHES",
  DEACTIVATE_BRANCHES = "DEACTIVATE_BRANCHES",
  REACTIVATE_BRANCHES = "REACTIVATE_BRANCHES",
  ALLOWED_TO_ALL_BRANCHES = "ALLOWED_TO_ALL_BRANCHES",
  ALLOWED_TO_ASSIGNED_BRANCHES = "ALLOWED_TO_ASSIGNED_BRANCHES",
  
  VIEW_LEAVES = "VIEW_LEAVES",
  CREATE_LEAVES = "CREATE_LEAVES",
  EDIT_LEAVES = "EDIT_LEAVES",
  APPROVE_LEAVES = "APPROVE_LEAVES",
  
  VIEW_TIMESLOT_DISCOUNTS = "VIEW_TIMESLOT_DISCOUNTS",
  CREATE_TIMESLOT_DISCOUNTS = "CREATE_TIMESLOT_DISCOUNTS",
  EDIT_TIMESLOT_DISCOUNTS = "EDIT_TIMESLOT_DISCOUNTS",
  DEACTIVATE_TIMESLOT_DISCOUNTS = "DEACTIVATE_TIMESLOT_DISCOUNTS",
  REACTIVATE_TIMESLOT_DISCOUNTS = "REACTIVATE_TIMESLOT_DISCOUNTS",

  VIEW_ATTENDANCE_LOGS = "VIEW_ATTENDANCE_LOGS",
  CREATE_ATTENDANCE_LOGS = "CREATE_ATTENDANCE_LOGS",
  ALLOWED_TO_ATTENDANCE_REPORT = "ALLOWED_TO_ATTENDANCE_REPORT",
  ALLOWED_TO_PAYROLL_REPORT = "ALLOWED_TO_PAYROLL_REPORT",
  ALLOWED_TO_USAGE_REPORT = "ALLOWED_TO_USAGE_REPORT",

  VIEW_ATTENDANCE_DEVICES = "VIEW_ATTENDANCE_DEVICES",
  CREATE_ATTENDANCE_DEVICES = "CREATE_ATTENDANCE_DEVICES",
  EDIT_ATTENDANCE_DEVICES = "EDIT_ATTENDANCE_DEVICES",
  DEACTIVATE_ATTENDANCE_DEVICES = "DEACTIVATE_ATTENDANCE_DEVICES",
  REACTIVATE_ATTENDANCE_DEVICES = "REACTIVATE_ATTENDANCE_DEVICES",

  VIEW_PLANS = "VIEW_PLANS",
  CREATE_PLANS = "CREATE_PLANS",
  EDIT_PLANS = "EDIT_PLANS",
  DEACTIVATE_PLANS ="DEACTIVATE_PLANS",
  REACTIVATE_PLANS = "REACTIVATE_PLANS",

  VIEW_ADDON_PLANS = "VIEW_ADDON_PLANS",
  CREATE_ADDON_PLANS = "CREATE_ADDON_PLANS",
  EDIT_ADDON_PLANS = "EDIT_ADDON_PLANS",
  DEACTIVATE_ADDON_PLANS = "DEACTIVATE_ADDON_PLANS",
  REACTIVATE_ADDON_PLANS = "REACTIVATE_ADDON_PLANS",

  VIEW_COUPON_TYPES = "VIEW_COUPON_TYPES",
  CREATE_COUPON_TYPES = "CREATE_COUPON_TYPES",
  EDIT_COUPON_TYPES = "EDIT_COUPON_TYPES",
  DEACTIVATE_COUPON_TYPES = "DEACTIVATE_COUPON_TYPES",
  REACTIVATE_COUPON_TYPES = "REACTIVATE_COUPON_TYPES",
 
  VIEW_COUPONS = "VIEW_COUPONS",
  CREATE_COUPONS = "CREATE_COUPONS",
  EDIT_COUPONS = "EDIT_COUPONS",
  DEACTIVATE_COUPONS = "DEACTIVATE_COUPONS",
  REACTIVATE_COUPONS = "REACTIVATE_COUPONS",

  CREATE_TICKETS = "CREATE_TICKETS",
  EDIT_TICKETS = "EDIT_TICKETS",
  VIEW_TICKETS = "VIEW_TICKETS",
  RESOLVE_TICKETS = "RESOLVE_TICKETS",
  DEACTIVATE_TICKETS = "DEACTIVATE_TICKETS",
  REACTIVATE_TICKETS = "REACTIVATE_TICKETS",

  VIEW_USER_PAYABLES = "VIEW_USER_PAYABLES",
  CREATE_USER_PAYABLES = "CREATE_USER_PAYABLES",
  EDIT_USER_PAYABLES = "EDIT_USER_PAYABLES",
  
  VIEW_SCHEDULES = "VIEW_SCHEDULES",
  EDIT_SCHEDULES = "EDIT_SCHEDULES",
  CREATE_SCHEDULES = "CREATE_SCHEDULES",

  UPLOAD_FAQS = "UPLOAD_FAQS",

  VIEW_PURCHASE_HISTORY = "VIEW_PURCHASE_HISTORY",
  CANCEL_USER_PLANS = "CANCEL_USER_PLANS",

  VIEW_USER_WALLETS = "VIEW_USER_WALLETS",

  VIEW_REFERRAL_PERCENTAGES = "VIEW_REFERRAL_PERCENTAGES",
  CREATE_REFERRAL_PERCENTAGES = "CREATE_REFERRAL_PERCENTAGES",
  EDIT_REFERRAL_PERCENTAGES = "EDIT_REFERRAL_PERCENTAGES",
  DEACTIVATE_REFERRAL_PERCENTAGES = "DEACTIVATE_REFERRAL_PERCENTAGES",
  REACTIVATE_REFERRAL_PERCENTAGES = "REACTIVATE_REFERRAL_PERCENTAGES",
  
  VIEW_USER_ACCESSES = "VIEW_USER_ACCESSES",
  EDIT_USER_ACCESSES = "EDIT_USER_ACCESSES",

  UPDATE_PLAN_TRAINER = "UPDATE_PLAN_TRAINER",

  VIEW_CUSTOM_NOTIFICATIONS = "VIEW_CUSTOM_NOTIFICATIONS",
  CREATE_CUSTOM_NOTIFICATIONS = "CREATE_CUSTOM_NOTIFICATIONS",

  VIEW_STEEL_BANNERS = "VIEW_STEEL_BANNERS",
  CREATE_STEEL_BANNERS = "CREATE_STEEL_BANNERS",
  EDIT_STEEL_BANNERS = "EDIT_STEEL_BANNERS",
  DEACTIVATE_STEEL_BANNERS = "DEACTIVATE_STEEL_BANNERS",
  REACTIVATE_STEEL_BANNERS = "REACTIVATE_STEEL_BANNERS",

};

export enum defaultRoles {
  ADMIN = "ADMIN",
  BRANCH_ADMIN = "BRANCH_ADMIN",
  TRAINER = "TRAINER",
  CUSTOMER = "CUSTOMER",
}


export const DISPLAY_PROFILE_PIC_URL = "assets/images/avatars/male-04.jpg"
