import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { LeaveActions, PlanTypeActions, defaultRoles, featureConstants } from 'app/meta.service';
import { UpdateLeaveStatusComponent } from 'app/pages/leave-management/update-leave-status/update-leave-status.component';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';

export interface IPayable {
  id: String;
  is_removed: boolean;
  // AddBranch: boolean;
}
@Component({
  selector: 'app-get-user-payable',
  templateUrl: './get-user-payable.component.html',
  styleUrls: ['./get-user-payable.component.scss']
})

export class GetUserPayableComponent {

  scrHeight: number;
  scrWidth: number;
  newDate: any;
  minDate: any;
  fromDate = null;
  toDate = null;
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  userId = null;
  FirstName = null;
  userRoleType = defaultRoles.CUSTOMER
  // need to keep it ion constants

  searchInputControl: UntypedFormControl = new UntypedFormControl();
  
  displayedColumns = ["Monthly","Session", "FromDate","ToDate","OverTime", "Actions"]; 
  dataSource = new MatTableDataSource<IPayable>();

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToEditPayables = this.userAllowedFeatures.indexOf(featureConstants.EDIT_USER_PAYABLES) > -1
  isUserAllowedToCreatePayables = this.userAllowedFeatures.indexOf(featureConstants.CREATE_USER_PAYABLES) > -1

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public apiService: ApiService, public dialog: MatDialog, public toastr: ToastrService, public router: Router) {
    this.getScreenSize();
    
    this.userId = sessionStorage.getItem('UserPayablesForUserId') || this.userId;
    this.FirstName = sessionStorage.getItem('UserPayablesForUserName') || this.FirstName;
  }

  back() {
    sessionStorage.removeItem('UserPayablesForUserId')
    sessionStorage.removeItem('UserPayablesForUserName')
    sessionStorage.removeItem('paginationDataForUserPayables')
    this.router.navigateByUrl('Admin/users');
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForUserPayables');
    
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
    }
    this.getUserPayables()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getUserPayables() {
    let data = {
      filter_by_user_id: this.userId,
      offset : this.bOffset,
      limit : this.bLimit
    }
    this.apiService.getUserPayableList(data).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.users_payables;
        this.totalCount=response.data.total_count;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  createPayable() {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
    };
    sessionStorage.setItem('paginationDataForUserPayables', JSON.stringify(paginationData));
    sessionStorage.setItem('CreatePayableForUserId', this.userId);
    this.router.navigateByUrl('Admin/create-user-payable');
  }

  editPayable(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
    };
    sessionStorage.setItem('paginationDataForUserPayables', JSON.stringify(paginationData));
    sessionStorage.setItem('EditPayableForUserId', this.userId);
    sessionStorage.setItem('payableId', row.id);
    this.router.navigateByUrl('Admin/edit-user-payable');
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getUserPayables(); 
  }

}


