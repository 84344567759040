<div class="container-fluid" style="background-color: #F1F5F9; height:100%;">
    <div class="dilogStl">
      <h1 class="viewHeading" mat-dialog-title>VIEW USER ACCESSES</h1>
      <button align="end" mat-icon-button mat-dialog-close>
          <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
      </button>
  </div>
  <mat-dialog-content class="mat-typography">
      <div class="">
          <div class="card-header mb-4" >
            <h4 class="p-3 ml-2.5">
              Access Controls
            </h4>
          </div>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel *ngFor="let head of heading; let i = index " >
              <mat-expansion-panel-header>
                <mat-panel-title [ngClass]="{'h6': step === 0}" style="color: #000000 !important; font-weight: bold; height: fit-content;">
                  {{headingArr[i]}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div style="overflow:auto;">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Features</th>
                    <th scope="col"></th>
                    <th scope="col">Web</th>
                    <th scope="col">Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dataArr[i]">
                    <td width="60%" class="fieldName">{{row.name}}</td>
                    <td width="10px"></td>
                    <td width="20px"><mat-checkbox [disabled]="true" [checked]="row.is_checked_on_web_app"
                        class="example-margin"></mat-checkbox></td>
                    <td width="20px"><mat-checkbox [disabled]="true" [checked]="row.is_checked_on_mobile_app"
                        class="example-margin"></mat-checkbox></td>
                  </tr>
                </tbody>
              </table>
            </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
  
    </mat-dialog-content>
  
</div>