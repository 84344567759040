import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plan-waitlists-management',
  templateUrl: './plan-waitlists-management.component.html',
  styleUrls: ['./plan-waitlists-management.component.scss']
})
export class PlanWaitlistsManagementComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  scrHeight: number;
  scrWidth: number;

  title = "Plan Waitlists";

  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number = 0;
  bLimit:number = 10;

  filterBySearchTerm = '';
  filterByBranchId = '';
  filterByActiveStatus = '';
  filterByNotifyStatus = '';

  activeStatuses = [
    { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'InActive' }
  ]

  notifyStatuses = [
    { value: '', viewValue: 'All Statuses' },
    { value: 'BOOKING_OPEN', viewValue: 'Booking Open' },
    { value: 'WAITLISTED', viewValue: 'Waitlisted' }
  ]

  branchesList = [];

  displayedColumns: string[] = ["user", "plan", "branch", "timeSlot", "trainer", "trainerCode", "notifyStatus", "createdBy", "createdAt"];
  
  dataSource = new MatTableDataSource<any>();

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }
  
  constructor(
    public apiService: ApiService, 
    public toastr: ToastrService, 
    public router: Router
  ){
    this.getScreenSize();
  }

  getBranches() {
    let data = {filter_by_branch_active_status: true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchesList = resp.data.branches
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForPlanWaitlists');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    sessionStorage.removeItem('paginationDataForPlanExpiryRenewalTracks');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterBySearchTerm = paginationData.filterValue;
      this.filterByBranchId = paginationData.filterByBranchId;
      this.filterByActiveStatus = paginationData.filterByActiveStatus;
      this.filterByNotifyStatus = paginationData.filterByNotifyStatus;
    }

    this.getBranches()

    this.getPlanWaitlists()
  }

  getPlanWaitlists(){
    let data: any = {
      offset: this.bOffset,
      limit: this.bLimit,
      filter_by_search_term: this.filterBySearchTerm || null,
      filter_by_active_status: this.filterByActiveStatus || null,
      filter_by_notify_status: this.filterByNotifyStatus || null,
      filter_by_branch_id: this.filterByBranchId || null,
    };
    
    this.apiService.getPlanWaitlistsAPI(data).subscribe((response) => {
      if (response.status == "success") {
        this.totalCount = response.data.total_count;
        let planWaitlists = []
        response.data.plan_waitlists.forEach(planWaitlistDataObj => {
          let planWaitlistObj = {
            "userName": planWaitlistDataObj.user.full_name, 
            "userMobileNumber": planWaitlistDataObj.user.mobile_number,
            "planName": planWaitlistDataObj.plan.name,
            "planDuration": planWaitlistDataObj.plan_duration.validity_in_months,
            "timeSlot": planWaitlistDataObj.time_slot.name,
            "branchName": planWaitlistDataObj.branch.name,
            "trainerCode": planWaitlistDataObj.trainer_code,
            "trainerName": planWaitlistDataObj.trainer?.full_name,
            "trainerMobileNumber": planWaitlistDataObj.trainer?.mobile_number,
            "notifyStatus": planWaitlistDataObj.notify_status,
            "createdByName": planWaitlistDataObj.created_by?.name,
            "createdByRoleName": planWaitlistDataObj.created_by?.role.name,
            "createdAt": planWaitlistDataObj.created_at
          }

          planWaitlists.push(planWaitlistObj)

        });
        this.dataSource.data = planWaitlists;
      
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
      }
    );
  }

  onGetPlanWaitlists(){
    this.pageIndex = 0;
    this.bOffset = 0;

    this.getPlanWaitlists()
  }

  applyFilter(event: Event) {
    this.filterBySearchTerm = (event.target as HTMLInputElement).value;
    this.onGetPlanWaitlists()
  }
  
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    this.getPlanWaitlists();
  }
  
}
