import { Component, PLATFORM_INITIALIZER, ViewChild } from '@angular/core';
import { MatPaginator,PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService, featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { limits } from 'chroma-js';
import { forEach } from 'lodash';
import { ExcelDownloadService } from 'app/excel-download.service';
import { VerifyDeactivateComponent } from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
import { MatDialog } from '@angular/material/dialog';
import { CancelPlanComponent } from '../cancel-plan/cancel-plan.component';
import { ChangePlanTrainerComponent } from '../change-plan-trainer/change-plan-trainer.component';
import { trainerInterface } from 'app/pages/commonComponents/customInterfaces/customInterfaces.interface';

@Component({
  selector: 'app-user-purchase-history',
  templateUrl: './user-purchase-history.component.html',
  styleUrls: ['./user-purchase-history.component.scss']
})
export class UserPurchaseHistoryComponent {

  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  scrHeight: number;
  scrWidth: number;

  userId = null;
  userName = "User Purchase History";
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  searchIndicator=1;
  filterValue = null;
  filterByBranchId = '';
  filterByFromDate = '';
  filterByToDate = '';
  filterByPaymentStatus = '';
  filterValueToDisplay = ""
  branches_list:[];
  currentRoutePath = null;
  downloadInToExcel = false;
  // isUserPurchaseHistory: boolean = false;
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewSchedules = this.userAllowedFeatures.indexOf(featureConstants.VIEW_SCHEDULES) > -1
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToCancelPlans = this.userAllowedFeatures.indexOf(featureConstants.CANCEL_USER_PLANS) > -1
  isUserAllowedToUpdatePlanTrainer = this.userAllowedFeatures.indexOf(featureConstants.UPDATE_PLAN_TRAINER) > -1

  paymentStatuses = [
    { value: '', viewValue: 'All Payment Statuses' },
    { value: 'SUCCESS', viewValue: 'Success' },
    { value: 'PENDING', viewValue: 'Pending' },
    { value: 'FAILED', viewValue: 'Failed' },
    { value: 'EXPIRED', viewValue: 'Expired' },
    { value: 'IN_PROCESS', viewValue: 'In Process' }
  ]

  displayedColumns: string[]; 

  dataSource = new MatTableDataSource<any>();

  constructor(
    public apiService: ApiService, 
    public toastr: ToastrService, 
    public router: Router,
    public dialog: MatDialog,
    public meta: MetaService,
    private route: ActivatedRoute, 
    private excelDownloadService: ExcelDownloadService, 
  ) {
    this.getScreenSize();


    this.route.url.subscribe(url => {      
      if (url && url.length > 0 && url[0].path=="user-purchase-history-all") {
        sessionStorage.removeItem('userPurchaseHistoryForUserId');
        sessionStorage.removeItem('userPurchaseHistoryForUserName');
      }
});

    this.userId = sessionStorage.getItem('userPurchaseHistoryForUserId') || this.userId;
    this.userName = sessionStorage.getItem('userPurchaseHistoryForUserName') || this.userName;
    if (this.userId === null) {
      this.searchIndicator=1;
      this.displayedColumns = ["userName", "email", "mobileNumber", "planName", "startDate", "totalPrice", "paymentGateway", "paymentStatus", "paymentSuccessAt", "planStatus", "invoiceNo", "Actions"];
    } else {
      this.searchIndicator=null;
      this.displayedColumns = ["planName", "startDate", "totalPrice", "paymentGateway", "paymentStatus", "paymentSuccessAt", "planStatus", "invoiceNo", "Actions"];
    }
  }

  back() {
    if(sessionStorage.getItem("userPurchaseHistoryForUserId"))
    {
    sessionStorage.removeItem('userPurchaseHistoryForUserId');
    sessionStorage.removeItem('userPurchaseHistoryForUserName');
    // this.userId=null;
    // this.userName="User Purchase History"
    this.router.navigate(['Admin/users'])
  }
  
}

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue=paginationData.filterValue;
      this.filterValueToDisplay=paginationData.filterValue;
    }

    if (this.isUserAllowedToViewBranches) {
      this.getBranches()
    }

    this.getUserPurchaseHistory()
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.onGetUserPurchaseHistory()
  }

  getBranches() {
    let data = {filter_by_branch_active_status: true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branches_list = resp.data.branches
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  getUserPurchaseHistory() {
    let filterByfromDateBE = this.filterByFromDate ? moment(new Date(this.filterByFromDate)).format("YYYY-MM-DD").toString(): null;
    let filterByToDateBE = this.filterByToDate ? moment(new Date(this.filterByToDate)).format("YYYY-MM-DD").toString(): null;
    let data: any = {
      offset: null,
      limit: null,
      filter_by_user_id: this.userId,
      filter_by_payment_from_date: filterByfromDateBE,
      filter_by_payment_to_date: filterByToDateBE,
      filter_by_payment_status: this.filterByPaymentStatus || null,
      filter_by_branch_id: this.filterByBranchId || null,
      filter_by_search_term: this.filterValue || null
    };
    if (!this.downloadInToExcel) {
      data['offset'] = this.bOffset
      data['limit']= this.bLimit
    }
    
    this.apiService.getUserPurchaseHistory(data).subscribe((response) => {
      if (response.status == "success") {
        if (!this.downloadInToExcel) {
          this.dataSource.data = response.data["user_orders"];
          this.totalCount = response.data.total_count;
        }
        else {
          let fileName = "PurchaseReport"
          if (this.filterByBranchId) {
            this.branches_list.forEach(element => {
              console.log("lement that we r expecting ", element)
              if (element['id'] == this.filterByBranchId) {
                fileName += "_" + element['name']
              }
            });
          }
          if (filterByfromDateBE) {
            fileName += "_" + filterByfromDateBE
          }
          if (filterByToDateBE) {
            fileName += "_" + filterByToDateBE
          }
          if (this.filterByPaymentStatus) {
            fileName += "_" + this.filterByPaymentStatus
          }

          fileName += '.' + 'xlsx'

          let purchaseHistoryDataForExcel = []
          let headerNames = ["User Name", "Email", "Mobile Number", "Plan Name", "Plan Type Name", "Plan Start Date", "Plan End Date", "Total Price Paid", "Payment Gateway", "Payment Status", "Payment Success At", "Plan Status", "Invoice No"]
          response.data["user_orders"].forEach(userOrder => {
            purchaseHistoryDataForExcel.push({
              "user_name": userOrder.user_name,
              "email": userOrder.email,
              "mobile_number": userOrder.mobile_number,
              "plan_name": userOrder.plan.name,
              "plan_type_name": userOrder.plan.plan_type.name,
              "plan_start_date": userOrder.plan_start_date ? moment(new Date(userOrder.plan_start_date)).format("DD-MM-YYYY").toString(): '',
              "plan_end_date": userOrder.plan_end_date ? moment(new Date(userOrder.plan_end_date)).format("DD-MM-YYYY").toString(): '',
              "total_price_paid": userOrder.total_price,
              "payment_gateway": userOrder?.payment_gateway?.split('_').join(' '),
              "payment_status": userOrder?.payment_status,
              "payment_success_at": userOrder.payment_success_at ? moment(new Date(userOrder.payment_success_at)).format("DD-MM-YYYY, hh:mm:ss A").toString(): '',
              "plan_status": userOrder?.plan_status,
              "invoice_no": userOrder?.invoice_no
            })

          })
          this.excelDownloadService.downloadExcelFile(purchaseHistoryDataForExcel, headerNames, fileName);
          this.downloadInToExcel = false
        }
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  getUserPlanSchedules(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue
    };
    sessionStorage.setItem('paginationDataForUserPurchaseHistory', JSON.stringify(paginationData));
    sessionStorage.setItem('getBackToFromSchedules', 'user-purchase-history')
    sessionStorage.setItem('userSchedulesUserId', row.user_id);
    sessionStorage.setItem('userSchedulesUserOrderPlanId', row.user_order_plan_id);
    sessionStorage.setItem('userSchedulesPlanTypeAlias', row.plan.plan_type.category);
    this.router.navigate(['Admin/user-schedules'])
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    this.getUserPurchaseHistory(); 
  }

  onGetUserPurchaseHistory(){
    this.pageIndex = 0;
    this.bOffset = 0;

    this.getUserPurchaseHistory()
  }

  onClickDownload() {
    this.downloadInToExcel = true
    this.getUserPurchaseHistory() 
  }

  viewWidth:any
  cancelPlanPage(row){
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }

    let dialogRef = this.dialog.open(CancelPlanComponent, {
      data: {
        data: {"userOrderPlanId": row.user_order_plan_id},
      },
      // height: auto,
      width: this.viewWidth,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.meta.dialogClosed) {
        let cancelledPlanObjIndex = this.dataSource.data.findIndex(rowData => rowData.user_order_plan_id == row.user_order_plan_id)
        this.dataSource.data[cancelledPlanObjIndex].plan_status = 'CANCEL'
      }
    });
  }

  cancelPlan(userOrderPlanId){

    let requestBody = {
      "user_order_plan_id": userOrderPlanId
    }

    this.apiService.cancelPlanAPI(requestBody).subscribe((response) => {
      if (response.status == "success") {
        let cancelledPlanObjIndex = this.dataSource.data.findIndex(rowData => rowData.user_order_plan_id == userOrderPlanId)
        this.dataSource.data[cancelledPlanObjIndex].plan_status = 'CANCEL'
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }


  getAvailableTrainersToUpdatePlanTrainer(row){

    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }

    let userOrderPlanId: string = row.user_order_plan_id

    let allowedTrainerObjs: trainerInterface[];
    this.apiService.getAvailableTrainersToUpdatePlanTrainerAPI(userOrderPlanId=userOrderPlanId).subscribe((response) => {
      if (response.status == "success") {
        allowedTrainerObjs = response.data.trainers;

        let dialogRef = this.dialog.open(ChangePlanTrainerComponent, {
          data: {
            "userOrderPlanId": userOrderPlanId,
            "allowedTrainerObjs": allowedTrainerObjs,
          },
          // height: auto,
          width: this.viewWidth,
        });

        dialogRef.afterClosed().subscribe(result => {
          if (this.meta.dialogClosed) { 
            // We can peform any action here on success
          }
        });
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

}
