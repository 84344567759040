import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import { viewUser } from 'app/meta.service';
import moment from 'moment';

@Component({
  selector: 'app-view-plan-sub-type',
  templateUrl: './view-plan-sub-type.component.html',
  styleUrls: ['./view-plan-sub-type.component.scss']
})

export class ViewPlanSubTypeComponent {
  planSubTypes = [];
  planBenefits=[];
 
  reportToUserNames = [];
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService, private datePipe: DatePipe
  ) { }
  ngOnInit() {
    this.getUserById();
  }
  getUserById() {
    this.planSubTypes = this.data.data;
    this.planSubTypes.forEach(element => {
      this.planBenefits = element.all_benefits_points;

    });
  }
}

