import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ViewUserLogsComponent } from '../view-user-logs/view-user-logs.component';
import moment from 'moment';
import { featureConstants } from 'app/meta.service';
import { ExcelDownloadService } from 'app/excel-download.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
const sampleData = [
  { Name: 'John Doe', Age: 30, Email: 'john.doe@example.com' },
  { Name: 'Jane Smith', Age: 25, Email: 'jane.smith@example.com' },
  { Name: 'Robert Johnson', Age: 35, Email: 'robert.johnson@example.com' },
];
export interface Element {
  BranchName: string;
  Location: string;
  Number: string;
  viewUser: number;
  IsRemoved: boolean;
  CreatedBy: string;
}
@Component({
  selector: 'app-view-user-attendance',
  templateUrl: './view-user-attendance.component.html',
  styleUrls: ['./view-user-attendance.component.scss'],
})
export class ViewUserAttendanceComponent {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['UserName', 'DutyTime', 'OverTime', 'ActiveTime', 'arrivalStatuses', 'aboutDay','branch', 'Actions'];
  dataSource = new MatTableDataSource<Element>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  user_id: string;
  basicDetails = [];
  newDate: any;
  minDate: any;
  userBranch = [];
  userBranchNames = [];
  userActiveBranchesList = [];
  userActiveBranchNames = [];
  userPreviousBranchNames = [];
  attendance_log_statistics: string;
  MyDateString: string;
  fromDate: any;
  toDate: any;
  total_week_off_days: string;
  total_working_days: string;
  firstdate: any;
  today: any;

  pageSize = 10;
  pageIndex = 0;
  totalCount = 0;
  bLimit = 10;
  bOffset = 0;

  roleType = [
    { value: 'current', viewValue: 'Current Date' },
    { value: 'currentMonth', viewValue: 'Current Month' },
    { value: 'custom', viewValue: 'Custom' },

  ]
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewAttendanceLog = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ATTENDANCE_LOGS) > -1
  // isUserAllowedToCreateAttendanceLog = this.userAllowedFeatures.indexOf(featureConstants.CREATE_ATTENDANCE_LOGS) > -1
  fromStartDate:any;
  lastDate:any;
  branch:string;
  all_branches_list = [];
  arrivalStatuses = []
  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute,private excelDownloadService: ExcelDownloadService, public apiService: ApiService, private toastr: ToastrService) {
    // this.route.params.subscribe((params) => {
    //   this.user_id = params['user_id'];
    // });
    this.user_id = sessionStorage.getItem('userAttendanceForUserId')
  }

  ngOnInit() {
    
    this.newDate = new Date();
    this.newDate = moment(new Date(this.newDate)).format("YYYY-MM-DD").toString();
    this.today = new Date();
    this.today.setDate(this.today.getDate())
    var firstDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
    this.firstdate = moment(new Date(firstDay)).format("YYYY-MM-DD").toString();
    // console.log(this.firstdate,this.newDate)
    this.fromDate=this.firstdate;
    this.toDate=this.newDate
    this.fromStartDate = this.firstdate;
   
    this.today = moment(new Date(this.today)).format("YYYY-MM-DD").toString();
    this.lastDate = this.today;
    // this.getUserAttendanceById();
    // Making these default as they should be called as default
    this.branch = ''
    this.firstdate = this.newDate;
    this.today = this.newDate;
    this.isCustom = false;
    
    this.getUserBranches();
  }
  getUserBranches() {
    this.apiService.getUsersBranches(this.user_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.all_branches_list = resp.data.branches;
        this.getUserAttendanceStats();
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  
  isCustom: boolean = false;
  statusValue:string = "current";
  filterActivity(event) {
    if (event.value == "currentMonth") {
      this.firstdate = this.fromStartDate;
      this.today = this.newDate;
      this.getUserAttendanceStats();
      this.isCustom = false;
    } 
    // else if (event.value == "current") {
    //   this.firstdate = this.newDate;
    //   this.today = this.newDate;
    //   this.isCustom = false;
    //   this.getMonthDetails();
    // } else {

    //   this.isCustom = true;
    // }
  }
  downloadExcel(): void {
    // this.excelDownloadService.downloadExcelFile(sampleData);
  }
  reset() {
    this.firstdate = this.newDate;
    this.today = this.newDate;
    this.fromDate = ""
    this.toDate = ""
    this.statusValue = "current"
    this.isCustom = false;
    this.userActiveBranchNames = [];
    this.userPreviousBranchNames = [];
    this.getUserAttendanceStats();
  }
  
  getUserAttendanceStats() {
    // console.log("this.firstdate-", this.firstdate)
    // console.log("this.today-", this.today)
    this.fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString();
    this.toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString();
    let queryParams = {
      from_date: this.fromDate || null,
      to_date: this.toDate || null,
      limit: this.bLimit,
      offset: this.bOffset
    }
    if (this.branch != '' || this.branch != null) {
      queryParams['filter_by_branch_id'] = this.branch
    }
    
    this.apiService.getUserAttendanceById(queryParams, this.user_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.basicDetails = []
        const timeCal = resp.data.log_date_active_times_with_count.log_date_active_times
        timeCal.forEach(element => {
          element.active_time_in_seconds = this.secondsToHms(element.active_time_in_seconds)
          element.duty_time_in_seconds = this.secondsToHms(element.duty_time_in_seconds)
          element.over_time_in_seconds = this.secondsToHms(element.over_time_in_seconds)
        });
        this.dataSource.data = resp.data.log_date_active_times_with_count.log_date_active_times;
        this.totalCount = resp.data.log_date_active_times_with_count.total_log_dates_count;

        this.attendance_log_statistics = this.secondsToHms(resp.data.attendance_log_statistics.total_active_time_in_seconds)
        this.total_week_off_days = resp.data.attendance_log_statistics.total_week_off_days;
        this.total_working_days = resp.data.attendance_log_statistics.total_working_days;
        this.basicDetails.push(resp.data);

        this.userActiveBranchNames = [];
        resp.data.branches.forEach(activeBranch => { 
          this.userActiveBranchNames.push(activeBranch.name)
        })

        this.userPreviousBranchNames = [];
        this.all_branches_list.forEach(userBranch => {
          if (this.userActiveBranchNames.indexOf(userBranch.name) == -1) {
            this.userPreviousBranchNames.push(userBranch.name)
          }
        })
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    }
    )
  }
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
     
    return hDisplay + mDisplay + sDisplay;
   
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
  back() {
    sessionStorage.removeItem('userAttendanceForUserId')
    this.router.navigateByUrl('Admin/users');
  }
  editUserLog(data) {
    // this.router.navigateByUrl('Admin/create-user-attendance');
    this.router.navigate(['Admin/create-user-attendance', { user_id: this.user_id, log_date: data.log_date }]);

    // let dialogRef = this.dialog.open(CreateLogsComponent, {
    //   data: {
    //     data: data,
    //   },
    //   height: '90%',
    //   width: '50%',
    // });
  }
  viewUserLog(row) {
    let dialogRef = this.dialog.open(ViewUserLogsComponent, {
      data: {
        Data: row,
        user_id: this.user_id
      },
      // height: '90%',
      width: '50%',
    });
  }

  addEvent(event) {
    this.fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString();
    this.toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString();
    // console.log(this.fromDate,this.toDate)
    if (this.fromDate != "invalid Date" && this.toDate != "invalid Date") {
      let queryParams = {
        from_date: this.fromDate || null,
        to_date: this.toDate || null
      }
      this.apiService.getUserAttendanceById(queryParams, this.user_id).subscribe((resp) => {
        const timeCal = resp.data.log_date_active_times_with_count.log_date_active_times
        timeCal.forEach(element => {
          element.active_time_in_seconds = this.secondsToHms(element.active_time_in_seconds)
        });

        // Combining the list of arrival statuses into one string
        resp.data.log_date_active_times_with_count.log_date_active_times.forEach(each_row => {
          let arrival_statues_list = []
          each_row["arrival_statuses"].forEach(each_status => {

            let partial_status_list = []
            each_status.split('_').forEach(partial_status => {
              partial_status_list.push(partial_status[0] + partial_status.toLowerCase().substring(1))
            })
            arrival_statues_list.push(partial_status_list.join(' '))
          })
          each_row.arrival_statuses = arrival_statues_list.join(', ')
        })

        this.dataSource.data = resp.data.log_date_active_times_with_count.log_date_active_times;
        // this.attendance_log_statistics = resp.data.attendance_log_statistics.total_active_time_in_seconds;
        this.attendance_log_statistics = this.secondsToHms(resp.data.attendance_log_statistics.total_active_time_in_seconds)
        this.total_week_off_days = resp.data.attendance_log_statistics.total_week_off_days;
        this.total_working_days = resp.data.attendance_log_statistics.total_working_days;

        // this.basicDetails.push(resp.data) 
      }, (err) => {
        this.toastr.error(err.error.message)
      })
    }
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    this.getUserAttendanceStats(); 
  }

  onGetUserAttendanceStats(){
    this.bOffset=0;
    this.pageIndex=0;
    this.getUserAttendanceStats()
  }

  
}
