<div class="container-fluid">
    <div class="row">

        <div class="row mt-8 justify-content-between">
            <div class="col-sm-12 col-lg-8 mt-8 d-flex flex-column flex-sm-row">
                <h1 class="h3 ml-1 mr-6 heading">{{title}}</h1>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end">
                <button class="addBtn m-1" style="width: 170px" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                  (click)="onClickDownload()">
                  <mat-icon class="white-icon" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                  <span class="ml-2 mr-1 txt" style="color: white">Track Report</span>
                </button>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" *ngIf="isUserAllowedToViewBranches">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select [placeholder]="'All Branches'" [(ngModel)]="filterByBranchId" (selectionChange)="onGetPlanExpiryRenewalTracks()">
                    <mat-option value="">All Branches</mat-option>
                    <mat-option *ngFor="let branch of branchesList" [value]="branch.id">{{branch.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select  [(ngModel)]="filterByExpiryTrackStatus"  [placeholder]="'Expiry Track Status'"(selectionChange)="onGetPlanExpiryRenewalTracks()">
                    <mat-option *ngFor="let trackStatus of expiryTrackStatuses" [value]="trackStatus.value">{{trackStatus.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select  [(ngModel)]="filterByRenewalTrackStatus"  [placeholder]="'Renewal Track Status'"(selectionChange)="onGetPlanExpiryRenewalTracks()">
                    <mat-option *ngFor="let trackStatus of renewalTrackStatuses" [value]="trackStatus.value">{{trackStatus.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" >
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                    <input matInput readonly [matDatepicker]="picker" [(ngModel)]="filterByFromDate"
                        [placeholder]="'Expiry From Date'" (dateChange)="onGetPlanExpiryRenewalTracks()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" >
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                    <input matInput readonly [matDatepicker]="picker1" [(ngModel)]="filterByToDate"
                        [placeholder]="'Expiry To Date'" (dateChange)="onGetPlanExpiryRenewalTracks()">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;" [subscriptSizing]="'dynamic'">
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <input
                        matInput
                        (keyup)="applyFilter($event)"
                        [autocomplete]="'off'"
                        [placeholder]="'Search User Name'"
                        [(ngModel)]="filterBySearchTerm"
                    />
                </mat-form-field>
            </div>
        </div>
    
    <div class="mat-elevation-z8 mt-3 overflow-auto">
       
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="userName" >
                <th mat-header-cell *matHeaderCellDef>User Name</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.user.name}}({{row.user.mobileNumber}}) </td>
            </ng-container>
            <ng-container matColumnDef="expiryPlanName" >
                <th mat-header-cell *matHeaderCellDef>Expiry Plan<span>(Duration)</span><span>(Branch)</span></th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.expiryPlan.planName}} 
                    <span>({{row.expiryPlan.planDuration}})</span> 
                    <span>({{row.expiryPlan.planBranch}})</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="expiryPlanDates">
                <th mat-header-cell *matHeaderCellDef >Expiry Plan Start/End Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.expiryPlan.PlanStartDate | dateFormat}}<span > to {{row.expiryPlan.PlanEndDate | dateFormat}}</span> </td>
            </ng-container>
            <ng-container matColumnDef="expiryPlanStatus">
                <th mat-header-cell *matHeaderCellDef >Expiry Plan Status</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.expiryPlan.planStatus.split('_').join(' ') | titlecase}}</td>
            </ng-container>
            <ng-container matColumnDef="expiryPlanTrackStatus">
                <th mat-header-cell *matHeaderCellDef >Expiry Plan Track Status</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.expiryPlanTrackStatus.split('_').join(' ') | titlecase}}</td>
            </ng-container>
            <ng-container matColumnDef="divider">
                <th mat-header-cell *matHeaderCellDef >||</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> <b>||</b> </td>
            </ng-container>
            <ng-container matColumnDef="renewalPlanName" >
                <th mat-header-cell *matHeaderCellDef>Renewal Plan<span>(Duration)</span><span>(Branch)</span></th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.renewalPlan.planName}}
                    <span *ngIf="row.renewalPlan.planDuration">({{row.renewalPlan.planDuration}})</span>
                    <span *ngIf="row.renewalPlan.planBranch">({{row.renewalPlan.planBranch}})</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="renewalPlanDates">
                <th mat-header-cell *matHeaderCellDef >Renewal Plan Start/End Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.renewalPlan.PlanStartDate | dateFormat}}<span *ngIf="row.renewalPlan.PlanEndDate"> to {{row.renewalPlan.PlanEndDate | dateFormat}}</span> </td>
            </ng-container>
            <ng-container matColumnDef="renewalPlanStatus">
                <th mat-header-cell *matHeaderCellDef >Renewal Plan Status</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.renewalPlan.planStatus.split('_').join(' ') | titlecase}}</td>
            </ng-container>
            <ng-container matColumnDef="renewalPlanTrackStatus">
                <th mat-header-cell *matHeaderCellDef >Renewal Plan Track Status</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.renewalPlanTrackStatus.split('_').join(' ') | titlecase}}</td>
            </ng-container>
            
            <!-- <ng-container matColumnDef="Actions" >
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                  <button mat-button [matMenuTriggerFor]="menu" [disabled]="row.is_removed">
                    <mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToViewSchedules && row?.payment_status === 'SUCCESS'" (click)="getUserPlanSchedules(row)">View Plan Schedules</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToCancelPlans && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="cancelPlanPage(row)">Cancel Plan</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUpdatePlanTrainer && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="getAvailableTrainersToUpdatePlanTrainer(row)">Update Trainer</button>
                  </mat-menu>
                </td>
              </ng-container> -->
              

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>