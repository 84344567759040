import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService, roles, featureConstants } from 'app/meta.service';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';

import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { OtpConformationComponent } from 'app/pages/Users/otp-conformation/otp-conformation.component';
import { FileUploadManagerComponent } from 'app/pages/commonComponents/file-upload-manager/file-upload-manager.component';
import { environment } from 'environments/environment';



const moment = _moment;
export interface State {
  id: string;
  name: string;
}
export interface cities {
  id: string;
  name: string;
}

@Component({
  selector: 'app-create-leave',
  templateUrl: './create-leave.component.html',
  styleUrls: ['./create-leave.component.scss'],
})
export class CreateLeaveComponent {

  @ViewChild('fileUploadComponent', { static: false }) fileUploadComponent: FileUploadManagerComponent;

  handleStartingSpaceTextInput = handleStartingSpaceTextInput
  
  leavePageTitle = "Create Leave"
  isEditLeave = false

  minStartDate: any;
  fromDate: any;
  toDate:any
  description;
  title;
  selectedLeavetypeValue = null;
  LeaveType = [
    // { value: '', viewValue: 'All' },
    { value: 'CASUAL_LEAVE', viewValue: 'Casual Leave' },
    { value: 'SICK_LEAVE', viewValue: 'Sick Leave' }
  ]

  leave_id = null;
  leaveData = null

  branchesList = [({"id": null, "name": "All Branches"})]
  selected_branch_id = null;
  
  roleList = [({ "id": null, "name": "All Roles" })]
  selected_role_id = null;

  userList = [({ "id": null, "name": "Select User" })]
  selectedUserId = null

  constructor(private router: Router, private meta: MetaService, public dialog: MatDialog, private route: ActivatedRoute, public apiService: ApiService, public _authService: AuthService, private datePipe: DatePipe, private _formBuilder: FormBuilder, private toastr: ToastrService,) {
    if (sessionStorage.getItem('editLeaveId')) {
      this.leave_id = sessionStorage.getItem('editLeaveId')
      this.isEditLeave = true
      
    }
  }
  ngOnInit() {

    this.minStartDate = moment(new Date(new Date())).format("YYYY-MM-DD").toString();

    if (this.isEditLeave) {
      this.leavePageTitle = "Edit Leave"
      this.getLeave()
    }
    else {
      this.getRoles();
      this.getBranches();
      this.getUsers();
    }

  }

  back() {
    if (this.isEditLeave) {
      sessionStorage.removeItem('editLeaveId')
      this.router.navigateByUrl('Admin/user-leaves');
    }
    else {
      this.router.navigateByUrl('Admin/leaves');
    }
  }

  getRoles() {
    let data = { "filter_by_role_active_status": true }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleList.push(...resp.data.roles)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
  getBranches() {
    let data = { "filter_by_branch_active_status": true }
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchesList.push(...resp.data.branches)
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }
getUsers() {
this.selectedUserId = null
    let filter_by_branch_ids = []
    let filter_by_role_ids = []
    if (this.selected_branch_id) {
      filter_by_branch_ids.push(this.selected_branch_id)
    }
    if (this.selected_role_id) {
      filter_by_role_ids.push(this.selected_role_id)
    }
    let data = {
      "filter_by_branch_ids": filter_by_branch_ids,
      "filter_by_role_ids": filter_by_role_ids
    }
    this.apiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userList = resp.data.users
      }
    })
}
  
getLeave() {
  this.apiService.getLeaveById(this.leave_id).subscribe((resp) => {
    if (resp.status == "success") {
      this.leaveData = resp.data.leave
      this.userList = [resp.data.leave.user]
      this.selectedUserId = resp.data.leave.user.id

      this.title = resp.data.leave.title
      this.description = resp.data.leave.description

      this.selectedLeavetypeValue = resp.data.leave.leave_dates[0].leave_type
      // this.fromDate = new Date(resp.data.leave.leave_dates[0].start_date)
      // this.toDate = new Date(resp.data.leave.leave_dates[0].end_date)
      this.fromDate = moment(new Date(resp.data.leave.leave_dates[0].start_date)).format("YYYY-MM-DD").toString()
      this.toDate = moment(new Date(resp.data.leave.leave_dates[0].end_date)).format("YYYY-MM-DD").toString()
      this.minStartDate = this.fromDate

      this.fileUploadComponent.existingFileData = resp.data.leave.file_name_urls.map((ele) => {
        return {
          fileName: ele.filename,
          fileBaseUrl: ele.file_url,
          fileUrl: environment.apiUrl + ele.file_url
        }
      })
      this.fileUploadComponent.actualexistingFilesCount = this.fileUploadComponent.existingFileData.length
    }
  },
    (err) => {
      this.toastr.error(err.error.message)
    })
}
  
  
createLeave() {
  this.fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString();
  this.toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString();
  let requestData = {
    user_id: this.selectedUserId,
    title: this.title,
    description: this.description,
    leave_dates: {
          start_date: this.fromDate,
          end_date: this.toDate,
          leave_type: this.selectedLeavetypeValue,
    },
  };

  this.apiService.createLeave(requestData).subscribe((resp) => {
    if (resp.status == "success") {
      // See how to make it out common in both create and update branch
      // Uploading Files
      this.leave_id = resp.data.leave.id
      if (this.fileUploadComponent.files.length || this.fileUploadComponent.existingFileData) {          
        if (this.fileUploadComponent.files.length) {
          let fileUploadRequest = this.fileUploadComponent.uploadFiles("leaves/" + this.leave_id)
          fileUploadRequest.subscribe((resp) => {
            // Successfully saved all files
            // And Started updating uploaded files in branch files
            let fileUrls = resp["file_urls"]
            this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
            let payload = { "file_urls": fileUrls }
            this.apiService.updateLeaveFiles(payload, this.leave_id).subscribe((resp) => {
              if (resp.status == "success") {
                // Successfully updating uploaded files in branch files
                this.router.navigateByUrl('Admin/leaves');
              }
            }, (err) => {
              this.toastr.error(err.error.message)
            })
          }, (err) => {
            this.toastr.error("Failed to upload Files");
          })
        }
        else {
          // can update only if any files are removed
          if (this.fileUploadComponent.actualexistingFilesCount != this.fileUploadComponent.existingFileData.length) {
            let fileUrls = []
            this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
            let payload = { "file_urls": fileUrls }
            this.apiService.updateLeaveFiles(payload, this.leave_id).subscribe((resp) => {
              if (resp.status == "success") {
                // Successfully updating uploaded files in branch files
                this.router.navigateByUrl('Admin/leaves');
              }
            }, (err) => {
              this.toastr.error(err.error.message)
            })
          }
          else {this.router.navigateByUrl('Admin/leaves');}
        }
      }
      else{this.router.navigateByUrl('Admin/leaves');}
    }
  },
    (err) => {
      this.toastr.error(err.error.message);
    }
  );
}

  updateLeave() {
    
    this.fromDate = moment(new Date(this.fromDate)).format("YYYY-MM-DD").toString();
    this.toDate = moment(new Date(this.toDate)).format("YYYY-MM-DD").toString();
    let requestData = {
      user_id: this.selectedUserId,
      title: this.title,
      description: this.description,
      leave_dates: {
        id: this.leaveData.leave_dates[0].id,
        start_date: this.fromDate,
        end_date: this.toDate,
        leave_type: this.selectedLeavetypeValue,
      },
    };

    this.apiService.updateLeave(requestData, this.leave_id).subscribe((resp) => {
      if (resp.status == "success") {
        // See how to make it out common in both create and update branch
        // Uploading Files
        if (this.fileUploadComponent.files.length || this.fileUploadComponent.existingFileData) {          
          if (this.fileUploadComponent.files.length) {
            let fileUploadRequest = this.fileUploadComponent.uploadFiles("leaves/" + this.leave_id)
            fileUploadRequest.subscribe((resp) => {
              // Successfully saved all files
              // And Started updating uploaded files in branch files
              let fileUrls = resp["file_urls"]
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateLeaveFiles(payload, this.leave_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/user-leaves');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }, (err) => {
              this.toastr.error("Failed to upload Files");
            })
          }
          else {
            // can update only if any files are removed
            if (this.fileUploadComponent.actualexistingFilesCount != this.fileUploadComponent.existingFileData.length) {
              let fileUrls = []
              this.fileUploadComponent.existingFileData.map(ele => fileUrls.push(ele.fileBaseUrl))
              let payload = { "file_urls": fileUrls }
              this.apiService.updateLeaveFiles(payload, this.leave_id).subscribe((resp) => {
                if (resp.status == "success") {
                  // Successfully updating uploaded files in branch files
                  this.router.navigateByUrl('Admin/user-leaves');
                }
              }, (err) => {
                this.toastr.error(err.error.message)
              })
            }
            else {this.router.navigateByUrl('Admin/user-leaves');}
          }
        }
        else{this.router.navigateByUrl('Admin/user-leaves');}
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })

  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.title = null;
    this.description = null;
    this.selectedLeavetypeValue = null;

    this.selectedUserId = null;
    this.selected_branch_id = null;
    this.selected_role_id = null;

    this.fileUploadComponent.reset()
  }

}
