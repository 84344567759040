import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss']
})
export class CreateDeviceComponent {
  
  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  public basicDetailsForm: FormGroup;
  device_id:string;
  branches_list=[];
  isUpdate:boolean =false;
  actionItem: string = "CREATE DEVICE"
  selectedBranch = {}
  
  attendanceDeviceTypes = [
    {value: 'STEELGYM_QR_SCANNER', viewValue: 'Steelgym Qr Scanner'},
    {value: 'SPINTLY', viewValue: 'Spintly'},
  ]

  constructor( private _formBuilder: FormBuilder,private router: Router,public apiService: ApiService,private toastr: ToastrService,private route: ActivatedRoute){
    this.buildForm();
    // this.route.params.subscribe((params) => {
    //   this.device_id = params['device_id'];
    // });

    this.device_id = sessionStorage.getItem('editDeviceId')
  }
  ngOnInit(){
    this.getBranches();
    if(this.device_id){
      this.getDeviceById();
      this.actionItem="EDIT DEVICE";
      this.isUpdate =true
    }
  }
  
  public buildForm() {
    this.basicDetailsForm = this._formBuilder.group({
      description: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      attendance_device_id: new FormControl(null, [Validators.required]),
      attendance_device_type: new FormControl('STEELGYM_QR_SCANNER', [Validators.required]),
      branchId: [null, [Validators.required]],
      branchName:('')
    });
    this.basicDetailsForm.get('attendance_device_type').disable()
  }
  getDeviceById(){
    this.apiService.getDeviceById(this.device_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.basicDetailsForm.controls.name.setValue(resp.data.attendance_device.name);
        this.basicDetailsForm.controls.description.setValue(resp.data.attendance_device.description);
        this.basicDetailsForm.controls.branchId.setValue(resp.data.attendance_device.branch.id);
        this.basicDetailsForm.controls.attendance_device_id.setValue(resp.data.attendance_device.attendance_device_id);
        this.basicDetailsForm.controls.attendance_device_type.setValue(resp.data.attendance_device.attendance_device_type);
        this.basicDetailsForm.controls.branchName.setValue(resp.data.attendance_device.branch.name)
        this.selectedBranch = resp.data.attendance_device.branch
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    })
  }
  getBranches() {
    let data = {"filter_by_branch_active_status": true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branches_list = resp.data.branches
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  resetForm(){
    this.buildForm()
  }
  back(){
    this.router.navigateByUrl('Admin/devices');
  }
  keyPress(event: any) {
    const pattern = /[a-zA-z0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  createDevice(){
    if(this.basicDetailsForm.valid){
      let payload={
        branch_id:this.basicDetailsForm.controls.branchId.value,
        name:this.basicDetailsForm.controls.name.value,
        description:this.basicDetailsForm.controls.description.value,
        attendance_device_id: this.basicDetailsForm.controls.attendance_device_id.value,
        attendance_device_type:this.basicDetailsForm.controls.attendance_device_type.value
      }
      this.apiService.createDevice(payload).subscribe((resp)=>{
        if(resp.status =="success"){
          this.router.navigateByUrl('Admin/devices');
        }
      },
      (err) => {
        this.toastr.error(err.error.message)
      })
    }
  }
  updateDevice(){
    let payload={
      name:this.basicDetailsForm.controls.name.value,
      description:this.basicDetailsForm.controls.description.value,
    }
    this.apiService.updateDevice(payload,this.device_id).subscribe((resp)=>{
      if(resp.status =="success"){
        this.router.navigateByUrl('Admin/devices');
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
}
