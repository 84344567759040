<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard" >

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>{{title}}</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <div class="card " style="border: none">
        <div class="row  mr-3 ml-3 mt-3 justify-center">
            <div class="col-md-6">
                <div class="fw-bold fieldName">
                    Alias
                    <span class="text-danger">*</span>
                    
                </div>
                <div class="">
                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;">
                        <mat-select [placeholder]="'Select Plan Type'" [(ngModel)]="selectedAlias" id="Alias" name="Alias" required #Alias="ngModel">
                            <mat-option *ngFor="let alias of ALIAS"
                                [value]="alias.value">{{alias.viewValue}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="Alias.invalid && Alias.touched && Alias.errors.required">Alias is required</mat-error>
                    </mat-form-field>
                  </div>
            </div>
            <div class="col-md-6">
                <div class="fw-bold fieldName">
                    Tag Name
                    <span class="text-danger">*</span>
                    <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(50))</span>
                </div>
                <div class="">
                    <mat-form-field class="w-full">
                      <input id="name" style="background: #FFFFFF;" matInput placeholder="Tag Name"
                        [(ngModel)]="name" id="nameId" name="nameId" #nameId="ngModel" required 
                        pattern="^[a-zA-Z][\w\s]*$" 
                        (keypress)="handleStartingSpaceTextInput($event, name)" maxlength="50"
                        >
                        <mat-error *ngIf="nameId.invalid && nameId.touched && nameId.errors.required">Tag Name is required</mat-error>
                        <mat-error *ngIf="nameId.invalid && nameId.touched && nameId.errors.pattern">Tag Name has invalid characters</mat-error>
                    </mat-form-field>
                  </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="mt-6">
                <div class=" d-flex justify-content-center">

                    <button *ngIf="!isUpdate" class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="createTag()"
                    [disabled]="!nameId || nameId.invalid || !selectedAlias || selectedAlias.invalid" [ngClass]="{'btn-clr': !(!nameId || nameId.invalid || !selectedAlias || selectedAlias.invalid)}">
                        <span class="ml-2 mr-1 txt" style="color: white">Create</span>
                    </button>
                    <button *ngIf="isUpdate" class="ml-4 addBtn" mat-flat-button [color]="'primary'" (click)="updateTag()"
                    [disabled]="!nameId || nameId.invalid || !selectedAlias || selectedAlias.invalid" [ngClass]="{'btn-clr': !(!nameId || nameId.invalid || !selectedAlias || selectedAlias.invalid)}">
                    <span class="ml-2 mr-1 txt" style="color: white">Update</span>
                </button>
                </div>
            </div>
        </div>
        
    </div>
    
   
</div>
