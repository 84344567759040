import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import moment from 'moment';

@Component({
  selector: 'app-view-user-logs',
  templateUrl: './view-user-logs.component.html',
  styleUrls: ['./view-user-logs.component.scss']
})
export class ViewUserLogsComponent {
  fullName:string;
  role:string;
  newDate: any;
  myDate = new Date();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public apiService: ApiService,private datePipe: DatePipe,){
    
  }
  ngOnInit(){
    this.getUserLogsById()
  }
  attendance_logs=[]
  getUserLogsById(){
    let log_date = this.data.Data.log_date
    let branchId = this.data.Data.branch?.id || null
    this.apiService.getUserLogsById(log_date,this.data.user_id, branchId).subscribe((resp)=>{
      if(resp.status == 'success'){
        // console.log(resp.data)
        this.fullName =resp.data.user_attendance_log.full_name;
        this.role=resp.data.user_attendance_log.role.name
        this.attendance_logs=resp.data.user_attendance_log.attendance_logs;
      // this.attendance_logs=this.attendance_logs.map(element => {
      //   console.log(element)
      //     element.check_in_date_time=moment(new Date(element.check_in_date_time)).format("hh:mm A").toString();
      //     element.check_out_date_time=moment(new Date(element.check_out_date_time)).format("hh:mm A").toString();

      //     // return({
      //     //   check_in_date_time: element.check_in ? {...element.check_in, log_date_time: this.convertToIst(element.check_in.log_date_time)} : null,
      //     //   check_out: element.check_out ? {...element.check_out, log_date_time: this.convertToIst(element.check_out.log_date_time)} : null,
      //     // })
      //   });
      }
    })
  }
  convertToIst(date: any) {
    if(date != null){
      var mainDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      var time =this.datePipe.transform(date, 'hh:mm:ss');
      date = new Date(mainDate + "T" + time + "Z");
      var endTime = moment(new Date(date)).format("hh:mm A").toString();
      // console.log(endTime)
      return endTime
    }else{
      return null
    }
   
  }
}
