import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-user-accesses',
  templateUrl: './edit-user-accesses.component.html',
  styleUrls: ['./edit-user-accesses.component.scss']
})
export class EditUserAccessesComponent {

  @ViewChild(MatAccordion) accordion: MatAccordion;

  webFeaturesList = [];
  mobileFeaturesList = [];
  selectedfeaturesList = [];
  featuresList: [];
  heading = [];
  headingArr = [];
  dataArr = [];

  userId: string;
  title: string = ''

  constructor(
    private router: Router, 
    public apiService: ApiService, 
    private route: ActivatedRoute, 
    private toastr:ToastrService
  ) {

    this.userId = sessionStorage.getItem('editUserAccessesForUserId')
    this.title = sessionStorage.getItem('editUserAccessesForUserName')

  }

  ngOnInit() {
    this.getUserAccessesByID();
  }

  back() {
    this.router.navigateByUrl('Admin/users');
  }

  getUserAccessesByID(){
    this.headingArr = [];
    this.dataArr = [];
    this.heading = [];

    this.apiService.getUserAccessesWithAllAccessesByUserId(this.userId).subscribe((resp) => {
      if (resp.status == "success") {
        this.selectedfeaturesList = resp.data.features;
        this.heading = Object.entries(this.selectedfeaturesList);
        this.heading.forEach(element => {
          this.headingArr.push(element[0])
          this.dataArr.push(element[1])
        });
        this.dataArr.forEach(element => {
          element.forEach(ele => {
            if (ele.is_checked_on_web_app == true) {
              this.webFeaturesList.push(ele.alias)
            }
            if (ele.is_checked_on_mobile_app == true) {
              this.mobileFeaturesList.push(ele.alias)
            }
          });
        });
      }
    },
    (err) => {
      this.toastr.error(err.error.message);
    });
  }

  getFeatures() {
    this.apiService.getFeatures().subscribe((resp) => {
      this.featuresList = resp.data.features;
      this.heading = Object.entries(this.featuresList);
      this.heading.forEach(element => {
        this.headingArr.push(element[0]) 
        this.dataArr.push(element[1])
      });    
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }
  selectedWed(name, event) {
  
    if (event.checked == true) {
      this.webFeaturesList.push(name);
    } else {
      this.webFeaturesList = this.webFeaturesList.filter(each => each != name)
    }
  }

  selectedMobile(name, event) {
   
    if (event.checked == true) {
      this.mobileFeaturesList.push(name);
    } else {
      this.mobileFeaturesList = this.mobileFeaturesList.filter(each => each != name)
    }
  }

  updateUserAccesses(){
    const payload: any = {}
    
    payload['features'] = [
      {
        device_type : "WEB_APP",
        allowed_features:this.webFeaturesList
      },
      {
        device_type : "MOBILE_APP",
        allowed_features:this.mobileFeaturesList
      }
    ]

    this.apiService.updateUserAccessesByUserId(this.userId, payload).subscribe((resp) => {
      if(resp.status == "success"){
        this.router.navigateByUrl('Admin/users');
      }
   }, (err) => {
    this.toastr.error(err.error.message)
  })
}

}
