import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService, roles, featureConstants } from 'app/meta.service';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { elementAt, map, startWith } from 'rxjs/operators';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { OtpConformationComponent } from 'app/pages/Users/otp-conformation/otp-conformation.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

@Component({
  selector: 'app-create-add-on-plan',
  templateUrl: './create-add-on-plan.component.html',
  styleUrls: ['./create-add-on-plan.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})


export class CreateAddOnPlanComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  @Output()
  change: EventEmitter<MatCheckboxChange>
  planName = null;
  planHierarchy = null;
  planSuitableForCustomers = null;

  PlanTypeList = [];
  PlanSubTypeList = [];
  selectedPlanTypeId = null;
  selectedPlanSubTypeId = null;

  selectedTrainerRoleIds = [];
  days: any = [];
  allComplete: boolean = false

  roleList = [];
  AddOnDetails=[];

  planDuration = null;
  planPrice = null;
  sgstTaxPrice = null;
  cgstTaxPrice = null;
  igstTaxPrice = null;

  planDurations = [];

  allBenefitPoints = [];
  selectedBenefitPoints = [];

  noOfSlotsPerMonth = null;
  Slot_Booking_days = [];
  selectedDay = [];

  canBookStots: Boolean = false;
  DaysList = [
    { value: 'SUN', viewValue: 'Sunday' },
    { value: 'MON', viewValue: 'Monday' },
    { value: 'TUE', viewValue: 'Tuesday' },
    { value: 'WED', viewValue: 'Wednesday' },
    { value: 'THU', viewValue: 'Thursday' },
    { value: 'FRI', viewValue: 'Friday' },
    { value: 'SAT', viewValue: 'Saturday' }

  ]

  addon_plan_id: string;
  isUpdate: boolean = false;
  title: string = 'CREATE ADD ON';

  constructor(
    private router: Router,
    private meta: MetaService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public apiService: ApiService,
    public _authService: AuthService,
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    // this.route.params.subscribe((params) => {
    //   this.addon_plan_id = params['addon_plan_id'];
    // });

    this.addon_plan_id = sessionStorage.getItem('editAddonPlanId')
  }

  ngOnInit() {
    this.getPlanType();
    this.getRoles();

    this.buildForm();
    if (this.addon_plan_id  != null) {
      this.getAddOnById();
      this.isUpdate = true;
      this.title = "Edit ADD ON"
     
    } else {
     
    }
  }

  public buildForm() {
    let name = null
    if(this.addon_plan_id ){
    }
  }

  getAddOnById() {
    this.AddOnDetails = [];
    this.apiService.getAddOnPlanById(this.addon_plan_id ).subscribe((resp) => {
      if (resp.status == "success") {
        this.AddOnDetails.push(resp.data.plan);
        this.planHierarchy = resp.data.plan.plan_hierarchy;
        this.canBookStots = resp.data.plan.can_book_slots;
        this.planName = resp.data.plan.name;
        this.noOfSlotsPerMonth = resp.data.plan.no_of_slots_per_month;
        resp.data.plan.plan_durations.map(elem => {
          if (!elem.is_removed) {
            this.planDurations.push({
              planDuration: elem["validity_in_months"],
              planPrice: elem["price"],
              sgstTaxPrice: elem["sgst_tax_price"],
              cgstTaxPrice: elem["cgst_tax_price"],
              igstTaxPrice: elem["igst_tax_price"],
            })
          }
        });
        this.selectedPlanTypeId = resp.data.plan.plan_type.id
        this.selectedTrainerRoleIds = resp.data.plan.schedule_role_ids
        this.allBenefitPoints = [...resp.data.plan.benefits]
        this.selectedBenefitPoints = this.allBenefitPoints
        // this.roleList.push(resp.data.plan.schedule_roles)
        // console.log("fhdcfgcfg",this.roleList)
        this.selectedTrainerRoleIds = resp.data.plan.schedule_roles.map((each) => each.id)
        this.DaysList.forEach((eachDay) => {
          if (resp.data.plan.slot_booking_days.includes(eachDay.value)){
            this.days.push(eachDay.value)
          }
        })
        if (this.days.length == 7){
          this.allComplete = true
        }else{
          this.allComplete = false
        }
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }


  getPlanType() {
    const data = {
      filter_by_plan_type_active_status: true,
    };
    this.apiService.getAddOnPlanTypeByFilter(data).subscribe(
      (resp) => {
        if (resp.status == 'success') {
          this.PlanTypeList = resp.data.add_on_plan_types;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getBenefitPointsOfSubType() {
    let planSubType = this.PlanTypeList.find(
      (each) => each.id == this.selectedPlanTypeId
    );
    this.allBenefitPoints = planSubType.all_benefits_points;
  }

  getRoles() {
    const data = {
      filter_by_role_active_status: true,
    };
    this.apiService.getRolesByFilter(data).subscribe(
      (resp) => {
        if (resp.status == 'success') {
          this.roleList = resp.data.roles;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  calculatePlanPrices() {
    if (isNaN(parseFloat(this.planPrice))) {
      this.sgstTaxPrice = null;
      this.cgstTaxPrice = null;
      this.igstTaxPrice = null;
      return
  }
    this.apiService.createCalculatePlansPrice(this.planPrice).subscribe(
      (resp) => {
        if (resp.status == 'success' && this.planPrice) {
          this.sgstTaxPrice = resp.data.plan_price.sgst_price;
          this.cgstTaxPrice = resp.data.plan_price.cgst_price;
          this.igstTaxPrice = resp.data.plan_price.igst_price;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  addPlanDuration() {
    this.planDurations.push({
      planDuration: this.planDuration,
      planPrice: this.planPrice,
      sgstTaxPrice: this.sgstTaxPrice,
      cgstTaxPrice: this.cgstTaxPrice,
      igstTaxPrice: this.igstTaxPrice
    });
    this.planDuration = null;
    this.planPrice = null;
    this.sgstTaxPrice = null;
    this.cgstTaxPrice = null;
    this.igstTaxPrice = null;
  }

  deleteplanDuration(planDuration) {
    let givenPlanDurationIndex = this.planDurations.findIndex(
      (pDuration) => pDuration.planDuration == planDuration.planDuration
    );
    this.planDurations.splice(givenPlanDurationIndex, 1);
  }

  createPlan() {
    let requestDataPlanDurations = [];
    this.planDurations.forEach((element) => {
      requestDataPlanDurations.push({
        validity_in_months: element.planDuration,
        price: element.planPrice,
      });
    });
    let requestData = {
      plan: {
        name: this.planName,
        benefits: this.selectedBenefitPoints,
        schedule_role_ids: this.selectedTrainerRoleIds,
        plan_hierarchy: this.planHierarchy,
        suitable_for_customers: this.planSuitableForCustomers,
        start_datetime: null,
        end_datetime: null,
        can_book_slots: this.canBookStots,
        no_of_slots_per_month: this.noOfSlotsPerMonth,
        plan_type_id: this.selectedPlanTypeId,
        slot_booking_days: this.days
      },
      plan_durations: requestDataPlanDurations,
    };

    this.apiService.createAddOnPlan(requestData).subscribe(
      (resp) => {
        if (resp.status == 'success') {
          this.router.navigateByUrl('Admin/addon-plans');
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  reset() {
    this.planName = null;
    this.planHierarchy = null;
    this.planSuitableForCustomers = null;

    this.PlanSubTypeList = [];
    this.selectedPlanTypeId = null;
    this.selectedPlanSubTypeId = null;

    this.selectedTrainerRoleIds = [];

    this.planDuration = null;
    this.planPrice = null;
    this.sgstTaxPrice = null;
    this.cgstTaxPrice = null;
    this.igstTaxPrice = null;

    this.planDurations = [];

    this.allBenefitPoints = [];
    this.selectedBenefitPoints = [];

    this.noOfSlotsPerMonth = null;
    this.days = [];

    this.canBookStots = false
  }

  back() {
    this.router.navigateByUrl('Admin/addon-plans');
  }

  someComplete(): boolean {
    if (this.days.length == this.DaysList.length) {
      this.allComplete = true
    } else {
      this.allComplete = false
    }

    return this.days.length > 0 && !this.allComplete;
  }
  setAll(change) {
    if (change.checked) {
      this.allComplete = true;
      this.days = this.DaysList.map(e => e.value);
    } else {
      this.allComplete = false;
      this.days = [];
    }
    this.selectedDays(this.days)
  }

  selectedDays(event) {
    let selectedDayValues = [...event];
    if (selectedDayValues.length >= 7) {
      this.allComplete = true
    } else {
      this.allComplete = false
    }
    this.selectedDay = []
    this.days = []
    let anyDayOffExists = false
    selectedDayValues.forEach(selectedDayValue => {
      let selectedObj = this.DaysList.find(each => each.value === selectedDayValue)
      this.selectedDay.push({ "value": selectedObj.value, "viewValue": selectedObj.viewValue });
      this.days.push(selectedObj.value)
    });
  }
  checking(ob: MatCheckboxChange) {
    this.canBookStots = ob.checked;
    this.days = []
    this.noOfSlotsPerMonth = null
  }

  UpdateAddOnType() {
    let requestDataPlanDurations = [];
    this.planDurations.forEach((element) => {
      requestDataPlanDurations.push({
        validity_in_months: element.planDuration,
        price: element.planPrice,
      });
    });

    let requestData = {
      plan: {
        name: this.planName,
        benefits: this.selectedBenefitPoints,
        schedule_role_ids: this.selectedTrainerRoleIds,
        plan_hierarchy: this.planHierarchy,
        suitable_for_customers: this.planSuitableForCustomers,
        start_datetime: null,
        end_datetime: null,
        can_book_slots: this.canBookStots,
        no_of_slots_per_month: this.noOfSlotsPerMonth,
        plan_type_id: this.selectedPlanTypeId,
        slot_booking_days: this.days
      },
      plan_durations: requestDataPlanDurations,
    };
    
    this.apiService.updateAddOnPlans(requestData,this.addon_plan_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.router.navigateByUrl('Admin/addon-plans');

      }
     
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
}
