<div class="container-fluid">


    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{title}}</h2>
        </div>

        <div class="card"  style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Details
                </h4>

                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Plan Type
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full" name="plan" style="width: 90%;">
                                    <mat-select [placeholder]="'Select Plan Type'" [(ngModel)]="selectedPlanTypeId" [ariaReadOnly]="isUpdate"
                                    id="planTypeId" name="planTypeId" required #planTypeId="ngModel"
                                    >
                                        <mat-option *ngFor="let plan of PlanTypeList"
                                            [value]="plan.id">{{plan.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="planTypeId.invalid && planTypeId.touched && planTypeId.errors.required">Plan Type is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Plan Sub Type
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(250))</span>
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input id="points" style="background: #FFFFFF;" matInput
                                        placeholder="Plan Sub Type" [(ngModel)]="SubType" 
                                        id="subTypeId" name="subTypeId" required #subTypeId="ngModel" 
                                        pattern="^[a-zA-Z][\w\s]*$"
                                        (keypress)="handleStartingSpaceTextInput($event, SubType)" maxlength="250"
                                        >
                                        <mat-error *ngIf="subTypeId.invalid && subTypeId.touched && subTypeId.errors.required">Plan Sub Type is required</mat-error>
                                        <mat-error *ngIf="subTypeId.invalid && subTypeId.touched && subTypeId.errors.pattern">Plan Sub Type has invalid characters</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="mt-6">
            <div class="card " style="border: none">
                <div class="card-header"  style="border: none ;">
                    <h4>
                        Plan Benefits
                    </h4>
                </div>
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-12">
                        <div class="fw-bold fieldName">
                            Add Benefit Points
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="points" style="background: #FFFFFF;" matInput
                                    placeholder="Add Benefit Points" [(ngModel)]="benefitPoints" 
                                    id="benefitPointsId" name="benefitPointsId" #benefitPointsId="ngModel" 
                                    (keypress)="handleStartingSpaceTextInput($event, benefitPoints)">
                                        <mat-error *ngIf="benefitPointsId.invalid && benefitPointsId.touched && benefitPointsId.errors.pattern">Please enter valid input</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="mt-6">
                            <div class=" d-flex justify-content-end">

                                <button (click)="Addplantype()" class="ml-4 addBtn" mat-flat-button [color]="'primary'"
                                    [ngClass]="{'btn-clr': !(!benefitPoints || benefitPointsId.invalid)}" [disabled]="(!benefitPoints || benefitPointsId.invalid)">
                                    <span class="ml-2 mr-1 txt" style="color:white">Add</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 d-flex flex-column align-items-center m-auto"
                    style="max-height:450px; overflow-y:scroll;">
                    <table class="table" style="width: 90%">
                        <thead class="thead-dark"
                            style="background: #000; color: #fff;  position: sticky; top: 0; z-index: 1;">
                            <tr>
                                <th scope="col">Benefit List</th>
                                <th scope="col"></th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let benefitPoint of planSubTypeBenefitPoints">
                                <th scope="row">{{benefitPoint}}</th>

                                <td style="display: flex; justify-content: end;">
                                    <button mat-icon-button (click)="deleteBenefitPoint(benefitPoint)">
                                        <mat-icon style="color: red" svgIcon="mat_outline:delete"></mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn"  *ngIf="!isUpdate" mat-flat-button (click)="resetSubPlan()">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
            <button class="ml-4 addBtn"  *ngIf="isUpdate" mat-flat-button (click)="back()">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <!-- Why tghis span is added here -->
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'"
            *ngIf="!isUpdate"  (click)="createPlanSubTypes()"
            [ngClass]="{'btn-clr': !(planTypeId.invalid || subTypeId.invalid)}"
            [disabled] = "(planTypeId.invalid || subTypeId.invalid)" 
            >
                <span class="ml-2 mr-1 txt" style="color: white">Create </span>
            </button>
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" *ngIf="isUpdate" (click)="UpdatePlanSubType()"
            [ngClass]="{'btn-clr': !(planTypeId.invalid || subTypeId.invalid)}"
            [disabled] = "(planTypeId.invalid || subTypeId.invalid)">
                <span class="ml-2 mr-1 txt" style="color: white">Update </span>
            </button>
        </div>
    </div>
    <!-- <div class="loader" *ngIf="showloading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->
</div>