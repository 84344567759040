<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>VIEW USER</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography" >
            <div class="card dataCard" style="width: 100%;" *ngFor="let val of userDetails">
              <div class="mt-2 ml-3 " >
                  <h1 class="headings">
                      Basic Details
                  </h1>
                  <div class="row" >
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                              User Name:
                          </div> 
                          <div class="fw-bold">
                              {{val.full_name}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                             Mobile Number:
                          </div>
                          <div class="fw-bold">
                              {{val.mobile_number}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                              Email:
                          </div>
                          <div class="fw-bold">
                              {{val.email}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                          <div class="listFont fw-bold">
                             Role:
                          </div>
                          <div class="fw-bold">
                              {{val.role?.name}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                            Gender:
                        </div>
                        <div class="fw-bold">
                          {{val.gender}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                           Date of Birth:
                        </div>
                        <div class="fw-bold">
                           {{val.dob | dateFormat}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                           Date of Joining:
                        </div>
                        <div class="fw-bold">
                            {{val.doj | dateFormat}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                           Designation:
                        </div>
                        <div class="fw-bold">
                            {{val.designation}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                           Report to Users:
                        </div>
                        <div class="fw-bold">
                            {{reportToUserNames.join(", ")}}
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                            Created By:
                        </div>
                        <div class="fw-bold">
                            {{val.createdBy}}<span *ngIf="val.createdByRole">({{val.createdByRole}})</span>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                            Created Date:
                        </div>
                        <div class="fw-bold">
                            {{val.createdAt | dateFormat }}
                        </div>
                    </div>
                  </div>
                 
                  <!-- <div class="row mt-8">
                      <div class="col-md-3">
                          <div class="listFont fw-bold">
                              Gender:
                          </div>
                          <div class="">
                            {{val.gender}}
                          </div>
                      </div>
                      <div class="col-md-3">
                          <div class="listFont fw-bold">
                             Date of Birth
                          </div>
                          <div class="">
                             {{val.dob}}
                          </div>
                      </div>
                  </div> -->
              </div>
           
             
            </div>
            <div class="card dataCard mt-4" *ngIf="userAddress[0] != null" >
              <div class="mt-2 ml-3 ">
                  <h4 class="headings">
                      Address Details
                  </h4>
                  <div class="row" *ngFor="let val of userAddress" >
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                            Address Line#1
                          </div>
                          <div class="fw-bold">
                              {{val.line_1}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                            Address Line#2
                          </div>
                          <div class="fw-bold">
                              {{val.line_2}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                              PIN Code:
                          </div>
                          <div class="fw-bold">
                              {{val.pincode}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                              State:
                          </div>
                          <div class="fw-bold">
                              {{val.city.state.name}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div class="listFont fw-bold">
                           City:
                        </div>
                        <div class="fw-bold">
                            {{val.city.name}}
                        </div>
                    </div>
                  </div>
              
              </div>
           
             
            </div>
            <div class="card dataCard mt-4" *ngIf="userBranch.length >= 1">
              <div class="mt-2 ml-3">
                  <h4 class="headings">
                      User Branches
                  </h4>
                  <div class="row">
                      <div class="">
                          <div class="listFont fw-bold">
                              Branches:
                          </div>
                          <div class="fw-bold">
                              {{userBranchNames.join(", ")}}
                          </div>
                      </div>
            </div>
              </div>
            </div>
            <div class="card dataCard mt-4" style="height: auto !important;" *ngIf="userTimings.length >= 1">
              <div class="mt-2 ml-3 ">
                  <h4 class="headings">
                      User Timings
                  </h4>
                  <div class="col-12 d-flex flex-column align-items-center" style="max-height:400px; overflow-y:scroll;">
                      <table class="table " style="width: 70%;">
                          <thead class="thead-dark" style="background: #000;color: #fff; position: sticky; top: 0; z-index: 1;">
                            <tr>
                              <th scope="col">Day</th>
                              <th scope="col">Day Type</th>
                              <th scope="col">Start Time</th>
                              <th scope="col">End Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let slot of userTimings">
                              <td class="">{{slot.day_of_week}}</td>
                              <td *ngIf="slot.day_type == 'WORK_DAY'" class="">Work Day</td>
                              <td *ngIf="slot.day_type == 'DAY_OFF'" class="">Day Off</td>
                              <td class="">{{slot.start_time}}</td>
                              <td class="">{{slot.end_time}}</td>
                            </tr>
                          </tbody>
                        </table>
                  </div>
              
              </div>
            </div>

            <app-file-upload-manager #fileUploadComponent *ngIf="existingFileDataForFileManager.length >= 1"
            [shouldShowFileUploader]="false" 
            [existingFileData]="existingFileDataForFileManager" 
            [actualexistingFilesCount]="actualexistingFilesCountForFileManager">
            </app-file-upload-manager>
            
          </mat-dialog-content>
    </div>   
    
   
</div>

    <!-- <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
    </mat-dialog-actions> -->
