<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>{{title}}</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
        </button>
    </div>

    <div class="card " style="border: none">

        <div class="row  mr-3 ml-3 mt-3 justify-center">
            <div class="col-md-6">
                <div class="fw-bold fieldName">
                    Available Trainers
                </div>
                <div class="">
                    <mat-form-field class="w-full">
                        <mat-select [placeholder]="'Select Trainer'"
                            [(ngModel)]="selectedTrainer"
                            [ngModelOptions]="{standalone: true}">
                            <div style="display: flex;justify-content: flex-start; align-items: center;">
                                <i class="material-icons mr-1">search</i>
                                <input matInput (keyup)="applyFilterOnTrainers($event.target.value)">
                            </div>
                            <mat-option *ngFor="let user of filteredTrainerObjs" [value]="user">
                                {{user.full_name}} ({{user.role.name}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="mt-6">
                <div class=" d-flex justify-content-center">
                    <button class="ml-4 addBtn" mat-flat-button [color]="'primary'"
                        (click)="updatePlanTrainer()" [disabled]="!selectedTrainer"
                        [ngClass]="{'btn-clr': selectedTrainer}">
                        <span class="ml-2 mr-1 txt" style="color: white">Update Trainer</span>
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>