<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>Cancel Plan</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography" >

            <div class="card dataCard mt-4" *ngIf="showCalculatableForm" [formGroup]="cancelPlanInfoRequestForm">
                <div class="mt-2 ml-3 ">
                    <h4 class="headings">
                        Custom Cancel Plan
                    </h4>
                    
                    <div class="row mr-3 ml-3">
                        <div class="col-md-4">
                            <div class="fw-bold fieldName">
                                Cancellation Fee Amount
                            </div>
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input id="cancelFeeAmount" style="background: #FFFFFF;" matInput placeholder="Enter fee amount"
                                        formControlName="cancellationFeeAmount" required pattern="^[a-zA-Z][\w\s]*$"
                                        (keypress)="allowNumkeyPress($event)"
                                        >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="fw-bold fieldName">
                                Cancellation Fee Percentage
                            </div>
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input id="cancelFeePercentage" style="background: #FFFFFF;" (keypress)="allowFloatNumkeyPress($event)" matInput
                                        placeholder="Enter fee percentage" formControlName="cancellationFeePercentage">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="false">
                            <div class="fw-bold fieldName">
                                Coupon Validity(in Days)
                            </div>
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input id="couponValidity" style="background: #FFFFFF;" matInput placeholder="Enter coupon validity"
                                        formControlName="couponValidityInDays" (keypress)="allowNumkeyPress($event)">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="btnposition">
                        <div class="bottomBtnsSingle mb-3 row">
                            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="getCancelPlanInfo()"
                                [disabled]="false"
                                [ngClass]="{'btn-clr': !false}">
                                <span class="ml-2 mr-1 txt" style="color: white">Calculate</span>
                            </button>
                        </div>
                    </div>
                  </div>
              </div>

            <div class="card dataCard" style="width: 100%;" *ngIf="showCancelPlanInfo">
              <div class="mt-2 ml-3 " >
                  <h1 class="headings">
                      Cancel Plan Info
                  </h1>
                  <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                        <div class="listFont fw-bold">
                            Total paid amount:
                        </div> 
                        <div class="fw-bold">
                            {{cancelPlanInfoResponse.paid_plan_amount}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                        <div class="listFont fw-bold">
                            Total available amount:
                        </div> 
                        <div class="fw-bold">
                            {{cancelPlanInfoResponse.total_amount_remaining}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                    </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                             Cancellation fee amount:
                          </div>
                          <div class="fw-bold">
                              {{cancelPlanInfoResponse.cancellation_fee_amount}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                          <div class="listFont fw-bold">
                              Steel Coins:
                          </div>
                          <div class="fw-bold">
                              {{cancelPlanInfoResponse.coupon_amount}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                    </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-3" *ngIf="false">
                          <div class="listFont fw-bold">
                            Coupon validity in days:
                          </div>
                          <div class="fw-bold">
                              {{cancelPlanInfoResponse.coupon_validity_in_days}}
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-4 mb-3" *ngIf="false">
                        <div class="listFont fw-bold">
                          Coupon validity end date:
                        </div>
                        <div class="fw-bold">
                            {{cancelPlanInfoResponse.coupon_validity_end_date}}
                        </div>
                    </div>
                  </div>
                  <div class="btnposition">
                    <div class="bottomBtns mb-3 row">
                        <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="backToCalculateInfoRequest()"
                            [disabled]="false"
                            [ngClass]="{'btn-clr': !false}">
                            <span class="ml-2 mr-1 txt" style="color: white">Back</span>
                        </button>
                        <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="cancelPlan()"
                            [disabled]="false"
                            [ngClass]="{'btn-clr': !false}">
                            <span class="ml-2 mr-1 txt" style="color: white">Cancel Plan</span>
                        </button>
                    </div>
                </div>
                </div> 
            </div>

            <div class="d-flex justify-content-center" *ngIf="showErrorMsg">
                <span class="text-danger">{{ errorMsg }}</span>
            </div>
          </mat-dialog-content>
    </div>   
    
   
</div>
