<div class="container-fluid">


    <div>
        <div class="d-flex m-3">
            <div class="mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{title}}</h2>
        </div>

        <div class="card"  style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Details
                </h4>

                <div class="row  mr-3 ml-3 mt-3">

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Coupon Name
                            <span class="text-danger">*</span>
                            <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(100))</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="CouponName" name="CouponName" required #CouponName="ngModel" style="background: #FFFFFF;" matInput placeholder="Enter Coupon Name"
                                [(ngModel)]="couponName" required 
                                pattern="^[a-zA-Z][\w\s]*$" 
                                (keypress)="handleStartingSpaceTextInput($event, couponName)" maxlength="100"
                                />
                                <mat-error *ngIf="CouponName.invalid && CouponName.touched && CouponName.errors.required">Coupon Name is required</mat-error>
                                <mat-error *ngIf="CouponName.invalid && CouponName.touched && CouponName.errors.pattern">Coupon Name has invalid characters</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Coupon Type
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">

                                <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  style="width: 90%;">
                                    <mat-select id="couponTypeId" name="couponTypeId" required #couponTypeId="ngModel" [placeholder]="'Select Plan Type'" [(ngModel)]="couponType">
                                        <mat-option *ngFor="let plan of CouponTypeList"
                                            [value]="plan.id">{{plan.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="couponTypeId.invalid && couponTypeId.touched && couponTypeId.errors.required">Coupon Type is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <mat-hint class="hintStyle">**Hint: Any one of Discount Price, Discount Percentage is required</mat-hint>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Discount Price
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="Discount Price" style="background: #FFFFFF;" matInput placeholder="Enter Discount Price"
                                [(ngModel)]="Discount_Price" oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value='';">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Discount Percentage (in %)
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="Discount Percentage" style="background: #FFFFFF;" matInput placeholder="Enter Discount Percentage"
                                [(ngModel)]="Discount_Percentage" oninput="if(!this.value.match(/^\d*\.?\d{0,7}$/)) this.value='';">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Minimum Purchase Price
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="MinimumPurchasePrice" name="MinimumPurchasePrice" required #MinimumPurchasePrice="ngModel" pattern='^[1-9][0-9]*$' style="background: #FFFFFF;" matInput placeholder="Enter Minimum Purchase Price"
                                [(ngModel)]="Minimum_Purchase_Price">
                                <mat-error *ngIf="MinimumPurchasePrice.invalid && MinimumPurchasePrice.touched && MinimumPurchasePrice.errors.required">Minimum purchase value is required</mat-error>
                                <mat-error *ngIf="MinimumPurchasePrice.invalid && MinimumPurchasePrice.touched && MinimumPurchasePrice.errors.pattern">Minimum purchase value is integer</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Maximum Usages
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="MaximumUsages" name="MaximumUsages" required #MaximumUsages="ngModel" style="background: #FFFFFF;" matInput placeholder="Enter Maximum Usages"
                                [(ngModel)]="Maximum_Usages" oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value='';">
                                <mat-error *ngIf="MaximumUsages.invalid && MaximumUsages.touched && MaximumUsages.errors.required">Maximum usages is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>


            </div>


        </div>

        <div class="card mt-6" style="border: none">
            <div class="card-header" style="border: none ;">
                <h4>
                    Terms And Conditions
                </h4>
            </div>
            <div class="row  mr-3 ml-3 mt-3">
                <div class="col-md-12">
                    <div class="fw-bold fieldName">
                        Add terms
                    </div>
                    <div class="">
                        <mat-form-field class="w-full">
                            <input id="points" style="background: #FFFFFF;" matInput
                                placeholder="Add Terms And Conditions" [(ngModel)]="termPoint" id="termPointId" name="termPointId" #termPointId="ngModel" 
                                pattern="^[a-zA-Z][\w\s]*$"
                                (keypress)="handleStartingSpaceTextInput($event, termPoint)"
                                >
                                    <mat-error *ngIf="termPointId.invalid && termPointId.touched && termPointId.errors.pattern">Please enter valid input</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mt-6">
                        <div class=" d-flex justify-content-end">

                            <button (click)="addTermsAndConditions()" class="ml-4 addBtn" mat-flat-button [color]="'primary'"
                                [ngClass]="{'btn-clr': !(!termPoint || termPointId.invalid)}" [disabled]="(!termPoint || termPointId.invalid)">
                                <span class="ml-2 mr-1 txt" style="color:white">Add</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 d-flex flex-column align-items-center m-auto"
                style="max-height:450px; overflow-y:scroll;">
                <table class="table" style="width: 90%">
                    <thead class="thead-dark"
                        style="background: #000; color: #fff;  position: sticky; top: 0; z-index: 1;">
                        <tr>
                            <th scope="col">Terms And Conditions</th>
                            <th scope="col"></th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let termPoint of termsAndConditions">
                            <th scope="row">{{termPoint}}</th>
                            <td style="display: flex; justify-content: end;">
                                <button mat-icon-button (click)="deleteTermsAndConditions(termPoint)">
                                    <mat-icon style="color: red" svgIcon="mat_outline:delete"></mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-6">
            <div class="card " style="border: none">
                <div class="card-header" style="border: none ;">
                    <h4>
                        Duration
                    </h4>
                </div>
                <div class="row  mr-3 ml-3 mt-3">
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Start Date
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input matInput readonly [matDatepicker]="picker" [(ngModel)]="fromDate" [min]="newDate"
                                    [placeholder]="'Start Date'" >
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select End Date
                        </div>
                        <div class="">
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-75">
                                <input matInput readonly [matDatepicker]="picker1" [min]="fromDate || newDate" [(ngModel)]="toDate"
                                    [placeholder]="'End Date'" >
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="fw-bold fieldName">
                            Select Type of Customers
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                                    <mat-select id="CustomerType" name="CustomerType" required #CustomerType="ngModel" [placeholder]="'Type of Customers'" [(ngModel)] = "customerType" (ngModelChange)="updatedCustomerType()">
                                        <mat-option *ngFor="let Cust of CustomersType" [value]="Cust.value">{{Cust.viewValue}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="CustomerType.invalid && CustomerType.touched && CustomerType.errors.required">Customer Type is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="showSelectCustomers">
                        <div class="fw-bold fieldName">
                            Select Customers
                        </div>
                        <div class="" >
                            <mat-form-field class="flex-auto gt-xs:pr-3 w-full" style="width: 90%;">
                              <mat-select [placeholder]="'All Users'" [(ngModel)]="applicableToUserIds" multiple>
                                <div style="display: flex;justify-content: flex-start; align-items: center;">
                                    <i class="material-icons mr-1">search</i>
                                    <input matInput (keyup)="applyFilterOnUsers($event.target.value)">
                                </div>
                                <mat-checkbox class="" [checked]="allUsersChecked" [indeterminate]="someUsersChecked()" (change)="setAllUsersChecked($event)">
                                    Select All
                                </mat-checkbox>
                                <mat-option *ngFor="let user of userIdNameList" [value]="user.id">{{user.full_name | titlecase }} <span>({{user.mobile_number}})</span></mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </div>

    <div class="btnposition">
        <div class="bottomBtns mb-3 row">
            <button class="ml-4 addBtn" mat-flat-button (click)="reset()" *ngIf="!isUpdate">
                <span class="ml-2 mr-1 txt">Reset</span>
            </button>
            <button class="ml-4 addBtn" mat-flat-button (click)="back()" *ngIf="isUpdate">
                <span class="ml-2 mr-1 txt">Cancel</span>
            </button>
            <!-- <span matTooltip="userTimings should be added for all the seven days"
                [matTooltipDisabled]="!(basicDetailsForm.invalid || (should_add_branch_at_user_creation && branch_list.length<=0) || (should_add_address && userAddressForm.invalid) || (should_add_availability_timings && !isValidTimings))"
                style="width: fit-content;">
            </span> -->
            <!-- Why tghis span is added here -->
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" 
            [ngClass]="{'btn-clr': !(
                !couponName || !CouponTypeList || (! Discount_Percentage && !Discount_Price) || !Minimum_Purchase_Price || !Maximum_Usages || !customerType
            )}" 
            [disabled] = "!couponName || !CouponTypeList ||(! Discount_Percentage && !Discount_Price)|| !Minimum_Purchase_Price || !Maximum_Usages || !customerType"
            (click)="createCoupon()" *ngIf="!isUpdate"
                
                >
                <span class="ml-2 mr-1 txt" style="color: white">Create </span>
            </button>
            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="updateCoupon()"
            [ngClass]="{'btn-clr': !(
                !couponName || !CouponTypeList || (! Discount_Percentage && !Discount_Price) || !Minimum_Purchase_Price || !Maximum_Usages || !customerType
            )}" 
            [disabled] = "!couponName || !CouponTypeList ||(! Discount_Percentage && !Discount_Price)|| !Minimum_Purchase_Price || !Maximum_Usages || !customerType"
                *ngIf="isUpdate">
                <span class="ml-2 mr-1 txt" style="color: white">Update </span>
            </button>
        </div>
    </div>
    <!-- <div class="loader" *ngIf="showloading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->
</div>