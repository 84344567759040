
import { DatePipe, formatDate } from '@angular/common';
import { FactoryTarget } from '@angular/compiler';
import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { viewUser } from 'app/meta.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';

export interface Iuser {
  full_name: string;
  email: string;
  mobile: boolean;

}

@Component({
  selector: 'app-create-coupon-types',
  templateUrl: './create-coupon-types.component.html',
  styleUrls: ['./create-coupon-types.component.scss']
})

export class CreateCouponTypesComponent {

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  public roleBasicDetailsForm: FormGroup;
  data;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data_: any,
    public apiService: ApiService, private router: Router, private toastr: ToastrService,private _formBuilder: FormBuilder, private dialogRef: MatDialogRef <CreateCouponTypesComponent>
  ) {
    this.data = data_
  }

  

  plan_type_id : string;
  isUpdate:boolean=false;
  title: string = 'CREATE COUPON TYPE';
  ngOnInit() {
    this.buildForm();
    if(this.data != null){
      this.isUpdate = true;
      this.title = "EDIT COUPON TYPE"
    }
  }
  

  public buildForm() {
    let name = null
    if(this.data){
      name = this.data.data.name
    }
    this.roleBasicDetailsForm = this._formBuilder.group({
      name: new FormControl(name, [Validators.required]),
      // shouldAddLevel:this.shouldAddLevel,
    });
  }
  
  createCouponType() {
    let payload = {
      name: this.roleBasicDetailsForm.controls.name.value,
    }
    this.apiService.createCouponType(payload).subscribe((resp) => {
      this.dialogRef.close();
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  UpdateCouponType() {
    let payload = {
      name: this.roleBasicDetailsForm.controls.name.value,
    }
    this.apiService.updateCouponType(payload,this.data.data.id).subscribe((resp) => {
      this.dialogRef.close();
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  isNameChanged() {
    if (this.roleBasicDetailsForm.controls.name.value) {
      return this.roleBasicDetailsForm.controls.name.value != this.data.data.name
    }
   }
}



