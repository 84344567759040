import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { branchApis } from 'app/api-services/api-urls';
import { ApiService } from 'app/api-services/api.service';
import { defaultRoles, featureConstants } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-schedules-list',
  templateUrl: './user-schedules-list.component.html',
  styleUrls: ['./user-schedules-list.component.scss']
})
export class UserSchedulesListComponent {

  scrHeight: number;
  scrWidth: number;

  searchTerm = null;
  fromDateFilter = null;
  toDateFilter = null;

  userRoleType = defaultRoles.CUSTOMER
  userTitle = "Customer"
  userId = null;
  userOrderPlanId = null;
  planTypeAlias = null;
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValueToDisplay=""
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  
  displayedColumns = ["Customer", "Trainer", "SlotDate", "SlotTime", "BookingStatus", "SessionStatus", "SessionStartedAt", "CreatedBy", "CreatedDate", "Actions"];
  dataSource = new MatTableDataSource<Element>();

  userInfo = null;
  userPlanInfo = null;

  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToEditSchedules = this.userAllowedFeatures.indexOf(featureConstants.EDIT_SCHEDULES) > -1

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public apiService: ApiService, public toastr: ToastrService, public router: Router) {
    this.getScreenSize();

    this.userId = sessionStorage.getItem('userSchedulesUserId') || this.userId
    this.userOrderPlanId = sessionStorage.getItem('userSchedulesUserOrderPlanId') || this.userOrderPlanId
    this.planTypeAlias = sessionStorage.getItem('userSchedulesPlanTypeAlias') || this.planTypeAlias

    if (this.userId && !this.userOrderPlanId && !this.planTypeAlias) {
      this.userRoleType = null
      this.userTitle = "User"
    }

    this.getFirstLastDatesOfWeek()

  }

  getFirstLastDatesOfWeek() {
    // getting first and last dates of week
    let curr = new Date
    let currDate = curr.getDate()
    let currDay = curr.getDay()
    let firstDateOfWeek = new Date(curr.setDate(currDate - currDay))

    this.fromDateFilter = firstDateOfWeek
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForSchedule');
    let filterVaue1=sessionStorage.getItem('filterValue')
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      // this.filterValueToDisplay=paginationData.filterVaue1
    }
    this.getAllSchedules()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  back() {
    sessionStorage.removeItem('paginationDataForSchedule')
    if (this.userId && !this.userOrderPlanId && !this.planTypeAlias) {
      sessionStorage.removeItem('userSchedulesUserId')
      this.router.navigateByUrl('Admin/users');
    }
    if (this.userId && (this.userOrderPlanId || this.planTypeAlias)) {
      sessionStorage.removeItem('userSchedulesUserId')
      sessionStorage.removeItem('userSchedulesUserOrderPlanId')
      sessionStorage.removeItem('userSchedulesPlanTypeAlias')
      if (sessionStorage.getItem('getBackToFromSchedules')) {
        let getBackTo = sessionStorage.getItem('getBackToFromSchedules')
        sessionStorage.removeItem('getBackToFromSchedules')
        this.router.navigateByUrl('Admin/' + getBackTo);
      } else {
        this.router.navigateByUrl('Admin/active-schedules');
      }
    }
  }

  getAllSchedules() {
    let filterByFromDate = moment(new Date(this.fromDateFilter)).format('YYYY-MM-DD').toString()
    let filterByToDate = this.toDateFilter ? moment(new Date(this.toDateFilter)).format('YYYY-MM-DD').toString() : null
    let filterData = {
      filter_by_user_role_type: this.userRoleType,
      filter_by_user_id: this.userId,
      filter_by_user_order_plan_id: this.userOrderPlanId,
      filter_by_plan_type_alias: this.planTypeAlias,
      filter_by_from_date: filterByFromDate,
      filter_by_to_date: filterByToDate,
      offset:this.bOffset,
      limit:this.bLimit
    }

    this.apiService.getUserSchedulesByFilter(filterData).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.schedules.map(schedule_data => {
          let sessionStartedAt = null
          if (schedule_data.session_started_at_date_time) {
            sessionStartedAt = moment(new Date(schedule_data.session_started_at_date_time)).format("hh:mm:ss A").toString()
          }
          return {
            "slotBookingId": schedule_data.id,
            "customer": schedule_data.user.full_name,
            "trainer": schedule_data.trainer.full_name,
            "slotDate": moment(new Date(schedule_data.start_date_time)).format("YYYY-MM-DD").toString(),
            "slotTime": moment(new Date(schedule_data.start_date_time)).format("hh:mm:ss A").toString(),
            "bookingStatus": schedule_data.booking_status,
            "createdBy": schedule_data.created_by,
            "createdAt": schedule_data.created_at,
            "sessionStatus": schedule_data.session_status,
            "sessionStartedAt": sessionStartedAt,
          }
        });
        let branchNames = []
        response.data.branches.forEach((branch) => {
          branchNames.push(branch.name)
        });
        this.userInfo = {
          "user": response.data.user,
          "branch": branchNames.join(", ")
        }
        this.userPlanInfo = response.data.active_plan;
        this.totalCount=response.data.total_count
         
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    sessionStorage.setItem("filterValue",filterValue)
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  fromDateChanged() {
    this.onGetAllSchedules()
  }
  toDateChanged() {
    this.onGetAllSchedules()
  }

  updateSchedule(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset
    };
    sessionStorage.setItem('paginationDataForSchedule', JSON.stringify(paginationData));
    sessionStorage.removeItem('userOrderPlanIdToCreateSchedule')
    sessionStorage.setItem('slotBookingIdToUpdateSchedule', row.slotBookingId);
    this.router.navigate(['Admin/edit-schedule'])
  }

  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getAllSchedules(); 
  }

onGetAllSchedules(){
this.pageIndex=0;
this.bOffset=0
this.getAllSchedules()
  }

}
