<!-- <p>roles-list works!</p> -->
<div class="container-fluid" >
    <div class="row">
      <div class="row mt-8 justify-content-between">
        <div class="col-sm-5 col-md-4 col-lg-2">
          <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">   
            Branches
          </h1>
        </div>
        <div class="col-sm-6 col-md-4   d-flex justify-content-end" *ngIf="isUserAllowedToBranchCreate">
          <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'" (click)="createBranch()">
            <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="ml-2 mr-1 txt" style="color: white">Create Branch</span>
          </button>
        </div>
        </div>


      <div class="row ">
        <div class="col-sm-12 col-md-4 col-lg-2">
          <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
            <mat-select [placeholder]="'All'" [(ngModel)]="IBranch.branchActive" (selectionChange)="onFilterBranches()">
              <mat-option  value=''>All</mat-option>
              <mat-option *ngFor="let role of branchType" [value]="role.value">{{role.viewValue}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
          <mat-form-field
           class="flex-auto gt-xs:pr-3"
           style="width: 90%;"
           [subscriptSizing]="'dynamic'">
           <mat-icon
               class="icon-size-5"
               matPrefix
               [svgIcon]="'heroicons_solid:search'"></mat-icon>
           <input
               matInput
               (keyup)="applyFilter($event)"
               [autocomplete]="'off'"
               [placeholder]="'Search Branch Name'"
               [(ngModel)]="filterValueToDisplay"
               >
       </mat-form-field>
       </div>
        
        
    </div>
   
</div>

  
  <div class="mat-elevation-z8 mt-2 overflow-auto">
    <table mat-table [dataSource]="dataSource" matSort>
  
        <!-- ID Column -->
        <ng-container matColumnDef="BranchName">
          <th mat-header-cell *matHeaderCellDef >Branch Name</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.name | titlecase}} </td>
          
        </ng-container>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="Location">
          <th mat-header-cell *matHeaderCellDef >City</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.city.name}}</td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="Number">
          <th mat-header-cell *matHeaderCellDef > Branch Admin</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">{{row.created_by?.full_name}}({{row.created_by?.role.name}})</td>
        </ng-container>

        <!-- <ng-container matColumnDef="AssignUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Assign User </th>
            <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" style="height: 52px;" *matCellDef="let row;">
              <div class="card asgnUser">
                <button mat-icon-button (click)="viewUser()">
                  <mat-icon style="color:#ffffff" svgIcon="feather:users"></mat-icon>       
                </button>
              </div>
            </td>
          </ng-container> -->
        <!-- Fruit Column -->
        <ng-container matColumnDef="ViewUser">
          <th mat-header-cell *matHeaderCellDef >Assigned User's</th>
          <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
            <span style="color:#000000;font-size:16px;">{{row.total_branch_users_count}}</span>
          </td>
          <!-- <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row;">
            <div class="card viewUserI" >
              <button mat-icon-button (click)="viewUserByBranch(row.id)">
            <span style="color:#ffffff">({{row.total_branch_users_count}})</span>   
              </button>
            </div>   
           
          </td> -->
        </ng-container>
      
      <ng-container matColumnDef="Actions">
        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
       
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="getBranchById(row)">View</button>
              <!-- <button mat-menu-item [disabled]="!row.is_removed && isUserAllowedToBranchEdit" (click)="editBranch(row.id)">Edit</button>
              <button mat-menu-item [disabled]="!row.is_removed && isUserAllowedToBranchDeactivate" (click)="deactivateBranch(row.id)">Deactivate</button>
              <button mat-menu-item [disabled]="row.is_removed && isUserAllowedToBranchReactivate" (click)="reactivateBranch(row.id)">Reactivate</button> -->
              <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToBranchEdit" (click)="editBranch(row.id)">Edit</button>
              <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToBranchDeactivate" (click)="verifyDeactivate(row.id)">Deactivate</button>
              <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToBranchReactivate" (click)="reactivateBranch(row.id)">Reactivate</button>
            </mat-menu>
          
          </td>
          
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <div>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">
          <h3 style="display: flex;justify-content: center; margin:10%;">
            No Records Found
          </h3>
          </td>
        </tr>
      </div>
    </table>
  </div>
  <!-- <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
  <mat-paginator style="display:flex;justify-content: end;" 
  (page)="onPaginateChange($event)" [length]="totalCount"
  [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
  aria-label="Select page of users" showFirstLastButtons></mat-paginator>

</div>


